import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { verify } from '../actions/userActions'
import { USER_VERIFY_RESET } from '../constants/userConstants'
import { useTranslation } from 'react-i18next'
import { setAlert } from '../actions/alertActions'
import Meta from '../components/Meta'

const VerifyScreen = ({ location, history }) => {
  const { t } = useTranslation()

  const params = new URLSearchParams(location.search)
  const userId = params.get('id')
  const otpQuery = params.get('otp')

  const [otp, setOTP] = useState('')

  const dispatch = useDispatch()

  const userVerify = useSelector((state) => state.userVerify)
  const { loading, error, userInfo } = userVerify

  const redirect = params.get('redirect') ? params.get('redirect') : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }

    if (otpQuery) {
      dispatch(verify(userId, otpQuery))
    }

    return () => {
      dispatch({ type: USER_VERIFY_RESET })
    }
  }, [history, userInfo, otpQuery, redirect])

  useEffect(() => {
    if (error && error !== '') {
      dispatch(setAlert(error, 'danger'))
      dispatch({ type: USER_VERIFY_RESET })
    }
  }, [error])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(verify(userId, otp))
  }

  return (
    <FormContainer>
      <Meta title={t('VerifyScreen.Verify Email') + ' - nQivi'} />
      <h1>{t('VerifyScreen.Verify Email')}</h1>
      <p>{t('VerifyScreen.Email Sent')}</p>
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='otp'>
          <Form.Label>{t('VerifyScreen.Your OTP')}</Form.Label>
          <Form.Control
            type='text'
            // placeholder={t('VerifyScreen.Enter otp')}
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary' className='my-2'>
          {t('VerifyScreen.Verify')}
        </Button>
      </Form>

      <Row className='py-3'>
        <Col>
          {t('VerifyScreen.Resend OTP')}{' '}
          <Link to='resendotp'>{t('VerifyScreen.Resend')}</Link>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default VerifyScreen
