import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
// import { MoralisProvider } from 'react-moralis'
import { Provider } from 'react-redux'
import store from './store'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import LoaderProgress from './components/LoaderProgress'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Suspense fallback={<LoaderProgress time={2} />}>
    <Provider store={store}>
      {/* <MoralisProvider
        serverUrl={process.env.REACT_APP_MORALIS_SERVER}
        appId={process.env.REACT_APP_MORALIS_APPID}
      > */}
      <App />
      {/* </MoralisProvider> */}
    </Provider>
  </Suspense>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
