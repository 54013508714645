// thirdweb major UPDATE to 3.x NOTES     https://blog.thirdweb.com/sdk-major-update

import React, { useEffect } from 'react'
import axios from 'axios'
import {
  useAddress,
  useNetworkMismatch,
  useNetwork,
  useSDK,
  useStorageUpload,
} from '@thirdweb-dev/react'
import { ChainId } from '@thirdweb-dev/sdk'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next'
import { ConnectMetamaskButton } from '../components/ConnectMetamaskButton'
import { ConnectWalletButtonV2 } from '../components/ConnectWalletButton'
import { setAlert } from '../actions/alertActions'
import { Button, Image, ListGroup, Row, Col } from 'react-bootstrap'
import { updateAddress } from '../actions/contractActions'
import {
  MINT_REQUEST,
  MINT_FAIL,
  MINT_SUCCESS,
  MINT_GASLESS_OPTION,
} from '../constants/mintConstants'

import {
  getOrderDetails,
  deliverOrder,
  updateOrder,
} from '../actions/orderActions'
import { updateProductTokenId } from '../actions/productActions'
import { changeNetwork } from '../actions/blockchainActions'
import confetti from 'canvas-confetti'

let contract, contractType

const getCloudinaryFileName = (url) => {
  // not include extension
  // return url.split('/').pop().split('.').shift()

  // include extension
  return url.split('/').pop()
}

const MintScreen = ({ setTrigger }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading: loadingOrder, error: errorOrder } = orderDetails

  const mint = useSelector((state) => state.mint)
  const { minting, contractAddress, blockchain, item, tokenId } = mint

  // Hooks to detect user is on the right network and switch them if they are not
  const { network } = useSelector((state) => state.blockchain)
  const networkMismatch = useNetworkMismatch()
  const [, switchNetwork] = useNetwork()

  const address = useAddress()
  // console.log('address', address)

  const sdk = useSDK()
  // console.log('sdk', sdk)

  const { mutateAsync: upload } = useStorageUpload()

  const loadContract = async () => {
    // get which type of contract you're dealing with : "nft-drop" or "token" or "nft-collection"
    contractType = await sdk.resolveContractType(
      // '0x64b66f630eB119810302a249CDD962c28E31F3f9' //edition drop
      // '0x36d22828e1771296059242340c9f0B627B9241Ec' //edition 1155
      contractAddress
    )
    // with that value, you can get the right instance
    // contract = sdk.getBuiltInContract(contractAddress, contractType) // from version 3.x add await
    contract = await sdk.getContract(contractAddress, contractType)
    // console.log('contractType: ', contractType)
    // console.log('contract: ', contract)
  }

  useEffect(() => {
    dispatch({ type: MINT_GASLESS_OPTION, payload: true })

    return () => {
      dispatch({ type: MINT_GASLESS_OPTION, payload: false })
    }
  }, [])

  // useEffect(() => {
  //   if (
  //     address &&
  //     item?.blockchain &&
  //     network &&
  //     network !== item?.blockchain
  //   ) {
  //     dispatch(changeNetwork(ChainId[item?.blockchain]))
  //     dispatch(
  //       setAlert(t('OrderScreen.Network Changed') + item?.blockchain, 'success')
  //     )
  //   }
  // }, [network, address, item])

  useEffect(() => {
    if (errorOrder && errorOrder !== '') {
      dispatch(setAlert(errorOrder, 'danger'))
    }
  }, [errorOrder])

  useEffect(() => {
    if (contractAddress && contractAddress.trim() === '') {
      dispatch(setAlert(t('OrderScreen.No contract'), 'danger'))
    }

    if (contractAddress && contractAddress.trim() !== '') {
      loadContract()
    }
  }, [contractAddress])

  const mintHandler = async () => {
    try {
      if (item.qty <= item.delivered) {
        dispatch(setAlert(t('OrderScreen.Mint Over'), 'danger'))
        return
      }

      if (network !== blockchain) {
        dispatch(changeNetwork(blockchain))
        switchNetwork && switchNetwork(ChainId[blockchain])
        dispatch(
          setAlert(t('OrderScreen.Network Changed') + blockchain, 'success')
        )
        return
      }

      if (address) {
        // Ensure user is on the correct network
        if (networkMismatch) {
          switchNetwork && switchNetwork(ChainId[network])
          return
        }

        dispatch({ type: MINT_REQUEST })

        let receipt

        if (contractType === 'nft-drop' || contractType === 'signature-drop') {
          const tx = await contract?.claim(1)
          receipt = tx[0].receipt // the transaction receipt

          // const claimedTokenId = tx[0].id // the id of the NFT claimed
          // const claimedNFT = await tx[0].data() // (optional) get the claimed NFT metadata
        } else if (contractType === 'edition-drop' && tokenId) {
          const quantity = 1 // how many NFTs you want to claim

          const tx = await contract.claim(Number(tokenId), quantity)

          receipt = tx.receipt // the transaction receipt, only return tx: {receipt}
        } else if (contractType === 'edition' && tokenId) {
          // Get signature from backend
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': i18n.language,
            },
          }

          const body = {
            authorAddress: address, // Address of the current user
            quantity: contractType === 'edition' ? 1 : null,
            contractAddress,
            blockchain: network,
            tokenId,
          }

          const { data: signature } = await axios.post(
            `/api/nft/mint/signature`,
            body,
            config
          )

          const signedPayload = signature.signedPayload

          const tx = await contract?.signature.mint(signedPayload)
          receipt = tx.receipt
        } else if (
          contractType === 'nft-collection' ||
          contractType === 'edition'
        ) {
          let downloadUrl

          if (item?.variant?.length > 0 && item?.isDigital) {
            downloadUrl = item.downloadUrl
          } else {
            const { data } = await axios.get(
              `/api/nft/download/${order._id}/${item.product}`,
              {
                headers: {
                  'Accept-Language': i18n.language,
                },
              }
            )
            downloadUrl = data[0].secure_url
          }

          // Download file

          // // Base64 method
          // const file = await axios.get(downloadUrl, {
          //   responseType: 'arraybuffer',
          // })
          // console.log('file', file)

          // const fileBase64 =
          //   `data:image/${downloadUrl.slice(-5).split('.')[1]};base64,` +
          //   Buffer.from(file.data, 'binary').toString('base64')

          // Blob method
          const { data: blob } = await axios.get(downloadUrl, {
            responseType: 'blob',
          })

          // convert blob to file type
          const file = new File([blob], getCloudinaryFileName(downloadUrl), {
            type: blob.type,
          })

          // // OLD MINTING METHOD
          // let metadata

          // if (blob.type.includes('image')) {
          //   metadata = {
          //     name: item.name,
          //     description: item.tokenDesc ? item.tokenDesc : '',
          //     image: file,
          //   }
          // } else {
          //   metadata = {
          //     name: item.name,
          //     description: item.tokenDesc ? item.tokenDesc : '',
          //     animation_url: file,
          //   }
          // }

          // const metadataWithSupply = {
          //   metadata,
          //   supply: 1, // The number of this NFT you want to mint
          // }

          // let tx
          // if (contractType === 'edition') {
          //   tx = await contract?.mintTo(address, metadataWithSupply)
          // } else {
          //   tx = await contract?.mintTo(address, metadata) // created collection name: NFT Collection - rLgvbw7cnz
          // }

          // receipt = tx.receipt // the transaction receipt
          // // const claimedTokenId = tx.id // the id of the NFT claimed
          // // const claimedNFT = await tx.data() // (optional) get the claimed NFT metadata

          // // OLD MINTING METHOD

          // Signature based minting

          // Upload image to IPFS using Storage
          const uris = await upload({
            data: [file],
          })

          // Get signature from backend
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': i18n.language,
            },
          }

          const body = {
            authorAddress: address, // Address of the current user
            nftName: item.name,
            nftDescription: item.tokenDesc ? item.tokenDesc : '',
            quantity: contractType === 'edition' ? 1 : null,
            uri: uris[0],
            isImage: blob.type.includes('image'),
            contractAddress,
            blockchain: network,
            tokenId,
          }

          const { data: signature } = await axios.post(
            `/api/nft/mint/signature`,
            body,
            config
          )
          // console.log('signature', signature)

          // If the request succeeded, we'll get the signed payload from the response.
          // The API should come back with a JSON object containing a field called signedPayload.
          // This line of code will parse the response and store it in a variable called signedPayload.
          const signedPayload = signature.signedPayload

          // Now we can call signature.mint and pass in the signed payload that we received from the server.
          // This means we provided a signature for the user to mint an NFT with.
          const tx = await contract?.signature.mint(signedPayload)
          receipt = tx.receipt
          // console.log('tx', tx)

          // update product's tokenId if not exist
          if (!tokenId && contractType === 'edition') {
            dispatch(
              updateProductTokenId(item?.product, order._id, tx?.id.toNumber())
            )
          }
        }

        // console.log('receipt: ', receipt)
        // console.log(claimedTokenId)
        // console.log(claimedNFT)

        if (receipt) {
          item.delivered++
          dispatch(updateOrder(order))

          const totalQty = order.orderItems.reduce(
            (acc, item) => acc + item.qty,
            0
          )
          const totalDelivered = order.orderItems.reduce(
            (acc, item) => acc + item.delivered,
            0
          )

          if (totalDelivered >= totalQty) {
            dispatch(deliverOrder(order._id))
          } else {
            dispatch(getOrderDetails(order._id))
          }

          dispatch({ type: MINT_SUCCESS })
          dispatch(setAlert(t('OrderScreen.Mint Success'), 'success'))

          confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 },
          })

          updateAddress(address, network)

          // Close mint screen
          setTrigger(false)
        } else {
          throw new Error(t('OrderScreen.Mint Fail'))
        }
      } else {
        dispatch(setAlert(t('OrderScreen.Please connect'), 'danger'))
      }
    } catch (error) {
      dispatch({ type: MINT_FAIL, payload: error.message })
      dispatch(setAlert(t('OrderScreen.Mint Fail'), 'danger'))
    }
  }

  return loadingOrder ? (
    <Loader />
  ) : (
    <>
      <ListGroup
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {/* <ConnectMetamaskButton /> */}
        <ConnectWalletButtonV2 />

        <h2 className='mx-4 mt-1'>{t('OrderScreen.Slogan')}</h2>

        {!item.isDigital && item?.mediaUrl ? (
          <video
            src={item?.mediaUrl}
            // width='300'
            height='300'
            autoPlay
            loop
            muted
          />
        ) : (
          <Image
            src={item.image}
            height='300'
            style={{
              maxWidth: '90%',
              objectFit: 'contain',
            }}
          />
        )}
        <ListGroup.Item>
          {item?.blockchain && item?.blockchain.trim().length > 0 && (
            <Row className='mb-1'>
              <Col>{t('ProductScreen.Blockchain') + ': '}</Col>
              <Col style={{ textTransform: 'capitalize' }}>
                <b>{item?.blockchain}</b>
              </Col>
            </Row>
          )}

          <Button
            type='button'
            className='btn-block btn-success my-1 rounded-pill'
            style={{ width: '160px' }}
            disabled={contractAddress && contractAddress.trim() === ''}
            onClick={mintHandler}
          >
            {minting ? <Loader size='sm' /> : t('OrderScreen.Mint')}
          </Button>
        </ListGroup.Item>
      </ListGroup>
    </>
  )
}

export default MintScreen
