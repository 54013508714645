import {
  REPORT_ADMIN_FAIL,
  REPORT_ADMIN_REQUEST,
  REPORT_ADMIN_SUCCESS,
} from '../constants/reportConstants'

export const reportAdminReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case REPORT_ADMIN_REQUEST:
      return { loading: true }
    case REPORT_ADMIN_SUCCESS:
      return { loading: false, report: payload }
    case REPORT_ADMIN_FAIL:
      return { loading: false, error: payload }
    default:
      return state
  }
}
