import { useSelector } from 'react-redux'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useTranslation } from 'react-i18next'

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  const { t } = useTranslation()

  const { cartItems } = useSelector((state) => state.cart)

  const isPhysical = cartItems
    ? cartItems.map((item) => item.isPhysical).includes(true)
    : false

  return (
    <Nav className='justify-content-center mb-3'>
      {/* <Nav.Item>
        {step1 ? (
          <LinkContainer to='/login'>
            <Nav.Link>{t('CheckoutSteps.Sign In')}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{t('CheckoutSteps.Sign In')}</Nav.Link>
        )}
      </Nav.Item> */}

      {isPhysical && (
        <Nav.Item>
          {step2 ? (
            <LinkContainer to='/shipping'>
              <Nav.Link>{t('CheckoutSteps.Shipping')}</Nav.Link>
            </LinkContainer>
          ) : (
            <Nav.Link disabled>{t('CheckoutSteps.Shipping')}</Nav.Link>
          )}
        </Nav.Item>
      )}

      <Nav.Item>
        {step3 ? (
          <LinkContainer to='/payment'>
            <Nav.Link>{t('CheckoutSteps.Payment')}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{t('CheckoutSteps.Payment')}</Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step4 ? (
          <LinkContainer to='/placeorder'>
            <Nav.Link>{t('CheckoutSteps.Place Order')}</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled>{t('CheckoutSteps.Place Order')}</Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  )
}

export default CheckoutSteps
