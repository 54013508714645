export const MINT_REQUEST = 'MINT_REQUEST'
export const MINT_SUCCESS = 'MINT_SUCCESS'
export const MINT_FAIL = 'MINT_FAIL'
export const MINT_RESET = 'MINT_RESET'
export const MINT_SET_CONTRACT = 'MINT_SET_CONTRACT'
export const MINT_GASLESS_OPTION = 'MINT_GASLESS_OPTION'
export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST'
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS'
export const DOWNLOAD_FAIL = 'DOWNLOAD_FAIL'
export const DOWNLOAD_RESET = 'DOWNLOAD_RESET'
