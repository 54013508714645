import {
  PROMO_CREATE_FAIL,
  PROMO_CREATE_REQUEST,
  PROMO_CREATE_SUCCESS,
  PROMO_DELETE_FAIL,
  PROMO_DELETE_REQUEST,
  PROMO_DELETE_SUCCESS,
  PROMO_GET_APPLIED,
  PROMO_GET_FAIL,
  PROMO_GET_REQUEST,
  PROMO_GET_SUCCESS,
  PROMO_LIST_FAIL,
  PROMO_LIST_REQUEST,
  PROMO_LIST_SUCCESS,
  PROMO_RESET,
  PROMO_SETACTIVE_FAIL,
  PROMO_SETACTIVE_REQUEST,
  PROMO_SETACTIVE_SUCCESS,
  PROMO_UPDATE_FAIL,
  PROMO_UPDATE_REQUEST,
  PROMO_UPDATE_SUCCESS,
} from '../constants/promoConstants'

export const promoReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case PROMO_CREATE_REQUEST:
      return { ...state, loading: true }
    case PROMO_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        successCreate: true,
        promoInfo: payload,
      }
    case PROMO_CREATE_FAIL:
      return { ...state, loading: false, error: payload }

    case PROMO_GET_REQUEST:
      return { ...state, loading: true }
    case PROMO_GET_SUCCESS:
      return { ...state, loading: false, successGet: true, promoInfo: payload }
    case PROMO_GET_APPLIED:
      return { ...state, couponApplied: payload }
    case PROMO_GET_FAIL:
      return { ...state, loading: false, error: payload }

    case PROMO_UPDATE_REQUEST:
      return { ...state, loading: true }
    case PROMO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        successUpdate: true,
        promoInfo: payload,
      }
    case PROMO_UPDATE_FAIL:
      return { ...state, loading: false, error: payload }

    case PROMO_DELETE_REQUEST:
      return { ...state, loading: true }
    case PROMO_DELETE_SUCCESS:
      return { ...state, loading: false, successDelete: true }
    case PROMO_DELETE_FAIL:
      return { ...state, loading: false, error: payload }

    case PROMO_LIST_REQUEST:
      return { ...state, loading: true }
    case PROMO_LIST_SUCCESS:
      return { ...state, loading: false, successList: true, promoList: payload }
    case PROMO_LIST_FAIL:
      return { ...state, loading: false, error: payload }

    case PROMO_SETACTIVE_REQUEST:
      return { ...state, loading: true }
    case PROMO_SETACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
        successSetActive: true,
        promoInfo: payload,
      }
    case PROMO_SETACTIVE_FAIL:
      return { ...state, loading: false, error: payload }

    case PROMO_RESET:
      return {}

    default:
      return state
  }
}
