import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { REMOVE_ALERT } from '../constants/alertConstants'
import store from '../store'

const Alert = () => {
  const dispatch = useDispatch()

  // const alerts = useSelector((state) => state.alert)
  const [alerts, setAlerts] = useState([])

  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState()
      setAlerts(state.alert)
    })
  }, [])

  return (
    alerts !== null &&
    alerts.length > 0 &&
    alerts.map((alert) => (
      <div
        key={alert.id}
        className={`alert alert-${alert.alertType} p-2 ${
          alert.bg ? alert.bg : ''
        }`}
        style={{ minWidth: '320px' }}
      >
        {alert.msg}
        <Button
          className='close-btn btn-secondary'
          onClick={() => dispatch({ type: REMOVE_ALERT, payload: alert.id })}
        >
          X
        </Button>
      </div>
    ))
  )
}

export default Alert
