export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'
export const PRODUCT_LIST_BY_IDS = 'PRODUCT_LIST_BY_IDS'
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET'

export const PRODUCT_RELATED_REQUEST = 'PRODUCT_RELATED_REQUEST'
export const PRODUCT_RELATED_SUCCESS = 'PRODUCT_RELATED_SUCCESS'
export const PRODUCT_RELATED_FAIL = 'PRODUCT_RELATED_FAIL'
export const PRODUCT_RELATED_RESET = 'PRODUCT_RELATED_RESET'

export const PRODUCT_UPSALE_REQUEST = 'PRODUCT_UPSALE_REQUEST'
export const PRODUCT_UPSALE_SUCCESS = 'PRODUCT_UPSALE_SUCCESS'
export const PRODUCT_UPSALE_FAIL = 'PRODUCT_UPSALE_FAIL'
export const PRODUCT_UPSALE_RESET = 'PRODUCT_UPSALE_RESET'

export const PRODUCT_FEATURED_REQUEST = 'PRODUCT_FEATURED_REQUEST'
export const PRODUCT_FEATURED_SUCCESS = 'PRODUCT_FEATURED_SUCCESS'
export const PRODUCT_FEATURED_FAIL = 'PRODUCT_FEATURED_FAIL'
export const PRODUCT_FEATURED_RESET = 'PRODUCT_FEATURED_RESET'

export const PRODUCT_GALLERY_REQUEST = 'PRODUCT_GALLERY_REQUEST'
export const PRODUCT_GALLERY_SUCCESS = 'PRODUCT_GALLERY_SUCCESS'
export const PRODUCT_GALLERY_FAIL = 'PRODUCT_GALLERY_FAIL'
export const PRODUCT_GALLERY_RESET = 'PRODUCT_GALLERY_RESET'

export const PRODUCT_LIST_MY_REQUEST = 'PRODUCT_LIST_MY_REQUEST'
export const PRODUCT_LIST_MY_SUCCESS = 'PRODUCT_LIST_MY_SUCCESS'
export const PRODUCT_LIST_MY_FAIL = 'PRODUCT_LIST_MY_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET'

export const PRODUCT_VARIANT_ADD = 'PRODUCT_VARIANT_ADD'
export const PRODUCT_VARIANT_DELETE = 'PRODUCT_VARIANT_DELETE'
export const PRODUCT_VARIANT_RESET = 'PRODUCT_VARIANT_RESET'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_MANY_SUCCESS = 'PRODUCT_DELETE_MANY_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_MANY_SUCCESS = 'PRODUCT_UPDATE_MANY_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL'

export const PRODUCT_GROUPART_REQUEST = 'PRODUCT_GROUPART_REQUEST'
export const PRODUCT_GROUPART_ADD = 'PRODUCT_GROUPART_ADD'
export const PRODUCT_GROUPART_EDIT = 'PRODUCT_GROUPART_EDIT'
export const PRODUCT_GROUPART_FAIL = 'PRODUCT_GROUPART_FAIL'
export const PRODUCT_GROUPART_APPROVE = 'PRODUCT_GROUPART_APPROVE'
export const PRODUCT_GROUPART_DELETE = 'PRODUCT_GROUPART_DELETE'
export const PRODUCT_GROUPART_RESET = 'PRODUCT_GROUPART_RESET'

export const PRODUCT_UPDATE_TOKENID_SUCCESS = 'PRODUCT_UPDATE_TOKENID_SUCCESS'
export const PRODUCT_UPDATE_TOKENID_FAIL = 'PRODUCT_UPDATE_TOKENID_FAIL'

export const PRODUCT_UPDATE_LIKE_SUCCESS = 'PRODUCT_UPDATE_LIKE_SUCCESS'
export const PRODUCT_UPDATE_LIKE_FAIL = 'PRODUCT_UPDATE_LIKE_FAIL'
export const PRODUCT_VOTE = 'PRODUCT_VOTE'
export const PRODUCT_VOTE_RESET = 'PRODUCT_VOTE_RESET'

export const PRODUCT_GROUPBUY_REQUEST = 'PRODUCT_GROUPBUY_REQUEST'
export const PRODUCT_GROUPBUY_FAIL = 'PRODUCT_GROUPBUY_FAIL'
export const PRODUCT_GROUPBUY_ADD_ORDER = 'PRODUCT_GROUPBUY_ADD_ORDER'
export const PRODUCT_GROUPBUY_CLOSE = 'PRODUCT_GROUPBUY_CLOSE'
export const PRODUCT_GROUPBUY_RESET = 'PRODUCT_GROUPBUY_RESET'

export const SHOE_CHANGE_COLOR = 'SHOE_CHANGE_COLOR'
export const SHOE_CHANGE_CURRENT = 'SHOE_CHANGE_CURRENT'
export const SHOE_RESET = 'SHOE_RESET'
