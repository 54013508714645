import {
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_RESET,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LIST_TEAM_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_UPDATE,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_PROFILE_RESET,
  USER_VERIFY_REQUEST,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_FAIL,
  USER_RESENDOTP_REQUEST,
  USER_RESENDOTP_SUCCESS,
  USER_RESENDOTP_FAIL,
  USER_VERIFY_RESET,
  USER_WITHDRAW_REQUEST,
  USER_WITHDRAW_SUCCESS,
  USER_WITHDRAW_FAIL,
  USER_WISHLIST_REQUEST,
  USER_WISHLIST_SUCCESS,
  USER_WISHLIST_FAIL,
  USER_TOPUP_REQUEST,
  USER_TOPUP_SUCCESS,
  USER_TOPUP_FAIL,
  USER_TOPUP_RESET,
  USER_POINTS_ADD,
  USER_POINTS_SUBTRACT,
  USER_EXCHANGE_FUND_REQUEST,
  USER_EXCHANGE_FUND_SUCCESS,
  USER_EXCHANGE_FUND_FAIL,
  EMAIL_SEND_RESET,
  EMAIL_SEND_REQUEST,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
  USER_CHANGE_LANGUAGE,
  USER_EXCHANGE_FUND_RESET,
  USER_PAYMENT_PROCESS,
  USER_PAYMENT_REQUEST,
  USER_PAYMENT_CANCEL,
  USER_PAYMENT_COMPLETE,
  USER_PAYMENT_RESET,
  USER_DELETE_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        userInfo: payload,
      }
    case USER_LOGIN_UPDATE:
      return {
        ...state,
        loading: false,
        userInfo: {
          // ...state.userInfo, // token created at login
          token: state.userInfo.token,
          id: payload.id, //IMPORTANT: get profile => return id not _id
          name: payload.name,
          email: payload.email,
          avatar: payload?.avatar,
          referral: payload?.referral,
          isVerified: payload.isVerified,
          isAdmin: payload.isAdmin,
          isMod: payload.isMod,
          isCreator: payload.isCreator,
          isEmployee: payload.isEmployee,
          isFinancer: payload.isFinancer,
          credits: payload?.credits,
          creditsLocked: payload?.creditsLocked,
          points: payload?.points,
          ref: payload?.ref,
          creator: payload?.creator,
          wishList: payload?.wishList,
          membership: payload?.membership,
          paymentAccount: payload?.paymentAccount,
          paymentVendor: payload?.paymentVendor,
          language: payload?.language,
        },
      }
    case USER_CHANGE_LANGUAGE:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          language: payload,
        },
      }
    case USER_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        userStatus: payload.status,
        userInfo: payload.data,
      }
    case USER_REGISTER_FAIL:
      return { loading: false, error: payload }
    case USER_REGISTER_RESET:
      return {}
    default:
      return state
  }
}

export const userVerifyReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_VERIFY_REQUEST:
      return { loading: true }
    case USER_VERIFY_SUCCESS:
      return { loading: false, userInfo: payload }
    case USER_VERIFY_FAIL:
      return { loading: false, error: payload }
    case USER_VERIFY_RESET:
      return {}
    case USER_RESENDOTP_REQUEST:
      return { loading: true }
    case USER_RESENDOTP_SUCCESS:
      return { loading: false, user: payload }
    case USER_RESENDOTP_FAIL:
      return { loading: false, error: payload }

    default:
      return state
  }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_DETAILS_REQUEST:
      return { ...state, loading: true }
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: payload }
    case USER_DETAILS_FAIL:
      return { loading: false, error: payload }
    case USER_DETAILS_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userUpdateProfileReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true }
    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: payload }
    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: payload }
    case USER_UPDATE_PROFILE_RESET:
      return {}
    default:
      return state
  }
}

export const userListReducer = (state = { users: [] }, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_LIST_REQUEST:
      return { loading: true }
    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: payload.users,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case USER_LIST_TEAM_SUCCESS:
      return {
        loading: false,
        users: payload,
      }
    case USER_LIST_FAIL:
      return { loading: false, error: payload }
    case USER_LIST_RESET:
      return { users: [] }
    default:
      return state
  }
}

export const userDeleteReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_DELETE_REQUEST:
      return { loading: true }
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case USER_DELETE_FAIL:
      return { loading: false, error: payload }
    case USER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

export const userUpdateReducer = (state = { user: {} }, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_UPDATE_REQUEST:
      return { loading: true }
    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case USER_POINTS_ADD:
      return { loading: false, success: true }
    case USER_POINTS_SUBTRACT:
      return { loading: false, success: true }
    case USER_UPDATE_FAIL:
      return { loading: false, error: payload }
    case USER_UPDATE_RESET:
      return { user: {} }
    default:
      return state
  }
}

export const userWithdrawReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_WITHDRAW_REQUEST:
      return { loading: true }
    case USER_WITHDRAW_SUCCESS:
      return { loading: false, success: true }
    case USER_WITHDRAW_FAIL:
      return { loading: false, error: payload }
    default:
      return state
  }
}

export const userPaymentReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_PAYMENT_REQUEST:
      return { loading: true }
    case USER_PAYMENT_PROCESS:
      return { loading: false, success: true }
    case USER_PAYMENT_CANCEL:
      return { loading: false, success: true }
    case USER_PAYMENT_COMPLETE:
      return { loading: false, success: true }
    case USER_WITHDRAW_FAIL:
      return { loading: false, error: payload }
    case USER_PAYMENT_RESET:
      return {}
    default:
      return state
  }
}

export const userWishListReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_WISHLIST_REQUEST:
      return { loading: true }
    case USER_WISHLIST_SUCCESS:
      return {
        loading: false,
        success: true,
        products: payload.products,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case USER_WISHLIST_FAIL:
      return { loading: false, error: payload }
    default:
      return state
  }
}

export const userTopupReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_TOPUP_REQUEST:
      return { loading: true }
    case USER_TOPUP_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case USER_TOPUP_FAIL:
      return { loading: false, error: payload }
    case USER_TOPUP_RESET:
      return {}
    default:
      return state
  }
}

export const userExchangeFundReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case USER_EXCHANGE_FUND_REQUEST:
      return { loading: true }
    case USER_EXCHANGE_FUND_SUCCESS:
      return {
        loading: false,
        success: true,
        user: payload,
      }
    case USER_EXCHANGE_FUND_FAIL:
      return { loading: false, error: payload }
    case USER_EXCHANGE_FUND_RESET:
      return {}
    default:
      return state
  }
}

export const emailReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case EMAIL_SEND_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case EMAIL_SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case EMAIL_SEND_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case EMAIL_SEND_RESET:
      return {}
    default:
      return state
  }
}
