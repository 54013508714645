const convertCurrency = (amount, fromLang, toLang) => {
  const rates = {
    en: 1,
    vi: 1 / process.env.REACT_APP_RATE_USDVND,
  }
  const decimal = {
    en: 1,
    vi: 3,
  }
  const ratio = Math.pow(10, decimal[toLang])

  // convert from fromLang to base currency
  const base = amount * rates[fromLang]

  // convert from base currency to toLang
  return Math.ceil(base * rates[toLang] * ratio) / ratio
}

export default convertCurrency
