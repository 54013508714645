import { NETWORK_CHANGE } from '../constants/blockchainConstants'

export const changeNetwork = (chainId) => (dispatch) => {
  dispatch({
    type: NETWORK_CHANGE,
    payload: chainId,
  })

  localStorage.setItem('network', chainId)
}
