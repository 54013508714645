import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Image,
  InputGroup,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { listMyOrders } from '../actions/orderActions'
import {
  listMyProducts,
  createProduct,
  deleteProduct,
} from '../actions/productActions'
import { withdrawMoneyCreator } from '../actions/userActions'
import { setAlert } from '../actions/alertActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { PRODUCT_CREATE_RESET } from '../constants/productConstants'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import { useTranslation } from 'react-i18next'
import Meta from '../components/Meta'
import Paginate from '../components/Paginate'

const blankImage =
  'https://res.cloudinary.com/nqivi/image/upload/v1659410845/NFT_Shop/images/blank_transparent.png'

const ProfileScreen = ({ history, match }) => {
  const { t, i18n } = useTranslation()

  // const pageNumber = 1
  // const pageSize = 20
  const pageNumber = match.params.pageNumber || 1
  const maxRefChange = 1

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [avatar, setAvatar] = useState('')
  const [paymentAccount, setPaymentAccount] = useState('')
  const [paymentVendor, setPaymentVendor] = useState('')
  const [uploading, setUploading] = useState(false)
  const [refId, setRefId] = useState('')
  const [referral, setReferral] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isReceivingOrderEmail, setIsReceivingOrderEmail] = useState(true)
  // const [message, setMessage] = useState(null)
  // console.log('avatar: ', avatar)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const orderListMy = useSelector((state) => state.orderListMy)
  const {
    loading: loadingOrders,
    error: errorOrders,
    orders,
    page,
    pages,
    pageSize,
  } = orderListMy

  const productListMy = useSelector((state) => state.productListMy)
  const {
    loading: loadingProducts,
    error: errorProducts,
    products,
  } = productListMy

  const productDelete = useSelector((state) => state.productDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate

  useEffect(() => {
    dispatch(getUserDetails(userInfo.id))
    dispatch(listMyProducts(userInfo.id))
  }, [])

  useEffect(() => {
    dispatch(listMyOrders(pageNumber))
  }, [pageNumber])

  useEffect(() => {
    if (user) {
      setName(user.name)
      setEmail(user.email)
      setAvatar(user.avatar)
      setIsReceivingOrderEmail(user.isReceivingOrderEmail)
      if (user.referral) setReferral(user.referral)
      if (user.paymentAccount) setPaymentAccount(user.paymentAccount)
      if (user.paymentVendor) setPaymentVendor(user.paymentVendor)
      if (userInfo?.ref && userInfo?.ref?.id) setRefId(userInfo.ref.id)
    }
  }, [user])

  useEffect(() => {
    if (successCreate) {
      dispatch({ type: PRODUCT_CREATE_RESET })
      history.push(`/admin/product/${createdProduct._id}/edit`)
    } else if (!userInfo) {
      history.push('/login')
    } else if (success) {
      // if (!user || !user.name || success) {

      dispatch({ type: USER_UPDATE_PROFILE_RESET })
      dispatch(getUserDetails(userInfo.id))
    }
    if (successDelete) {
      dispatch(listMyProducts(userInfo.id))
    }
  }, [
    userInfo,
    success,
    successCreate,
    // createdProduct,
    successDelete,
  ])

  useEffect(() => {
    if (error && error !== '') {
      dispatch(setAlert(error, 'danger'))
    }

    if (success === true) {
      dispatch(setAlert(t('ProfileScreen.Profile Updated'), 'success'))
    }
  }, [error, success])

  const createProductHandler = () => {
    dispatch(createProduct())
  }

  const deleteHandler = (id) => {
    if (window.confirm('Are you sure?')) {
      dispatch(deleteProduct(id))
    }
  }

  const duplicateHandler = (product) => {
    dispatch(createProduct(product))
  }

  const uploadFileHandler = async (e, setImage) => {
    const file = e.target.files[0]
    // console.log('file: ', file)

    const formData = new FormData()
    formData.append('image', file)
    // console.log('file: ', file)

    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
          folder: 'NFT_Shop/Avatars',
        },
      }

      const { data } = await axios.post(`/api/upload`, formData, config)
      // console.log('data: ', data)
      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      dispatch(setAlert(t('ProfileScreen.Password do not match'), 'danger'))
      // setMessage('Passwords do not match')
    } else {
      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          email,
          avatar,
          paymentAccount,
          paymentVendor,
          password,
          refId,
          referral,
          isReceivingOrderEmail,
        })
      )
    }
  }

  const withdrawHandler = (e) => {
    e.preventDefault()
    if (user?.creator && user.creator.balance && user.creator.balance >= 10) {
      dispatch(withdrawMoneyCreator(user._id, i18n.language))
      dispatch(setAlert(t('RefScreen.Withdraw request'), 'success'))
    } else {
      dispatch(setAlert(t('RefScreen.Withdraw request fail'), 'danger'))
    }
  }

  return (
    <Row>
      <Meta title={t('ProfileScreen.User Profile') + ' - nQivi'} />
      <Col md={4}>
        <h2>{t('ProfileScreen.User Profile')}</h2>

        {loading && <Loader />}

        {user && user?.credits > 0 && (
          <>
            <p>
              <strong>
                {t('ProfileScreen.Credits')}
                {': '}
                {t('currency')}{' '}
                {currentCurrencyToLocale(user.credits, i18n.language)}
              </strong>
            </p>
          </>
        )}

        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name' className='mb-2'>
            <Form.Label>{t('ProfileScreen.Name')}</Form.Label>
            <Form.Control
              type='name'
              placeholder={t('ProfileScreen.Enter name')}
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='email' className='mb-2'>
            <Form.Label>{t('ProfileScreen.Email Address')}</Form.Label>
            <Form.Control
              type='email'
              placeholder={t('ProfileScreen.Enter email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password' className='mb-2'>
            <Form.Label>{t('ProfileScreen.Password')}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder={t('ProfileScreen.Enter password')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
              <Button
                variant='outline-secondary'
                style={{
                  backgroundColor: 'transparent',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                <i className='fas fa-eye' />
              </Button>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId='confirmPassword' className='mb-2'>
            <Form.Label>{t('ProfileScreen.Confirm Password')}</Form.Label>
            <InputGroup>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder={t('ProfileScreen.Confirm Password')}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></Form.Control>
              <Button
                variant='outline-secondary'
                style={{
                  backgroundColor: 'transparent',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                <i className='fas fa-eye' />
              </Button>
            </InputGroup>
          </Form.Group>

          <Form.Group controlId='avatar' className='mb-2'>
            <Form.Label>{t('ProfileScreen.Avatar')}</Form.Label>
            <br />
            <Image
              src={avatar && avatar.length > 0 ? avatar : blankImage}
              fluid
              roundedCircle
              className='mb-2'
              style={{
                objectFit: 'cover',
                width: '120px',
                height: '120px',
                borderStyle: 'dashed',
                borderWidth: '1px',
              }}
            />
            <Form.Control
              type='text'
              placeholder={t('ProfileScreen.Enter avatar URL')}
              value={avatar}
              onChange={(e) => setAvatar(e.target.value)}
            ></Form.Control>
            <Form.Control
              type='file'
              onChange={(e) => uploadFileHandler(e, setAvatar)}
            ></Form.Control>
            {uploading && <Loader />}
          </Form.Group>

          {user && user.ref && user.ref.id && (
            <Form.Group controlId='refId' className='mb-2'>
              <Form.Label>{t('ProfileScreen.Ref ID')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('ProfileScreen.Enter ref id')}
                value={refId}
                onChange={(e) => setRefId(e.target.value)}
              ></Form.Control>
              <Form.Label style={{ fontWeight: 'lighter' }}>
                <em>
                  {t('ProfileScreen.Changes number left')}{' '}
                  {maxRefChange - user.ref?.changeRefNo}
                </em>
              </Form.Label>
            </Form.Group>
          )}

          {user && user?.authType !== 'local' && (
            <Form.Group controlId='referral' className='mb-2'>
              <Form.Label>{t('ProfileScreen.Referral')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('ProfileScreen.Enter your referral')}
                value={referral}
                readOnly={user?.referral?.length > 0 ? true : false}
                onChange={(e) => setReferral(e.target.value)}
              ></Form.Control>
            </Form.Group>
          )}

          {user && (user?.ref?.isEnabled || user.isCreator) && (
            <Form.Group controlId='isReceivingOrderEmail' className='mb-2'>
              <Form.Check
                type='checkbox'
                label={t('UserEditScreen.Is Receiving New Order Notification')}
                checked={isReceivingOrderEmail}
                onChange={(e) => setIsReceivingOrderEmail(e.target.checked)}
              ></Form.Check>
            </Form.Group>
          )}

          <Form.Group controlId='paymentAccount' className='mb-2'>
            <Form.Label>{t('ProfileScreen.Payment Account')}</Form.Label>
            <Form.Control
              type='text'
              placeholder={t('ProfileScreen.Enter Payment Account')}
              value={paymentAccount}
              onChange={(e) => setPaymentAccount(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='paymentVendor'>
            <Form.Label>{t('ProfileScreen.Payment Vendor')}</Form.Label>
            <Form.Control
              type='text'
              placeholder={t('ProfileScreen.Enter Payment Vendor')}
              value={paymentVendor}
              onChange={(e) => setPaymentVendor(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button type='submit' variant='primary' className='mt-2'>
            {t('ProfileScreen.Update')}
          </Button>
        </Form>
      </Col>

      <Col md={8}>
        <Row>
          <h2>{t('ProfileScreen.My Orders')}</h2>
          {loadingOrders ? (
            <Loader />
          ) : errorOrders ? (
            <Message variant='danger'>{errorOrders}</Message>
          ) : (
            <>
              <span style={{ fontWeight: 'lighter' }}>
                <em>{t('ProfileScreen.Table Note')}</em>
              </span>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>ID</th>
                    <th>{t('ProfileScreen.DATE')}</th>
                    <th>{t('ProfileScreen.TOTAL')}</th>
                    <th>{t('ProfileScreen.PAID')}</th>
                    <th>{t('ProfileScreen.DELIVERED')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order, index) => (
                    <tr key={order._id}>
                      <td>{index + 1 + (pageNumber - 1) * pageSize}</td>
                      <td>{order._id}</td>
                      <td>{order.createdAt.substring(0, 10)}</td>
                      <td>
                        {currentCurrencyToLocale(
                          order.totalPrice,
                          i18n.language
                        )}
                      </td>
                      <td>
                        {order.isPaid ? (
                          order.paidAt.substring(0, 10)
                        ) : (
                          <i
                            className='fas fa-times'
                            style={{ color: 'red' }}
                          ></i>
                        )}
                      </td>
                      <td>
                        {order.isDelivered ? (
                          order.deliveredAt.substring(0, 10)
                        ) : (
                          <i
                            className='fas fa-times'
                            style={{ color: 'red' }}
                          ></i>
                        )}
                      </td>
                      <td>
                        <LinkContainer to={`/order/${order._id}`}>
                          <Button className='btn-sm' variant='light'>
                            {t('ProfileScreen.Details')}
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Paginate pages={pages} page={page} link={'/profile'} />
            </>
          )}
        </Row>

        {/* Products list & creator balance */}
        {userInfo && userInfo?.isCreator && !userInfo.isAdmin && (
          <Row>
            <Row>
              <h2>{t('ProfileScreen.My Balance')}</h2>
              <Col md={3}>
                <p>
                  {t('RefScreen.Rate')}:{' '}
                  <strong>
                    {user?.creator?.commissionCreator &&
                      user?.creator?.commissionCreator * 100}{' '}
                    %
                  </strong>
                </p>
              </Col>
              <Col md={3}>
                <p>
                  {t('RefScreen.Revenue')}:{' '}
                  <strong>
                    {user?.creator?.revenue &&
                      currentCurrencyToLocale(
                        user?.creator?.revenue,
                        i18n.language
                      )}
                  </strong>
                </p>
              </Col>
              <Col md={3}>
                <p>
                  {t('RefScreen.Profit')}:{' '}
                  <strong>
                    {user?.creator?.profit &&
                      currentCurrencyToLocale(
                        user?.creator?.profit,
                        i18n.language
                      )}
                  </strong>
                </p>
              </Col>
              <Col md={3}>
                <p>
                  {t('RefScreen.Balance')}:{' '}
                  <strong>
                    {user?.creator?.balance &&
                      currentCurrencyToLocale(
                        user?.creator?.balance,
                        i18n.language
                      )}
                  </strong>
                </p>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Button
                  type='button'
                  className='primary'
                  onClick={withdrawHandler}
                >
                  {t('RefScreen.Withdraw')}
                </Button>
              </Col>
            </Row>

            <Row className='align-items-center'>
              <Col>
                <h2>{t('ProfileScreen.My Products')}</h2>
              </Col>
              <Col className='text-right'>
                <Button className='my-3' onClick={createProductHandler}>
                  <i className='fas fa-plus'></i>{' '}
                  {t('ProductListScreen.Create Product')}
                </Button>
              </Col>
            </Row>

            {loadingDelete && <Loader />}
            {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
            {loadingCreate && <Loader />}
            {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

            {loadingProducts ? (
              <Loader />
            ) : errorProducts ? (
              <Message variant='danger'>{errorProducts}</Message>
            ) : (
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>ID</th>
                    <th>{t('ProductListScreen.NAME')}</th>
                    <th>{t('ProductListScreen.PRICE')}</th>
                    <th>{t('ProductListScreen.CATEGORY')}</th>
                    <th>{t('ProductListScreen.BRAND')}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => (
                    <tr key={product._id}>
                      <td>{index + 1}</td>
                      <td>{product._id}</td>
                      <td>{product.name}</td>
                      <td>
                        {t('currency')}{' '}
                        {currentCurrencyToLocale(product.price, i18n.language)}
                      </td>
                      <td>{product.category}</td>
                      <td>{product.brand}</td>
                      <td>
                        <LinkContainer
                          to={`/admin/product/${product._id}/edit`}
                        >
                          <Button variant='light' className='btn-sm'>
                            <i className='fas fa-edit'></i>
                          </Button>
                        </LinkContainer>

                        <Button
                          variant='secondary'
                          className='btn-sm ms-1'
                          onClick={() => duplicateHandler(product)}
                        >
                          <i className='fas fa-clone'></i>
                        </Button>

                        <Button
                          variant='danger'
                          className='btn-sm ms-1'
                          onClick={() => deleteHandler(product._id)}
                        >
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                      <td>
                        <LinkContainer to={`/product/${product._id}`}>
                          <Button className='btn-sm' variant='light'>
                            {t('OrderListScreen.Details')}
                          </Button>
                        </LinkContainer>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Row>
        )}
      </Col>
    </Row>
  )
}

export default ProfileScreen
