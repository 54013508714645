import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import TrackingOrder from '../components/TrackingOrder'
import { listMyOrders } from '../actions/orderActions'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import { useTranslation } from 'react-i18next'
import { setAlert } from '../actions/alertActions'
import Meta from '../components/Meta'
import Paginate from '../components/Paginate'

const TrackingOrderScreen = ({ history, match }) => {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()

  const pageNumber = match.params.pageNumber || 1

  const orderListMy = useSelector((state) => state.orderListMy)
  const {
    loading: loadingOrders,
    error: errorOrders,
    orders: ordersLoginProfile,
    page,
    pages,
    pageSize,
  } = orderListMy

  const orderLocalStorage = localStorage.getItem('myOrders')
    ? JSON.parse(localStorage.getItem('myOrders'))
    : []

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo) {
      dispatch(listMyOrders(pageNumber))
    }
  }, [dispatch, history, userInfo, pageNumber])

  useEffect(() => {
    if (errorOrders && errorOrders !== '') {
      dispatch(setAlert(errorOrders, 'danger'))
    }
  }, [errorOrders])

  return (
    <>
      <Meta title={t('TrackingOrderScreen.Tracking Order') + ' - nQivi'} />
      <h1>{t('TrackingOrderScreen.Tracking Order')}</h1>
      <TrackingOrder />

      {userInfo ? (
        <>
          <h2>{t('ProfileScreen.My Orders')}</h2>
          {loadingOrders ? (
            <Loader />
          ) : (
            <>
              <span style={{ fontWeight: 'lighter' }}>
                <em>{t('ProfileScreen.Table Note')}</em>
              </span>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>{t('ProfileScreen.DATE')}</th>
                    <th>{t('ProfileScreen.TOTAL')}</th>
                    <th>{t('ProfileScreen.PAID')}</th>
                    <th>{t('ProfileScreen.DELIVERED')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {ordersLoginProfile &&
                    ordersLoginProfile.map((order) => (
                      <tr key={order._id}>
                        <td>{order._id}</td>
                        <td>{order.createdAt.substring(0, 10)}</td>
                        <td>
                          {currentCurrencyToLocale(
                            order.totalPrice,
                            i18n.language
                          )}
                        </td>
                        <td>
                          {order.isPaid ? (
                            order.paidAt.substring(0, 10)
                          ) : (
                            <i
                              className='fas fa-times'
                              style={{ color: 'red' }}
                            ></i>
                          )}
                        </td>
                        <td>
                          {order.isDelivered ? (
                            order.deliveredAt.substring(0, 10)
                          ) : (
                            <i
                              className='fas fa-times'
                              style={{ color: 'red' }}
                            ></i>
                          )}
                        </td>
                        <td>
                          <LinkContainer to={`/order/${order._id}`}>
                            <Button className='btn-sm' variant='light'>
                              {t('ProfileScreen.Details')}
                            </Button>
                          </LinkContainer>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Paginate pages={pages} page={page} link={'/tracking'} />
            </>
          )}
        </>
      ) : (
        orderLocalStorage?.length > 0 && (
          <>
            <h2>{t('ProfileScreen.My Orders')}</h2>
            <span style={{ fontWeight: 'lighter' }}>
              <em>{t('ProfileScreen.Table Note')}</em>
            </span>
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t('ProfileScreen.DATE')}</th>
                  <th>{t('ProfileScreen.TOTAL')}</th>
                  <th>{t('ProfileScreen.PAID')}</th>
                  <th>{t('ProfileScreen.DELIVERED')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {orderLocalStorage.reverse().map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td>{order.createdAt.substring(0, 10)}</td>
                    <td>
                      {currentCurrencyToLocale(order.totalPrice, i18n.language)}
                    </td>
                    <td>
                      {order.isPaid ? (
                        order.paidAt.substring(0, 10)
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'red' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      {order.isDelivered ? (
                        order.deliveredAt.substring(0, 10)
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'red' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      <LinkContainer to={`/order/${order._id}`}>
                        <Button className='btn-sm' variant='light'>
                          {t('ProfileScreen.Details')}
                        </Button>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )
      )}
    </>
  )
}

export default TrackingOrderScreen
