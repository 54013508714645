import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import Message from '../components/Message'
import { setAlert } from '../actions/alertActions'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'
import { useTranslation } from 'react-i18next'
import Meta from '../components/Meta'
import { GoogleLoginButton } from 'react-social-login-buttons'
// import i18next from 'i18next'

const LoginScreen = ({ location, history }) => {
  const { t, i18n } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  useEffect(() => {
    if (error && error !== '') {
      dispatch(setAlert(error, 'danger'))
    }
  }, [error])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password, i18n.language))
  }

  return (
    <FormContainer>
      <Meta title={t('LoginScreen.Sign In') + ' - nQivi'} />
      <h1>{t('LoginScreen.Sign In')}</h1>
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email' className='mb-2'>
          <Form.Label>{t('LoginScreen.Email Address')}</Form.Label>
          <Form.Control
            type='email'
            // autoFocus
            placeholder={t('LoginScreen.Enter email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password'>
          <Form.Label>{t('LoginScreen.Password')}</Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              minLength='6'
              placeholder={t('LoginScreen.Enter password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            ></Form.Control>
            <Button
              variant='outline-secondary'
              style={{
                backgroundColor: 'transparent',
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              <i className='fas fa-eye' />
            </Button>
          </InputGroup>
        </Form.Group>

        <Button
          type='submit'
          variant='primary'
          className='mt-2 mb-3'
          style={{ minWidth: '120px' }}
        >
          {t('LoginScreen.Submit')}
        </Button>
      </Form>

      <GoogleLoginButton
        onClick={() => {
          window.location.href = `http://${window.location.hostname}/api/auth/google`
        }}
      >
        {t('LoginScreen.Sign in with Google')}
      </GoogleLoginButton>

      <Row className='py-3' style={{ fontWeight: 'lighter' }}>
        <Col>
          <Row>
            <Col md={6}>
              {t('LoginScreen.New Customer')}{' '}
              <Link
                to={redirect ? `/register?redirect=${redirect}` : '/register'}
              >
                {t('LoginScreen.Register')}
              </Link>
            </Col>

            <Col md={6}>
              <Link to={'/forgot-password'}>
                {t('LoginScreen.Forgot Password')}
              </Link>
              <br />
              <Link to='/resendotp'>{t('LoginScreen.Verify')}</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default LoginScreen
