import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const SearchBox = ({ history, path }) => {
  const [keyword, setKeyword] = useState('')
  const { t } = useTranslation()

  const submitHandler = (e) => {
    e.preventDefault()
    if (keyword.trim()) {
      history.push(`${path}/search/${keyword.trim()}`)
    } else {
      history.push(`${path}/`)
    }
  }
  return (
    <Form
      onSubmit={submitHandler}
      className='d-flex header-search-box'
      style={{ height: '60px' }}
    >
      <Form.Control
        type='text'
        name='q'
        onChange={(e) => setKeyword(e.target.value)}
        placeholder={t('SearchBox.Search Products')}
        className='mr-sm-2 ml-sm-5'
        style={{ minWidth: '100px' }}
      ></Form.Control>
      <Button
        type='submit'
        variant='outline-success'
        className='p-2 fs-5'
        style={{ width: '70px' }}
      >
        <i className='fas fa-magnifying-glass'></i>
        {/* {t('SearchBox.Search')} */}
      </Button>
    </Form>
  )
}

SearchBox.defaultProps = {
  path: '',
}

export default SearchBox
