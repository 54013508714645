export const STORE_RESET = 'STORE_RESET'

export const STORE_CREATE_REQUEST = 'STORE_CREATE_REQUEST'
export const STORE_CREATE_SUCCESS = 'STORE_CREATE_SUCCESS'
export const STORE_CREATE_FAIL = 'STORE_CREATE_FAIL'
export const STORE_CREATE_RESET = 'STORE_CREATE_RESET'

export const STORE_GET_ALL_REQUEST = 'STORE_GET_ALL_REQUEST'
export const STORE_GET_ALL_SUCCESS = 'STORE_GET_ALL_SUCCESS'
export const STORE_GET_ALL_FAIL = 'STORE_GET_ALL_FAIL'

export const STORE_GET_MY_REQUEST = 'STORE_GET_MY_REQUEST'
export const STORE_GET_MY_SUCCESS = 'STORE_GET_MY_SUCCESS'
export const STORE_GET_MY_FAIL = 'STORE_GET_MY_FAIL'

export const STORE_GET_REQUEST = 'STORE_GET_REQUEST'
export const STORE_GET_SUCCESS = 'STORE_GET_SUCCESS'
export const STORE_GET_FAIL = 'STORE_GET_FAIL'

export const STORE_DELETE_REQUEST = 'STORE_DELETE_REQUEST'
export const STORE_DELETE_SUCCESS = 'STORE_DELETE_SUCCESS'
export const STORE_DELETE_FAIL = 'STORE_DELETE_FAIL'

export const STORE_UPDATE_REQUEST = 'STORE_UPDATE_REQUEST'
export const STORE_UPDATE_SUCCESS = 'STORE_UPDATE_SUCCESS'
export const STORE_UPDATE_FAIL = 'STORE_UPDATE_FAIL'

export const STORE_UPDATE_LIKE_SUCCESS = 'STORE_UPDATE_LIKE_SUCCESS'
export const STORE_UPDATE_LIKE_FAIL = 'STORE_UPDATE_LIKE_FAIL'
