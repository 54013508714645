import {
  REQUEST_CREATE_FAIL,
  REQUEST_CREATE_REQUEST,
  REQUEST_CREATE_SUCCESS,
  REQUEST_CREATE_RESET,
  REQUEST_DELETE_FAIL,
  REQUEST_DELETE_REQUEST,
  REQUEST_DELETE_SUCCESS,
  REQUEST_GET_ALL_FAIL,
  REQUEST_GET_ALL_REQUEST,
  REQUEST_GET_ALL_SUCCESS,
  REQUEST_GET_MY_FAIL,
  REQUEST_GET_MY_REQUEST,
  REQUEST_GET_MY_SUCCESS,
  REQUEST_GET_REQUEST,
  REQUEST_GET_SUCCESS,
  REQUEST_GET_FAIL,
  REQUEST_GET_TYPE_REQUEST,
  REQUEST_GET_TYPE_SUCCESS,
  REQUEST_GET_TYPE_FAIL,
  REQUEST_UPDATE_FAIL,
  REQUEST_UPDATE_REQUEST,
  REQUEST_UPDATE_SUCCESS,
  REQUEST_RESET,
  REQUEST_UPDATE_RESET,
} from '../constants/requestConstants'

export const requestReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case REQUEST_CREATE_REQUEST:
      return {
        ...state,
        loadingCreate: true,
      }
    case REQUEST_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        successCreate: true,
        requestDetails: payload,
      }
    case REQUEST_CREATE_FAIL:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: payload,
      }
    case REQUEST_CREATE_RESET:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: null,
        requestDetails: null,
      }

    case REQUEST_GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case REQUEST_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        requests: payload.requests,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case REQUEST_GET_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case REQUEST_GET_MY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case REQUEST_GET_MY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        requests: payload.requests,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case REQUEST_GET_MY_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case REQUEST_GET_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case REQUEST_GET_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        requests: payload.requests,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case REQUEST_GET_TYPE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case REQUEST_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case REQUEST_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        requestDetails: payload,
      }
    case REQUEST_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case REQUEST_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case REQUEST_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        successUpdate: true,
        requestDetails: payload,
      }
    case REQUEST_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        errorUpdate: payload,
      }
    case REQUEST_UPDATE_RESET:
      return {
        ...state,
        loading: false,
        successUpdate: false,
        requestDetails: null,
        errorUpdate: null,
      }

    case REQUEST_DELETE_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      }
    case REQUEST_DELETE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      }
    case REQUEST_DELETE_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: payload,
      }
    case REQUEST_RESET:
      return {}
    default:
      return state
  }
}
