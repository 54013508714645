export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGIN_UPDATE = 'USER_LOGIN_UPDATE'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_TEAM_SUCCESS = 'USER_LIST_TEAM_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'
export const USER_DELETE_RESET = 'USER_DELETE_RESET'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST'
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL'
export const USER_VERIFY_RESET = 'USER_VERIFY_RESET'

export const USER_RESENDOTP_REQUEST = 'USER_RESENDOTP_REQUEST'
export const USER_RESENDOTP_SUCCESS = 'USER_RESENDOTP_SUCCESS'
export const USER_RESENDOTP_FAIL = 'USER_RESENDOTP_FAIL'

export const USER_WITHDRAW_REQUEST = 'USER_WITHDRAW_REQUEST'
export const USER_WITHDRAW_SUCCESS = 'USER_WITHDRAW_SUCCESS'
export const USER_WITHDRAW_FAIL = 'USER_WITHDRAW_FAIL'

export const USER_PAYMENT_PROCESS = 'USER_PAYMENT_PROCESS'
export const USER_PAYMENT_CANCEL = 'USER_PAYMENT_CANCEL'
export const USER_PAYMENT_COMPLETE = 'USER_PAYMENT_COMPLETE'
export const USER_PAYMENT_REQUEST = 'USER_PAYMENT_REQUEST'
export const USER_PAYMENT_FAIL = 'USER_PAYMENT_FAIL'
export const USER_PAYMENT_RESET = 'USER_PAYMENT_RESET'

export const USER_TOPUP_REQUEST = 'USER_TOPUP_REQUEST'
export const USER_TOPUP_SUCCESS = 'USER_TOPUP_SUCCESS'
export const USER_TOPUP_FAIL = 'USER_TOPUP_FAIL'
export const USER_TOPUP_RESET = 'USER_TOPUP_RESET'

export const USER_WISHLIST_ADD = 'USER_WISHLIST_ADD'
export const USER_WISHLIST_REMOVE = 'USER_WISHLIST_REMOVE'
export const USER_WISHLIST_REQUEST = 'USER_WISHLIST_REQUEST'
export const USER_WISHLIST_SUCCESS = 'USER_WISHLIST_SUCCESS'
export const USER_WISHLIST_FAIL = 'USER_WISHLIST_FAIL'

export const USER_CHANGE_LANGUAGE = 'USER_CHANGE_LANGUAGE'

export const USER_POINTS_ADD = 'USER_POINTS_ADD'
export const USER_POINTS_SUBTRACT = 'USER_POINTS_SUBTRACT'

export const USER_EXCHANGE_FUND_REQUEST = 'USER_EXCHANGE_FUND_REQUEST'
export const USER_EXCHANGE_FUND_SUCCESS = 'USER_EXCHANGE_FUND_SUCCESS'
export const USER_EXCHANGE_FUND_FAIL = 'USER_EXCHANGE_FUND_FAIL'
export const USER_EXCHANGE_FUND_RESET = 'USER_EXCHANGE_FUND_RESET'

export const EMAIL_SEND_REQUEST = 'EMAIL_SEND_REQUEST'
export const EMAIL_SEND_SUCCESS = 'EMAIL_SEND_SUCCESS'
export const EMAIL_SEND_FAIL = 'EMAIL_SEND_FAIL'
export const EMAIL_SEND_RESET = 'EMAIL_SEND_RESET'
