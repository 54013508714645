import axios from 'axios'
import i18n from 'i18next'
import {
  CONTRACT_CREATE_FAIL,
  CONTRACT_CREATE_REQUEST,
  CONTRACT_CREATE_SUCCESS,
  CONTRACT_GET_ALL_FAIL,
  CONTRACT_GET_ALL_REQUEST,
  CONTRACT_GET_ALL_SUCCESS,
  CONTRACT_GET_MY_FAIL,
  CONTRACT_GET_MY_REQUEST,
  CONTRACT_GET_MY_SUCCESS,
  CONTRACT_GET_REQUEST,
  CONTRACT_GET_SUCCESS,
  CONTRACT_GET_FAIL,
  CONTRACT_DELETE_REQUEST,
  CONTRACT_DELETE_SUCCESS,
  CONTRACT_DELETE_FAIL,
  CONTRACT_UPDATE_REQUEST,
  CONTRACT_UPDATE_SUCCESS,
  CONTRACT_UPDATE_FAIL,
  CONTRACT_UPDATE_LIKE_SUCCESS,
  CONTRACT_UPDATE_LIKE_FAIL,
} from '../constants/contractConstants'

export const createContract = (contract) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRACT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    // console.log('creating contract: ', contract)
    const { data } = await axios.post(`/api/contracts`, contract, config)

    dispatch({
      type: CONTRACT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CONTRACT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getContracts = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRACT_GET_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.get(
      `/api/contracts?pageNumber=${pageNumber}`,
      config
    )

    dispatch({
      type: CONTRACT_GET_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CONTRACT_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserContracts =
  (userId, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRACT_GET_MY_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const { data } = await axios.get(
        `/api/contracts/user/${userId}?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: CONTRACT_GET_MY_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CONTRACT_GET_MY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getContract = (address) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRACT_GET_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.get(`/api/contracts/${address}`, config)

    dispatch({
      type: CONTRACT_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CONTRACT_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteContract = (address) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CONTRACT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.delete(`/api/contracts/${address}`, config)

    dispatch({
      type: CONTRACT_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CONTRACT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateContract =
  (address, contract) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CONTRACT_UPDATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const { data } = await axios.put(
        `/api/contracts/${address}`,
        contract,
        config
      )

      dispatch({
        type: CONTRACT_UPDATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: CONTRACT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateAddress = async (address, network) => {
  try {
    // update contract
    // console.log('updateAddress: ', address, network)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const body = {
      address: address,
      blockchain: network,
    }

    await axios.put(
      `/api/setting/contract/${process.env.REACT_APP_SETTING_ID}`,
      body,
      config
    )
  } catch (error) {
    console.error(error.message)
  }
}

export const addLike = (contractId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }
    const { data } = await axios.put(
      `/api/contracts/${contractId}/like`,
      {},
      config
    )
    // console.log('data: ', data)

    dispatch({
      type: CONTRACT_UPDATE_LIKE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: CONTRACT_UPDATE_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeLike = (contractId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.put(
      `/api/contracts/${contractId}/unlike`,
      {},
      config
    )
    // console.log('data: ', data)

    dispatch({
      type: CONTRACT_UPDATE_LIKE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: CONTRACT_UPDATE_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
