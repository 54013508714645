export const CONTRACT_CREATE_REQUEST = 'CONTRACT_CREATE_REQUEST'
export const CONTRACT_CREATE_SUCCESS = 'CONTRACT_CREATE_SUCCESS'
export const CONTRACT_CREATE_FAIL = 'CONTRACT_CREATE_FAIL'

export const CONTRACT_GET_ALL_REQUEST = 'CONTRACT_GET_ALL_REQUEST'
export const CONTRACT_GET_ALL_SUCCESS = 'CONTRACT_GET_ALL_SUCCESS'
export const CONTRACT_GET_ALL_FAIL = 'CONTRACT_GET_ALL_FAIL'

export const CONTRACT_GET_MY_REQUEST = 'CONTRACT_GET_MY_REQUEST'
export const CONTRACT_GET_MY_SUCCESS = 'CONTRACT_GET_MY_SUCCESS'
export const CONTRACT_GET_MY_FAIL = 'CONTRACT_GET_MY_FAIL'

export const CONTRACT_GET_REQUEST = 'CONTRACT_GET_REQUEST'
export const CONTRACT_GET_SUCCESS = 'CONTRACT_GET_SUCCESS'
export const CONTRACT_GET_FAIL = 'CONTRACT_GET_FAIL'

export const CONTRACT_DELETE_REQUEST = 'CONTRACT_DELETE_REQUEST'
export const CONTRACT_DELETE_SUCCESS = 'CONTRACT_DELETE_SUCCESS'
export const CONTRACT_DELETE_FAIL = 'CONTRACT_DELETE_FAIL'
export const CONTRACT_DELETE_RESET = 'CONTRACT_DELETE_RESET'

export const CONTRACT_UPDATE_REQUEST = 'CONTRACT_UPDATE_REQUEST'
export const CONTRACT_UPDATE_SUCCESS = 'CONTRACT_UPDATE_SUCCESS'
export const CONTRACT_UPDATE_FAIL = 'CONTRACT_UPDATE_FAIL'
export const CONTRACT_UPDATE_RESET = 'CONTRACT_UPDATE_RESET'

export const CONTRACT_UPDATE_LIKE_SUCCESS = 'CONTRACT_UPDATE_LIKE_SUCCESS'
export const CONTRACT_UPDATE_LIKE_FAIL = 'CONTRACT_UPDATE_LIKE_FAIL'
