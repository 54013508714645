import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next'
import { setAlert } from '../actions/alertActions'
import { Button, Image, ListGroup } from 'react-bootstrap'
// import { Image } from 'cloudinary-react'
// import download_video from '../assets/piflowers.webm'
import {
  DOWNLOAD_REQUEST,
  DOWNLOAD_FAIL,
  DOWNLOAD_SUCCESS,
} from '../constants/mintConstants'
import { saveAs } from 'file-saver'
import {
  getOrderDetails,
  deliverOrder,
  updateOrder,
} from '../actions/orderActions'

const DownloadScreen = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // const [counter, setCounter] = useState(6000)
  // const [images, setImages] = useState([])

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading: loadingOrder, error: errorOrder } = orderDetails

  const mint = useSelector((state) => state.mint)
  const { downloaded, item } = mint

  const [currentImage, setCurrentImage] = useState({})
  // console.log('currentImage', currentImage)
  // console.log('item', item)

  const loadImages = async () => {
    try {
      if (item?.variant?.length > 0 && item?.isDigital) {
        setCurrentImage({
          secure_url: item.downloadUrl,
        })
      } else {
        const { data } = await axios.get(
          `/api/nft/download/${order._id}/${item.product}`,
          {
            headers: {
              'Accept-Language': i18n.language,
            },
          }
        )
        setCurrentImage(data[0])
        // setImages(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    loadImages()
  }, [])

  useEffect(() => {
    if (errorOrder && errorOrder !== '') {
      dispatch(setAlert(errorOrder, 'danger'))
    }
  }, [errorOrder])

  // Delete image from cloudinary
  const deleteImage = async (public_id) => {
    // console.log('public id: ', public_id)
    const config = {
      headers: {
        public_id: public_id,
        'Accept-Language': i18n.language,
      },
    }

    await axios.delete(`/api/nft/delete`, config)
  }

  useEffect(() => {
    if (downloaded && !item.isDigital) {
      deleteImage(currentImage.public_id)
    }
  }, [downloaded])

  // // Counting to redirect
  // let interval
  // useEffect(() => {
  //   interval = setInterval(() => {
  //     setCounter((counter) => counter - 1)
  //     // console.log(counter)
  //     if (images.length > 0) {
  //       setCurrentImage(images[counter % images.length])
  //     }
  //     if (counter <= 0) {
  //       // clearInterval(interval)
  //       clearInterval(interval)
  //     }
  //   }, 100)

  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [counter])

  const downloadFile = (data, name = 'myfile.png') => {
    const blob = new Blob([data], { type: 'octet-stream' })

    const href = URL.createObjectURL(blob)

    const link = Object.assign(document.createElement('a'), {
      href,
      style: 'display:none',
      download: name,
    })

    document.body.appendChild(link)

    link.click()
    URL.revokeObjectURL(href)
    link.remove()
  }

  const downloadHandler = async () => {
    try {
      // clearInterval(interval)

      if (item.qty <= item.delivered && !item.isDigital) {
        dispatch(setAlert(t('OrderScreen.Download Over'), 'danger'))
        return
      }
      dispatch({ type: DOWNLOAD_REQUEST })

      // Download image
      const { data } = await axios.get(currentImage.secure_url, {
        responseType: 'blob',
      })
      // console.log('data: ', data)
      // downloadFile(
      //   data,
      //   item.name + '.' + currentImage.secure_url.split('.').pop()
      // )

      saveAs(
        data,
        item.name.trim() + '.' + currentImage.secure_url.split('.').pop()
      )

      item.delivered++
      dispatch(updateOrder(order))

      const totalQty = order.orderItems.reduce((acc, item) => acc + item.qty, 0)
      const totalDelivered = order.orderItems.reduce(
        (acc, item) => acc + item.delivered,
        0
      )

      if (totalDelivered >= totalQty) {
        dispatch(deliverOrder(order._id))
      } else {
        dispatch(getOrderDetails(order._id))
      }

      dispatch({ type: DOWNLOAD_SUCCESS })
      dispatch(setAlert(t('OrderScreen.Download Success'), 'success'))
    } catch (error) {
      dispatch({ type: DOWNLOAD_FAIL, payload: error.message })
      dispatch(setAlert(t('OrderScreen.Download Fail'), 'danger'))
      // console.log('error: ', error.message)
    }
  }

  return loadingOrder ? (
    <Loader />
  ) : (
    <>
      <ListGroup
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <h2 className='mx-4 mt-2'>{t('OrderScreen.Slogan')}</h2>

        {/* Load images from server */}
        {/* {currentImage.secure_url ? (
          // Image tag of React Bootstrap
          <Image
            src={currentImage.secure_url}
            fluid
            rounded
            style={{ width: '300px' }}
          />
        ) : (
          // // // Image tag of Cloudinary
          // <Image
          //   cloudName='nqivi'
          //   publicId={currentImage.public_id}
          //   width='300'
          // />
          <Loader />
        )} */}

        <ListGroup.Item
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {/* {item.isDigital ? (
            <Image src={item.image} height='300' />
          ) : (
            <video
              src={item?.mediaUrl}
              width='300'
              height='300'
              autoPlay
              loop
              muted
            />
          )} */}

          {!item.isDigital && item?.mediaUrl ? (
            <video
              src={item?.mediaUrl}
              // width='300'
              height='300'
              autoPlay
              loop
              muted
            />
          ) : (
            <Image
              src={item.image}
              height='300'
              style={{
                maxWidth: '90%',
                objectFit: 'contain',
              }}
            />
          )}
        </ListGroup.Item>

        <ListGroup.Item>
          <Button
            type='button'
            className='btn-block btn-success my-1 rounded-pill'
            onClick={downloadHandler}
          >
            {t('OrderScreen.Download')}
          </Button>
        </ListGroup.Item>
      </ListGroup>
    </>
  )
}

export default DownloadScreen

// // DOWNLOAD OPTION 2

// const downloadHandler = async (item) => {
//   // const result = await axios.get(
//   //   `/api/nft/download/${orderId}/${item.product}`
//   // )
//   // console.log(result)

//   // // Receive Html
//   // window
//   //   .open('', '_blank', 'width=800,height=800')
//   //   .document.write(result.data)

//   // var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
//   // win.document.body.innerHTML = result.data;

//   //   export const requestExamplePage = async () => {
//   //     const {data} = await axios.get(examplePage, {responseType: 'document'})

//   //     return (
//   //         <div dangerouslySetInnerHTML={{ __html: data }} />
//   //     )
//   // }

//   // Receive Url
//   window.open(
//     baseUrl + `/api/nft/download/${orderId}/${item.product}`,
//     '_blank',
//     'width=800,height=800,location=no,toolbar=no'
//   )
//   item.delivered++
//   dispatch(updateOrder(order))

//   const totalQty = order.orderItems.reduce((acc, item) => acc + item.qty, 0)
//   const totalDelivered = order.orderItems.reduce(
//     (acc, item) => acc + item.delivered,
//     0
//   )

//   if (totalDelivered >= totalQty) {
//     dispatch(deliverOrder(orderId))
//   } else {
//     dispatch(getOrderDetails(orderId))
//   }
// }
