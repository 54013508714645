import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import FormContainer from '../components/FormContainer'
import { resendOTP } from '../actions/userActions'
import { USER_VERIFY_RESET } from '../constants/userConstants'
import { useTranslation } from 'react-i18next'
import { setAlert } from '../actions/alertActions'

const ResendOTPScreen = ({ location, history }) => {
  const { t } = useTranslation()

  // const params = new URLSearchParams(location.search)
  // const userId = params.get('id')
  // console.log(userId)

  // const [otp, setOTP] = useState('')
  const [email, setEmail] = useState('')

  const dispatch = useDispatch()

  const userVerify = useSelector((state) => state.userVerify)
  const { loading, error, userInfo, user } = userVerify

  useEffect(() => {
    if (user) {
      history.push(`/verify?id=${user.userId}`)
    }

    return () => {
      dispatch({ type: USER_VERIFY_RESET })
    }
  }, [history, user])

  useEffect(() => {
    if (error && error !== '') {
      dispatch(setAlert(error, 'danger'))
      dispatch({ type: USER_VERIFY_RESET })
    }
  }, [error])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(resendOTP(email))
  }

  return (
    <FormContainer>
      <Meta title={t('ResendOTPScreen.Send OTP') + ' - nQivi'} />

      <h1>{t('ResendOTPScreen.Send OTP')}</h1>
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='email'>
          <Form.Label>{t('ResendOTPScreen.Email Address')}</Form.Label>
          <Form.Control
            type='email'
            placeholder={t('ResendOTPScreen.Enter email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        {/* <Form.Group controlId='otp'>
          <Form.Label>{t('ResendOTPScreen.Your OTP')}</Form.Label>
          <Form.Control
            type='text'
            // placeholder={t('ResendOTPScreen.Enter otp')}
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          ></Form.Control>
        </Form.Group> */}

        <Button type='submit' variant='primary' className='my-2'>
          {t('ResendOTPScreen.Send')}
        </Button>
      </Form>

      {/* <Row className='py-3'>
        <Col>
          <Link to='/verify'>{t('ResendOTPScreen.Verify')}</Link>
        </Col>
      </Row> */}
    </FormContainer>
  )
}

export default ResendOTPScreen
