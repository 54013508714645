import axios from 'axios'
import i18n from 'i18next'
import {
  AUCTION_BID_CANCEL_FAIL,
  AUCTION_BID_CANCEL_REQUEST,
  AUCTION_BID_CANCEL_SUCCESS,
  AUCTION_BID_FAIL,
  AUCTION_BID_REQUEST,
  AUCTION_BID_SUCCESS,
  AUCTION_CLOSE_FAIL,
  AUCTION_CLOSE_REQUEST,
  AUCTION_CLOSE_SUCCESS,
} from '../constants/auctionConstants'
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_MY_REQUEST,
  PRODUCT_LIST_MY_SUCCESS,
  PRODUCT_LIST_MY_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_UPDATE_LIKE_SUCCESS,
  PRODUCT_UPDATE_LIKE_FAIL,
  PRODUCT_GROUPART_REQUEST,
  PRODUCT_GROUPART_ADD,
  PRODUCT_GROUPART_EDIT,
  PRODUCT_GROUPART_FAIL,
  PRODUCT_GROUPART_APPROVE,
  PRODUCT_GROUPART_DELETE,
  PRODUCT_GROUPBUY_REQUEST,
  PRODUCT_GROUPBUY_FAIL,
  PRODUCT_GROUPBUY_ADD_ORDER,
  PRODUCT_GROUPBUY_CLOSE,
  PRODUCT_VARIANT_ADD,
  PRODUCT_VARIANT_DELETE,
  PRODUCT_VOTE,
  PRODUCT_GALLERY_REQUEST,
  PRODUCT_GALLERY_SUCCESS,
  PRODUCT_GALLERY_FAIL,
  PRODUCT_LIST_BY_IDS,
  PRODUCT_LIST_RELATED,
  PRODUCT_RELATED_REQUEST,
  PRODUCT_RELATED_SUCCESS,
  PRODUCT_RELATED_FAIL,
  PRODUCT_UPSALE_REQUEST,
  PRODUCT_UPSALE_SUCCESS,
  PRODUCT_UPSALE_FAIL,
  PRODUCT_UPDATE_TOKENID_SUCCESS,
  PRODUCT_UPDATE_TOKENID_FAIL,
  PRODUCT_DELETE_MANY_SUCCESS,
  PRODUCT_UPDATE_MANY_SUCCESS,
  PRODUCT_FEATURED_REQUEST,
  PRODUCT_FEATURED_SUCCESS,
  PRODUCT_FEATURED_FAIL,
} from '../constants/productConstants'

export const listProducts =
  (keyword = '', pageNumber = '', sort, order) =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
        },
      }

      const { data } = await axios.get(
        `/api/products?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}&order=${order}`,
        config
      )

      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getProductsByList = (ids) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.post(`/api/products/list`, { ids }, config)

    dispatch({
      type: PRODUCT_LIST_BY_IDS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductsUpSale = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_UPSALE_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.get(`/api/products/upsale`, config)

    dispatch({
      type: PRODUCT_UPSALE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_UPSALE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductsRelated = (productId) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_RELATED_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.get(
      `/api/products/related/${productId}`,
      config
    )

    dispatch({
      type: PRODUCT_RELATED_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_RELATED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductsGallery = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_GALLERY_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.get(`/api/products/gallery`, config)

    dispatch({
      type: PRODUCT_GALLERY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_GALLERY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductsFeatured = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_FEATURED_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.get(`/api/products/featured`, config)

    dispatch({
      type: PRODUCT_FEATURED_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_FEATURED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductsAdmin =
  (keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/products/admin?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listMyProducts = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_MY_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.get(`/api/products/creator/${userId}`, config)

    dispatch({
      type: PRODUCT_LIST_MY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_MY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.get(`/api/products/${id}`, config)

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/products/${id}`, config)

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteManyProducts = (products) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/products/delete-many`, { products }, config)

    dispatch({
      type: PRODUCT_DELETE_MANY_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateManyProducts =
  (products, applyToAll) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_UPDATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/products/update-many`,
        { products, applyToAll },
        config
      )

      dispatch({
        type: PRODUCT_UPDATE_MANY_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    if (!product) {
      product = {
        name: 'EDIT ME',
        price: 0,
        user: userInfo.id,
        owner: userInfo.isAdmin ? 'admin' : userInfo.id,
        commissionCreator: userInfo.isAdmin
          ? 0
          : userInfo.creator.commissionCreator,
        image: ' ',
        brand: ' ',
        category: ' ',
        countInStock: 0,
        numReviews: 0,
        description: ' ',
        downloadUrl: '',
        contractAddress: '',
      }
    } else {
      delete product._id
      delete product.createdAt
      delete product.updatedAt
    }

    const { data } = await axios.post(`/api/products`, product, config)

    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/products/${product._id}`,
      product,
      config
    )

    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createProductReview =
  (productId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.post(`/api/products/${productId}/reviews`, review, config)

      dispatch({
        type: PRODUCT_CREATE_REVIEW_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const hideProductReview =
  (productId, reviewId) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        `/api/products/${productId}/reviews/hide/${reviewId}`,
        {},
        config
      )
    } catch (error) {
      console.log(
        'Something went wrong... Please try again later or contact us for help.'
      )
    }
  }

export const showProductReview =
  (productId, reviewId) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        `/api/products/${productId}/reviews/show/${reviewId}`,
        {},
        config
      )
    } catch (error) {
      console.log(
        'Something went wrong... Please try again later or contact us for help.'
      )
    }
  }

export const listTopProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TOP_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.get(`/api/products/top`, config)

    dispatch({
      type: PRODUCT_TOP_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Update product tokenId
export const updateProductTokenId =
  (productId, orderId, tokenId) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
        },
      }

      const { data } = await axios.put(
        `/api/products/${productId}/order/${orderId}/tokenId`,
        { tokenId },
        config
      )
      // console.log('data: ', data)

      dispatch({
        type: PRODUCT_UPDATE_TOKENID_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_UPDATE_TOKENID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Add like
export const addLike = (productId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.put(
      `/api/products/${productId}/like`,
      {},
      config
    )
    // console.log('data: ', data)

    dispatch({
      type: PRODUCT_UPDATE_LIKE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Remove like
export const removeLike = (productId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.put(
      `/api/products/${productId}/unlike`,
      {},
      config
    )
    // console.log('data: ', data)

    dispatch({
      type: PRODUCT_UPDATE_LIKE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_LIKE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Make a bid
export const newBidProduct =
  (productId, bidPrice, ref) => async (dispatch, getState) => {
    try {
      dispatch({
        type: AUCTION_BID_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.post(
        `/api/products/${productId}/bid`,
        { bidPrice, ref },
        config
      )

      dispatch({
        type: AUCTION_BID_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: AUCTION_BID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Make a bid
export const cancelBidProduct = (productId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUCTION_BID_CANCEL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.delete(
      `/api/products/${productId}/bid`,
      config
    )

    dispatch({
      type: AUCTION_BID_CANCEL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: AUCTION_BID_CANCEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Close auction when time is up
export const closeAuctionByTime = (productId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: AUCTION_CLOSE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.put(
      `/api/products/${productId}/bid/close`,
      {},
      config
    )

    dispatch({
      type: AUCTION_CLOSE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: AUCTION_CLOSE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Add image to group art
export const addToGroupProduct =
  ({ productId, image, artName, artPrice, artDesc }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_GROUPART_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        `/api/products/${productId}/groupart/add`,
        { image, artName, artPrice, artDesc },
        config
      )

      dispatch({
        type: PRODUCT_GROUPART_ADD,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_GROUPART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Edit group art item
export const editGroupProductItem =
  ({ productId, image, artName, artPrice, artDesc }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_GROUPART_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        `/api/products/${productId}/groupart/edit`,
        { image, artName, artPrice, artDesc },
        config
      )

      dispatch({
        type: PRODUCT_GROUPART_EDIT,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_GROUPART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Approve an image of group art
export const approveGroupProduct =
  (productId, imageId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_GROUPART_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        `/api/products/${productId}/groupart/${imageId}/approve`,
        {},
        config
      )

      dispatch({
        type: PRODUCT_GROUPART_APPROVE,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_GROUPART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Remove an image from group art
export const removeImageGroupProduct =
  (productId, imageId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_GROUPART_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.delete(
        `/api/products/${productId}/groupart/${imageId}`,
        config
      )

      dispatch({
        type: PRODUCT_GROUPART_DELETE,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_GROUPART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Add new group buy order
export const addGroupBuyOrder =
  (productId, order) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_GROUPBUY_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/products/${productId}/groupbuy/add`,
        { orderId: order._id, qty: order.orderItems[0]?.qty },
        config
      )

      dispatch({
        type: PRODUCT_GROUPBUY_ADD_ORDER,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_GROUPBUY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Close group buy promotion
export const closeGroupBuy = (productId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_GROUPBUY_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.put(
      `/api/products/${productId}/groupbuy/close`,
      {},
      config
    )

    dispatch({
      type: PRODUCT_GROUPBUY_CLOSE,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_GROUPBUY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Add new variant to product
export const addVariant =
  (productId, variant) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_DETAILS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/products/${productId}/variant/add`,
        variant,
        config
      )

      dispatch({
        type: PRODUCT_VARIANT_ADD,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Delete variant by ID
export const deleteVariant =
  (productId, variantId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_DETAILS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.delete(
        `/api/products/${productId}/variant/${variantId}`,
        config
      )

      dispatch({
        type: PRODUCT_VARIANT_DELETE,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// Vote for a product
export const voteProduct =
  (productId, variantId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_DETAILS_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const { data } = await axios.put(
        `/api/products/${productId}/vote/${variantId}`,
        {},
        config
      )

      dispatch({
        type: PRODUCT_VOTE,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
