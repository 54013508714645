import React from 'react'
import { Image, ListGroup } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import promoImage from '../assets/gift-img.jpg'

const PromotionBox = ({ promotion, notShowQuantity }) => {
  const { t, i18n } = useTranslation()

  return (
    <div
      className='text-center'
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <ListGroup
        variant='flush'
        className='my-3 rounded'
        style={{ width: '300px' }}
      >
        <Image src={promoImage} variant='top' fluid />
        <div className='m-2' style={{ fontWeight: 'lighter' }}>
          <h5 className='mb-1'>{promotion.promoName}</h5>
          {i18n.language === 'en'
            ? promotion.promoDetails
            : promotion.promoDetailsVN}
        </div>

        {!notShowQuantity && (
          <div className='m-2' style={{ fontWeight: 'lighter' }}>
            <p className='mb-1'>
              {t('ProductScreen.Promotion Quantity') + ': '}
              <strong>{promotion?.promoQuantity}</strong>
            </p>
          </div>
        )}
      </ListGroup>
    </div>
  )
}

export default PromotionBox
