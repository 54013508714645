import axios from 'axios'
import i18n from 'i18next'
import {
  TRANSACTION_CREATE_FAIL,
  TRANSACTION_CREATE_REQUEST,
  TRANSACTION_CREATE_SUCCESS,
  TRANSACTION_DELETE_FAIL,
  TRANSACTION_DELETE_REQUEST,
  TRANSACTION_DELETE_SUCCESS,
  TRANSACTION_GET_ALL_FAIL,
  TRANSACTION_GET_ALL_REQUEST,
  TRANSACTION_GET_ALL_SUCCESS,
  TRANSACTION_GET_FAIL,
  TRANSACTION_GET_REQUEST,
  TRANSACTION_GET_SUCCESS,
  TRANSACTION_GET_USER_FAIL,
  TRANSACTION_GET_USER_REQUEST,
  TRANSACTION_GET_USER_SUCCESS,
  TRANSACTION_UPDATE_FAIL,
  TRANSACTION_UPDATE_REQUEST,
  TRANSACTION_UPDATE_SUCCESS,
} from '../constants/transactionConstants'

export const createTransaction =
  (transaction) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TRANSACTION_CREATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const { data } = await axios.post(
        `/api/transactions`,
        transaction,
        config
      )

      dispatch({
        type: TRANSACTION_CREATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TRANSACTION_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getTransactions = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSACTION_GET_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.get(
      `/api/transactions?pageNumber=${pageNumber}`,
      config
    )

    dispatch({
      type: TRANSACTION_GET_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRANSACTION_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserTransactions =
  (userId, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TRANSACTION_GET_USER_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const { data } = await axios.get(
        `/api/transactions/user/${userId}?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: TRANSACTION_GET_USER_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TRANSACTION_GET_USER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getTransactionById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSACTION_GET_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.get(`/api/transactions/${id}`, config)

    dispatch({
      type: TRANSACTION_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRANSACTION_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteTransaction = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRANSACTION_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.delete(`/api/transactions/${id}`, config)

    dispatch({
      type: TRANSACTION_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: TRANSACTION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateTransaction =
  (id, transaction) => async (dispatch, getState) => {
    try {
      dispatch({
        type: TRANSACTION_UPDATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const { data } = await axios.put(
        `/api/transactions/${id}`,
        transaction,
        config
      )

      dispatch({
        type: TRANSACTION_UPDATE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: TRANSACTION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
