import {
  ARTICLE_CREATE_FAIL,
  ARTICLE_CREATE_REQUEST,
  ARTICLE_CREATE_RESET,
  ARTICLE_CREATE_SUCCESS,
  ARTICLE_DELETE_FAIL,
  ARTICLE_DELETE_REQUEST,
  ARTICLE_DELETE_SUCCESS,
  ARTICLE_GET_ALL_FAIL,
  ARTICLE_GET_ALL_REQUEST,
  ARTICLE_GET_ALL_SUCCESS,
  ARTICLE_GET_FAIL,
  ARTICLE_GET_USER_FAIL,
  ARTICLE_GET_USER_REQUEST,
  ARTICLE_GET_USER_SUCCESS,
  ARTICLE_GET_REQUEST,
  ARTICLE_GET_SUCCESS,
  ARTICLE_UPDATE_FAIL,
  ARTICLE_UPDATE_REQUEST,
  ARTICLE_UPDATE_SUCCESS,
  ARTICLE_RESET,
  ARTICLE_UPDATE_LIKE_FAIL,
  ARTICLE_UPDATE_LIKE_SUCCESS,
  ARTICLE_UPDATE_RESET,
} from '../constants/articleConstants'

export const articleReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case ARTICLE_CREATE_REQUEST:
      return {
        ...state,
        loadingCreate: true,
      }
    case ARTICLE_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        successCreate: true,
        article: payload,
      }
    case ARTICLE_CREATE_FAIL:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: payload,
      }
    case ARTICLE_CREATE_RESET:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: null,
        article: null,
      }

    case ARTICLE_GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ARTICLE_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        articles: payload.articles,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case ARTICLE_GET_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case ARTICLE_GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ARTICLE_GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        articles: payload.articles,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case ARTICLE_GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case ARTICLE_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ARTICLE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        article: payload,
      }
    case ARTICLE_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case ARTICLE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case ARTICLE_UPDATE_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        successUpdate: true,
        article: payload,
      }
    case ARTICLE_UPDATE_FAIL:
      return {
        ...state,
        loadingUpdate: false,
        errorUpdate: payload,
      }
    case ARTICLE_UPDATE_RESET:
      return {
        ...state,
        loadingUpdate: false,
        errorUpdate: null,
        successUpdate: false,
      }

    case ARTICLE_DELETE_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      }
    case ARTICLE_DELETE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      }
    case ARTICLE_DELETE_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: payload,
      }
    case ARTICLE_UPDATE_LIKE_SUCCESS:
      return {
        ...state,
        successLike: true,
      }
    case ARTICLE_UPDATE_LIKE_FAIL:
      return {
        ...state,
        successLike: false,
        errorLike: payload,
      }
    case ARTICLE_RESET:
      return {}
    default:
      return state
  }
}
