import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Product from './Product'
import { getProductsUpSale } from '../actions/productActions'
import { PRODUCT_UPSALE_RESET } from '../constants/productConstants'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ProductsUpSale = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productsUpSale = useSelector((state) => state.productsUpSale)
  const { products } = productsUpSale

  // console.log('productId: ', productId)

  useEffect(() => {
    dispatch(getProductsUpSale())

    return () => {
      dispatch({ type: PRODUCT_UPSALE_RESET })
    }
  }, [])

  return (
    products && (
      <div className='mt-3'>
        <h3>{t('ProductScreen.Best Deals')}</h3>
        <Row>
          {products.map((product) => (
            <Col key={product._id} sm={6} md={3}>
              <Product
                key={product._id}
                product={product}
                userInfo={userInfo}
                addToCart
                notShowRefRate
                showPrice
              />
            </Col>
          ))}
        </Row>
      </div>
    )
  )
}

export default ProductsUpSale
