import { NETWORK_CHANGE } from '../constants/blockchainConstants'

export default function (state = { network: 'Polygon' }, action) {
  const { type, payload } = action

  switch (type) {
    case NETWORK_CHANGE:
      return { network: payload }
    default:
      return state
  }
}
