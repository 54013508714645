export const AUCTION_RESET = 'AUCTION_RESET'
export const AUCTION_BID_REQUEST = 'AUCTION_BID_REQUEST'
export const AUCTION_BID_SUCCESS = 'AUCTION_BID_SUCCESS'
export const AUCTION_BID_FAIL = 'AUCTION_BID_FAIL'

export const AUCTION_BID_CANCEL_REQUEST = 'AUCTION_BID_CANCEL_REQUEST'
export const AUCTION_BID_CANCEL_SUCCESS = 'AUCTION_BID_CANCEL_SUCCESS'
export const AUCTION_BID_CANCEL_FAIL = 'AUCTION_BID_CANCEL_FAIL'

export const AUCTION_CLOSE_REQUEST = 'AUCTION_CLOSE_REQUEST'
export const AUCTION_CLOSE_SUCCESS = 'AUCTION_CLOSE_SUCCESS'
export const AUCTION_CLOSE_FAIL = 'AUCTION_CLOSE_FAIL'
