import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import './Popup.css'

const Popup = (props) => {
  return (
    <Modal centered show={props.trigger} onHide={() => props.setTrigger(false)}>
      {/* <Modal.Header closeButton /> */}
      <Modal.Body>
        <div className='popup'>
          <div className={`popup-inner ${props.background}`}>
            <Button
              className='close-btn btn'
              onClick={() => props.setTrigger(false)}
              style={{ zIndex: '1000' }}
            >
              X
            </Button>
            {props.children}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

Popup.defaultProps = {
  background: 'bg-primary',
}

export default Popup
