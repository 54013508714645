import { useEffect, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
// import BackgroundLight from './BackgroundLight'
// import BackgroundDark from './BackgroundDark'

let interval

const LoaderProgress = ({ time, variant }) => {
  const start = 70
  const max = 96
  const duration = 100

  const [now, setNow] = useState(start)

  const step = Number((max - start) / ((time * 1000) / duration)) // time = seconds
  // console.log('time: ', time)
  // console.log('max: ', max, ' typeof: ', typeof max)
  // console.log('step: ', step)

  useEffect(() => {
    interval = setInterval(() => {
      // if (Number(now) >= Number(max)) {
      //   console.log(' clearInterval')
      //   clearInterval(interval)
      // }

      setNow((prev) => prev + step)
    }, duration)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    // console.log('now: ', now, ' typeof: ', typeof now)
    if (Number(now) >= Number(max)) {
      clearInterval(interval)
    }
  }, [now])

  return (
    <>
      <ProgressBar
        variant={variant}
        animated={true}
        label={`${parseInt(now)}%`}
        now={now}
        min={0}
        max={100}
      />
    </>
  )
}

ProgressBar.defaultProps = {
  variant: 'primary',
  time: 1,
  label: 'Loading...',
}

export default LoaderProgress
