import { setCookie } from '../utils/cookies'

// setCookie("user_email","bobthegreat@gmail.com",30); //set "user_email" cookie, expires in 30 days
// var userEmail=getCookie("user_email");//"bobthegreat@gmail.com"

import { REF_SAVE } from '../constants/refConstants'

export const saveRef = (ref) => (dispatch) => {
  dispatch({
    type: REF_SAVE,
    payload: ref,
  })

  setCookie('ref', ref, 30)
}
