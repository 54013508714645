import axios from 'axios'
import i18n from 'i18next'
import {
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_FAIL,
  USER_LIST_SUCCESS,
  USER_LIST_TEAM_SUCCESS,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_VERIFY_FAIL,
  USER_VERIFY_SUCCESS,
  USER_VERIFY_REQUEST,
  USER_RESENDOTP_REQUEST,
  USER_RESENDOTP_SUCCESS,
  USER_RESENDOTP_FAIL,
  USER_WITHDRAW_FAIL,
  USER_WITHDRAW_REQUEST,
  USER_WITHDRAW_SUCCESS,
  USER_WISHLIST_ADD,
  USER_WISHLIST_REMOVE,
  USER_WISHLIST_REQUEST,
  USER_WISHLIST_SUCCESS,
  USER_WISHLIST_FAIL,
  USER_LOGIN_UPDATE,
  USER_TOPUP_REQUEST,
  USER_TOPUP_SUCCESS,
  USER_TOPUP_FAIL,
  USER_POINTS_ADD,
  USER_EXCHANGE_FUND_REQUEST,
  USER_EXCHANGE_FUND_SUCCESS,
  USER_EXCHANGE_FUND_FAIL,
  EMAIL_SEND_REQUEST,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
  USER_CHANGE_LANGUAGE,
  USER_PAYMENT_REQUEST,
  USER_PAYMENT_PROCESS,
  USER_PAYMENT_FAIL,
  USER_PAYMENT_CANCEL,
  USER_PAYMENT_COMPLETE,
} from '../constants/userConstants'
import {
  ORDER_LIST_MY_RESET,
  ORDER_REF_LIST_MY_RESET,
} from '../constants/orderConstants'
import currentCurrency from '../utils/currentCurrency'

export const login = (email, password, lang) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': lang,
      },
    }

    const { data } = await axios.post(
      `/api/users/login?lang=${lang}`,
      { email, password },
      config
    )

    // console.log('login result: ', data)
    if (data.isVerified) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
    } else {
      throw new Error('User email not verified')
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const loginOauth = (token, lang) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': lang,
        Authorization: `Bearer ${token}`,
      },
    }

    const { data } = await axios.get(`/api/users/profile?lang=${lang}`, config)

    // console.log('login result: ', data)
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: { ...data, token },
    })

    localStorage.setItem('userInfo', JSON.stringify({ ...data, token }))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const loginOauth_2nd_way = (provider) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.get(`/api/auth/${provider}`, config)

    if (data.isVerified) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
    } else {
      throw new Error('User email not verified')
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUpdatedUserInfo = (id, lang) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/users/profile?lang=${lang ? lang : ''}`,
      config
    )
    // console.log('getUpdatedUserInfo: ', data)

    dispatch({
      type: USER_LOGIN_UPDATE,
      payload: data,
    })

    localStorage.setItem(
      'userInfo',
      JSON.stringify({ ...data, id: userInfo.id, token: userInfo.token })
    )
  } catch (error) {
    // check if error is 401, then logout
    if (error.response && error.response.status === 401) {
      dispatch(logout())
    }
    // if (error.message.includes('Not authorized')) {
    //   dispatch(logout())
    // }
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_DETAILS_RESET })
  dispatch({ type: ORDER_LIST_MY_RESET })
  dispatch({ type: ORDER_REF_LIST_MY_RESET })
  dispatch({ type: USER_LIST_RESET })
}

export const verify = (userId, otp) => async (dispatch) => {
  try {
    dispatch({
      type: USER_VERIFY_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.post(
      '/api/users/verifyotp',
      { userId, otp },
      config
    )

    if (data.status === 'VERIFIED') {
      dispatch({
        type: USER_VERIFY_SUCCESS,
        payload: data.user,
      })

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data.user,
      })

      localStorage.setItem('userInfo', JSON.stringify(data.user))
    }

    if (data.status === 'FAILED') {
      dispatch({
        type: USER_VERIFY_FAIL,
        payload: data.message,
      })
    }
  } catch (error) {
    dispatch({
      type: USER_VERIFY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const resendOTP = (email) => async (dispatch) => {
  try {
    dispatch({
      type: USER_RESENDOTP_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.post('/api/users/resendotp', { email }, config)

    if (data.status === 'PENDING') {
      dispatch({
        type: USER_RESENDOTP_SUCCESS,
        payload: data.data,
      })
    }

    if (data.status === 'FAILED') {
      dispatch({
        type: USER_RESENDOTP_FAIL,
        payload: data.message,
      })
    }
  } catch (error) {
    dispatch({
      type: USER_RESENDOTP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const register =
  (name, email, password, agreePromotion, referral, lang) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISTER_REQUEST,
      })

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
        },
      }

      const { data } = await axios.post(
        `/api/users?lang=${lang}`,
        { name, email, password, agreePromotion, referral },
        config
      )

      if (data.status === 'PENDING') {
        dispatch({
          type: USER_REGISTER_SUCCESS,
          payload: data,
        })
      }

      if (data.status === 'FAILED') {
        dispatch({
          type: USER_REGISTER_FAIL,
          payload: data.message,
        })
      }

      // dispatch({
      //   type: USER_LOGIN_SUCCESS,
      //   payload: data,
      // })

      // localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/users/${id}`, config)
    // console.log('user details: ', data)

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/users/profile`, user, config)

    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    })

    // dispatch({
    //   type: USER_DETAILS_SUCCESS,
    //   payload: data,
    // })

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listUsers =
  (pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_LIST_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/users?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: USER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listTeamUsers = (referral) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/users/ref/${referral}`, config)

    dispatch({
      type: USER_LIST_TEAM_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/users/${id}`, config)

    dispatch({
      type: USER_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    // console.log('update user: ', user)
    const { data } = await axios.put(`/api/users/${user._id}`, user, config)

    dispatch({
      type: USER_UPDATE_SUCCESS,
    })

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const withdrawMoney = (userId, lang) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_WITHDRAW_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/users/withdraw?lang=${lang}`, { userId }, config)

    dispatch({
      type: USER_WITHDRAW_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: USER_WITHDRAW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const withdrawMoneyCreator =
  (userId, lang) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_WITHDRAW_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.post(
        `/api/users/withdraw/creator?lang=${lang}`,
        { userId },
        config
      )

      dispatch({
        type: USER_WITHDRAW_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: USER_WITHDRAW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const processCreatorPayment =
  (user, amount) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_PAYMENT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        `/api/users/creator/${user._id}/process`,
        { amount: amount ? amount : user.creator?.balance },
        config
      )

      dispatch({
        type: USER_PAYMENT_PROCESS,
      })
    } catch (error) {
      dispatch({
        type: USER_PAYMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const cancelCreatorPayment = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PAYMENT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/users/creator/${userId}/cancel`, {}, config)

    dispatch({
      type: USER_PAYMENT_CANCEL,
    })
  } catch (error) {
    dispatch({
      type: USER_PAYMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const completeCreatorPayment =
  (userId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_PAYMENT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(`/api/users/creator/${userId}/complete`, {}, config)

      dispatch({
        type: USER_PAYMENT_COMPLETE,
      })
    } catch (error) {
      dispatch({
        type: USER_PAYMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const processRefPayment =
  (user, amount) => async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_PAYMENT_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        `/api/users/ref/${user._id}/process`,
        { amount: amount ? amount : user.ref?.balance },
        config
      )

      dispatch({
        type: USER_PAYMENT_PROCESS,
      })
    } catch (error) {
      dispatch({
        type: USER_PAYMENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const cancelRefPayment = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PAYMENT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/users/ref/${userId}/cancel`, {}, config)

    dispatch({
      type: USER_PAYMENT_CANCEL,
    })
  } catch (error) {
    dispatch({
      type: USER_PAYMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const completeRefPayment = (userId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_PAYMENT_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/users/ref/${userId}/complete`, {}, config)

    dispatch({
      type: USER_PAYMENT_COMPLETE,
    })
  } catch (error) {
    dispatch({
      type: USER_PAYMENT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// Add to wishlist
export const addToWishList = (productId) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/users/wishlist/add`,
      { productId },
      config
    )
    // console.log('wishlist: ', data)

    dispatch({
      type: USER_WISHLIST_ADD,
    })
  } catch (error) {
    console.log(
      'Something went wrong... Please try again later or contact us for help.'
    )
  }
}

// Remove from wishlist
export const removeFromWishList = (productId) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/users/wishlist/remove`,
      { productId },
      config
    )
    // console.log('wishlist: ', data)

    dispatch({
      type: USER_WISHLIST_REMOVE,
    })
  } catch (error) {
    console.log(
      'Something went wrong... Please try again later or contact us for help.'
    )
  }
}

export const listProductsWishList =
  (keyword = '', pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: USER_WISHLIST_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/products/wishlist?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: USER_WISHLIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USER_WISHLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const topupBalance =
  ({ userId, amount, paymentResult, lang }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_TOPUP_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      await axios.put(
        `/api/users/${userId}/topup?lang=${lang}`, // if not amount, userId is the transactionId
        { paymentResult, amount },
        config
      )

      dispatch({
        type: USER_TOPUP_SUCCESS,
      })
    } catch (error) {
      dispatch({
        type: USER_TOPUP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const topupBalanceByNganluong =
  ({ userId, amount, paymentMethod, lang, bankCode }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_TOPUP_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      // Create transaction
      const newTransaction = {
        user: userId ? userId : null,
        type: 'Topup',
        amount,
        paymentMethod,
        status: 'pending',
      }

      const { data: transaction } = await axios.post(
        `/api/transactions`,
        newTransaction,
        config
      )

      const baseUrl = `https://${window.location.hostname}`
      // console.log('baseUrl: ', baseUrl)

      if (transaction) {
        // console.log('transaction: ', transaction)

        const paymentInfo = {
          orderId: transaction._id,
          amount: currentCurrency(amount, lang),
          customerId: userInfo ? userInfo.id : 'Guest',
          currency: lang === 'vi' ? 'VND' : 'USD',
          paymentMethod: paymentMethod,
          bankCode: bankCode,
          customerName: userInfo ? userInfo.name : 'Guest Buyer',
          customerEmail: userInfo ? userInfo.email : 'guest@example.com',
          customerPhone: '18001080',
          returnUrl: `${baseUrl}/topup/thankyou`,
          cancelUrl: `${baseUrl}/topup/fail`,
          transactionId: transaction._id,
          paymentType: '1',
          locale: lang,
        }
        // console.log('payment info: ', paymentInfo)

        const { data } = await axios.post(
          '/api/payment/checkout',
          paymentInfo,
          config
        )
        // console.log('nganluong response: ', data)

        window.location = data
      }
    } catch (error) {
      dispatch({
        type: USER_TOPUP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const addPoints = (userId, points) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.put(`/api/users/${userId}/points/add`, { points }, config)

    dispatch({
      type: USER_POINTS_ADD,
    })
  } catch (error) {
    console.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    )
  }
}

export const subtractPoints =
  (userId, points) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      await axios.put(
        `/api/users/${userId}/points/subtract`,
        { points },
        config
      )

      dispatch({
        type: USER_POINTS_ADD,
      })
    } catch (error) {
      console.error(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    }
  }

export const exchangeFund =
  ({ amount, type }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: USER_EXCHANGE_FUND_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/users/exchange-credits`,
        { amount, type },
        config
      )

      dispatch({
        type: USER_EXCHANGE_FUND_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: USER_EXCHANGE_FUND_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const sendEmailGallery =
  (email, subject, message) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EMAIL_SEND_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const body = {
        email,
        subject,
        message,
      }

      const { data } = await axios.post(
        `/api/email/user/gallery`, // if not amount, userId is the transactionId
        body,
        config
      )
      if (data.success) {
        dispatch({
          type: EMAIL_SEND_SUCCESS,
        })
      }
    } catch (error) {
      dispatch({
        type: EMAIL_SEND_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const changeLanguageUser = (lang) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/users/language`, { lang }, config)

    dispatch({
      type: USER_CHANGE_LANGUAGE,
      payload: data.language,
    })
  } catch (error) {
    console.error(
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    )
  }
}
