import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Button, Card } from 'react-bootstrap'
import Product from './Product'
import { useTranslation } from 'react-i18next'
import { getProductsByList } from '../actions/productActions'
import { addToCart } from '../actions/cartActions'
import { PRODUCT_LIST_RESET } from '../constants/productConstants'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import { setAlert } from '../actions/alertActions'

const ProductCombo = ({ product }) => {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()

  const [totalPrice, settotalPrice] = useState(0)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productList = useSelector((state) => state.productList)
  const { products } = productList

  const ids = product.combo.comboProducts.map((item) => item.product)

  useEffect(() => {
    dispatch(getProductsByList(ids))

    return () => {
      dispatch({ type: PRODUCT_LIST_RESET })
    }
  }, [])

  useEffect(() => {
    if (products?.length > 0) {
      //   Calculate total price
      let total = 0
      ids.forEach((id) => {
        const product = products.find((item) => item._id.toString() === id)
        if (product) {
          total += product.price
        }
      })
      settotalPrice(total)
    }
  }, [products])

  const addToCartHandler = () => {
    const comboPrice = product.combo.comboPrice
      ? product.combo.comboPrice
      : product.combo.comboDiscountType === 'amount'
      ? totalPrice - product.combo.comboDiscount
      : totalPrice - (totalPrice * product.combo.comboDiscount) / 100
    product.combo.comboProducts.forEach((item) =>
      dispatch(
        addToCart({
          id: item.product,
          qty: 1,
          comboPrice: (
            comboPrice / product?.combo.comboProducts.length
          ).toFixed(1),
        })
      )
    )

    dispatch(setAlert(t('ProductScreen.Added to cart'), 'success', 3000))
  }

  return (
    product.combo?.comboProducts?.length > 0 && (
      <div className='mt-3 p-3' style={{ border: '3px dotted' }}>
        <h4>{product.combo.comboName}</h4>
        {products?.length > 0 && (
          <Row
            style={{
              display: 'flex',
              //   justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {product.combo.comboProducts.map((x, index) => (
              <div key={index}>
                <Col sm={5} md={2}>
                  <Product
                    product={products.find(
                      (item) => item._id.toString() === x.product
                    )}
                    userInfo={userInfo}
                    minimal
                    notShowRefRate
                    showPrice
                  />
                </Col>
                {index < product.combo.comboProducts.length - 1 && (
                  <Col sm={1} md={1}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <i
                        className='fas fa-plus'
                        style={{ fontSize: '2rem', color: 'orange' }}
                      />
                    </div>
                  </Col>
                )}
              </div>
            ))}

            <Col sm={1} md={1}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <i
                  className='fas fa-equals'
                  style={{ fontSize: '2rem', color: 'orange' }}
                />
              </div>
            </Col>

            <Col sm={12} md={3} className='my-1'>
              {/* <Card> */}
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    {/* <Col md={3}>Price:</Col> */}
                    <Col>
                      {t('currency') + ' '}
                      <span style={{ fontSize: '2rem', color: 'yellow' }}>
                        <strong>
                          {product.combo.comboPrice
                            ? currentCurrencyToLocale(
                                product.combo.comboPrice,
                                i18n.language
                              )
                            : currentCurrencyToLocale(
                                product.combo.comboDiscountType === 'amount'
                                  ? totalPrice - product.combo.comboDiscount
                                  : totalPrice -
                                      (totalPrice *
                                        product.combo.comboDiscount) /
                                        100,
                                i18n.language
                              )}
                        </strong>
                      </span>
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col md={4}>{t('ProductScreen.Old Price')}</Col> */}
                    <Col>
                      {/* {t('currency') + ' '} */}
                      <span
                        style={{
                          fontSize: '1.2rem',
                          textDecoration: 'line-through',
                        }}
                      >
                        {currentCurrencyToLocale(totalPrice, i18n.language)}
                      </span>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Button
                    onClick={addToCartHandler}
                    className='btn-block my-1'
                    type='button'
                  >
                    <strong>{t('ProductScreen.Add To Cart')}</strong>
                  </Button>
                </ListGroup.Item>
              </ListGroup>
              {/* </Card> */}
            </Col>
          </Row>
        )}
      </div>
    )
  )
}

export default ProductCombo
