import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import SocialFollowFooter from './SocialFollowFooter'
import { useTranslation } from 'react-i18next'
import CookieConsent from 'react-cookie-consent'
import payments from '../assets/payments.png'
import nganluong from '../assets/nganluong.png'

const Footer = () => {
  const { t, i18n } = useTranslation()

  return (
    <footer>
      <Container>
        <div
          className='mt-5'
          style={{
            borderTop: '0.01em solid rgba(255, 255, 255, 0.3)',
          }}
        >
          <Row style={{ fontWeight: 'lighter' }} className='mt-3'>
            <Col md={2} className='mb-2'>
              <h5>nQivi</h5>
              <Row>
                <Link to='/about' className='px-3'>
                  {t('HomeScreen.AboutScreen')}
                </Link>
              </Row>
              <Row>
                <Link to='/contact' className='px-3'>
                  {t('HomeScreen.Contact')}
                </Link>
              </Row>
              <Row>
                <Link to='/faq' className='px-3'>
                  {t('HomeScreen.FAQ')}
                </Link>
              </Row>
              <Row>
                <Link to='/program' className='px-3'>
                  {t('Header.Program')}
                </Link>
              </Row>
            </Col>

            <Col md={2} className='mb-2'>
              <h5>{t('HomeScreen.Policies')}</h5>
              <Row>
                <Link to='/terms-of-service' className='px-3'>
                  {t('HomeScreen.TermsOfServiceScreen')}
                </Link>
              </Row>
              <Row>
                <Link to='/privacy-policy' className='px-3'>
                  {t('HomeScreen.PrivacyPolicyScreen')}
                </Link>
              </Row>
              <Row>
                <Link to='/how-to-buy' className='px-3'>
                  {t('HomeScreen.HowToBuyScreen')}
                </Link>
              </Row>
              <Row>
                <Link to='/delivery-return' className='px-3'>
                  {t('HomeScreen.DeliveryAndReturnScreen')}
                </Link>
              </Row>
            </Col>

            <Col className='text-center'>
              <h5>{t('HomeScreen.Follow Us')}</h5>
              <Row>
                <SocialFollowFooter />
              </Row>
            </Col>

            <Col md={4}>
              <>
                {window.location.hostname.includes('.vn') ? (
                  <>
                    <Row>
                      <h5> </h5>
                      <p>
                        <b>GPKD số:</b> 18A8025485 do Phòng Tài chính - Kế hoạch
                        Thành phố Việt Trì cấp 2022
                      </p>
                      {/* <br />
                    <div>
                      <b>Địa chỉ ĐKKD:</b> Tổ 21, Phường Bến Gót, Thành phố Việt
                      Trì, Tỉnh Phú Thọ
                    </div> */}
                    </Row>
                    <Row>
                      <div className='text-center'>
                        <Image
                          src={nganluong}
                          alt='payment methods'
                          className='mt-2'
                          width={220}
                        />
                      </div>
                    </Row>
                  </>
                ) : (
                  <Row>
                    {/* <p>
                        <b>Business Certificate:</b> 18A8025485 issued by
                        Finance - Planning Department of Viet Tri City in 2022
                      </p>
                      <br />
                      <div>
                        <b>Address :</b> Zone 21, Ben Got Ward, Viet Tri City,
                        Phu Tho Province, Vietnam
                      </div> */}

                    <Image src={payments} alt='payment methods' width={150} />
                  </Row>
                )}
              </>
            </Col>
          </Row>
          {/* <hr /> */}
          <p className='text-center mt-4' style={{ fontWeight: 'lighter' }}>
            <em>"{t('HomeScreen.Slogan')}"</em>
          </p>
          <Row>
            <Col className='text-center pb-3 pt-1'>Copyright &copy; nQivi</Col>
          </Row>

          {window.location.hostname.includes('.com') && (
            <CookieConsent
              location='bottom'
              buttonText='OK'
              style={{ background: '#573a7f', fontSize: '13px' }}
              // buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
              // expires={150}
            >
              {t('HomeScreen.CookieConsent')}
            </CookieConsent>
          )}
        </div>
      </Container>
    </footer>
  )
}

export default Footer
