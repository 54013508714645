import { useEffect, useRef } from 'react'
import './index.css'
import { useTranslation } from 'react-i18next'

let isMouseOver = false

const BannerFeeling = () => {
  const { t } = useTranslation()
  // const left = document.getElementById('left-side')
  const left = useRef(null)

  const handleMove = (e) => {
    if (left?.current && isMouseOver) {
      left.current.style.width = `${(e.clientX / window.innerWidth) * 100}%`
    }
  }

  useEffect(() => {
    document.onmousemove = (e) => handleMove(e)

    document.ontouchmove = (e) => handleMove(e.touches[0])

    // document.onmouseover = () => setIsMouseOver(true)

    // document.onmouseout = () => setIsMouseOver(false)

    // // Add event listener for mouse over
    // document.addEventListener('mouseoenter', () => (isMouseOver = true))

    // // Add event listener for mouse out
    // document.addEventListener('mouseleave', () => (isMouseOver = false))

    return () => {
      document.onmousemove = null
      document.ontouchmove = null

      // document.onmouseenter = null
      // document.onmouseleave = null

      // // Remove event listener for mouse over
      // document.removeEventListener('mouseoenter', () => (isMouseOver = true))

      // // Remove event listener for mouse out
      // document.removeEventListener('mouseleave', () => (isMouseOver = false))
    }
  }, [])

  return (
    <div
      className='banner-feeling'
      onMouseEnter={() => (isMouseOver = true)}
      onMouseLeave={() => (isMouseOver = false)}
    >
      <div id='left-side' ref={left} className='side'>
        <h2 className='title'>
          {t('HomeScreen.Today Im feeling')}
          <span className='fancy'> {t('HomeScreen.great')} </span>
        </h2>
      </div>
      <div id='right-side' className='side'>
        <h2 className='title'>
          {t('HomeScreen.Today Im feeling')}
          <span className='fancy'> {t('HomeScreen.bad')} </span>
        </h2>
      </div>
    </div>
  )
}

export default BannerFeeling
