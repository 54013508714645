import React from 'react'
import { Spinner } from 'react-bootstrap'
import BackgroundLight from './BackgroundLight'

const Loader = ({ size }) => {
  return (
    <Spinner
      animation='border'
      role='status'
      size={size}
      style={{
        margin: 'auto',
        display: 'block',
      }}
    >
      <span className='visually-hidden'>Loading...</span>
    </Spinner>

    // <Spinner
    //   animation='border'
    //   role='status'
    //   style={{
    //     width: '100px',
    //     height: '100px',
    //     margin: 'auto',
    //     display: 'block',
    //   }}
    // >
    //   <span className='sr-only'>Loading...</span>
    // </Spinner>
  )
}

export default Loader
