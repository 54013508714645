import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  useChainId,
  ConnectWallet,
  useAddress,
  useDisconnect,
  useMetamask,
  useWalletConnect,
  useCoinbaseWallet,
} from '@thirdweb-dev/react'
import { changeNetwork } from '../actions/blockchainActions'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import store from '../store'
import { AiOutlineWallet } from 'react-icons/ai'
import { MdLogout } from 'react-icons/md'

const networks = {
  1: 'Mainnet',
  137: 'Polygon',
  56: 'BinanceSmartChainMainnet',
  5: 'Goerli',
  42161: 'Arbitrum',
  421613: 'ArbitrumGoerli',
  43114: 'Avalanche',
  43113: 'AvalancheFujiTestnet',
  97: 'BinanceSmartChainTestnet',
  250: 'Fantom',
  4002: 'FantomTestnet',
  31337: 'Hardhat',
  1337: 'Localhost',
  80001: 'Mumbai',
  10: 'Optimism',
  420: 'OptimismGoerli',
}

export const ConnectWalletButton = () => {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()

  const [network, setNetwork] = useState([])

  // Connect Wallet method
  const chain = useChainId()

  useEffect(() => {
    if (networks[chain]) {
      setNetwork(networks[chain])
      dispatch(changeNetwork(networks[chain]))
    }
  }, [chain])

  // Listen to store changes
  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState()
      setNetwork(state.blockchain.network)
    })
  }, [])

  // Change language of connect wallet button
  useEffect(() => {
    const connectWalletButtons = document.querySelectorAll(
      '.connect-wallet-button > span > span'
    )
    connectWalletButtons.forEach((element) => {
      if (element.textContent === 'Connect Wallet' || 'Kết nối ví') {
        // element.innerText = t('ConnectMetamaskButton.Connect Wallet')
        element.textContent = t('ConnectMetamaskButton.Connect Wallet')
      }
    })
  }, [i18n.language, network, chain])

  // useEffect(() => {
  //   const connectWalletButtons = document.querySelectorAll(
  //     '.connect-wallet-button'
  //   )
  //   connectWalletButtons.forEach((element) => {
  //     element.addEventListener('click', () => {
  //       if (i18n.language !== 'en') {
  //         // if (true) {
  //         const copyButtons = document.querySelectorAll('#copy')
  //         copyButtons.forEach((element) => {
  //           const st = element?.innerHTML
  //           element.innerHTML = st.replace(
  //             'Copy address',
  //             t('ConnectMetamaskButton.Copy')
  //           )
  //         })

  //         const switchButtons = document.querySelectorAll('#switch-wallet')
  //         switchButtons.forEach((element) => {
  //           const st = element?.innerHTML
  //           element.innerHTML = st.replace(
  //             'Switch Account',
  //             t('ConnectMetamaskButton.Switch Account')
  //           )
  //         })

  //         const disconnectButtons = document.querySelectorAll('#disconnect')
  //         disconnectButtons.forEach((element) => {
  //           const st = element?.innerHTML
  //           element.innerHTML = st.replace(
  //             'Disconnect',
  //             t('ConnectMetamaskButton.Disconnect')
  //           )
  //         })
  //       }
  //     })
  //   })
  // }, [])
  // // Change language of connect wallet button

  return (
    <div
      style={{
        // fontWeight: 'lighter',
        fontSize: '0.9rem',
        height: '42px',
        border: '3px solid transparent',
        borderRadius: '0.5rem',
        background:
          'linear-gradient(90deg,#ECECEC,#ECECEC),linear-gradient(90deg,#418dff,#cc25b3)',
        backgroundClip: 'padding-box,border-box',
        backgroundOrigin: 'padding-box,border-box',
      }}
    >
      <ConnectWallet
        className='connect-wallet-button'
        displayName={t('ConnectMetamaskButton.Connect Metamask')}
        accentColor='#ffffff'
        colorMode='light'
        ecosystem='evm'
        // ml={{ base: 0, md: 2 }}
        // colorScheme='#0000ff'
      />
    </div>
  )
}

export const ConnectWalletButtonV2 = ({ width }) => {
  const connectWithMetamask = useMetamask()
  const connectWithWalletConnect = useWalletConnect()
  const connectWithCoinbaseWallet = useCoinbaseWallet()
  const disconnectWallet = useDisconnect()

  const address = useAddress()
  const { t } = useTranslation()

  return (
    <div>
      {address ? (
        <>
          <span
            className='btn btn-light btn-sm ms-1'
            style={{ fontSize: '0.9rem' }}
          >
            <AiOutlineWallet />{' '}
            {`${address.substring(0, 5)}...${address.slice(-4)}`}
          </span>
          <button
            className='btn btn-secondary btn-sm'
            onClick={disconnectWallet}
            style={{ fontSize: '0.9rem' }}
          >
            <MdLogout />
          </button>
        </>
      ) : (
        <Dropdown>
          <Dropdown.Toggle
            variant='secondary'
            // className='btn-sm'
            className='btn btn-light text-dark btn-sm ms-1 connect-wallet-button-v2'
            style={{
              // fontWeight: 'lighter',
              width: `${width}px`,
              fontSize: '0.9rem',
              border: '3px solid transparent',
              borderRadius: '10px',
              background:
                'linear-gradient(90deg,#ECECEC,#ECECEC),linear-gradient(90deg,#418dff,#cc25b3)',
              backgroundClip: 'padding-box,border-box',
              backgroundOrigin: 'padding-box,border-box',
            }}
          >
            {t('ConnectMetamaskButton.Connect Wallet')}
          </Dropdown.Toggle>

          <Dropdown.Menu
            bg='light'
            variant='light'
            align='end'
            style={{
              // width: `${width}px`,
              width: `120px`,
              fontSize: '0.9rem',
              color: 'black',
              backgroundColor: 'white',
            }}
          >
            <Dropdown.Item onClick={connectWithMetamask}>
              {t('ConnectMetamaskButton.MetaMask')}
            </Dropdown.Item>
            <Dropdown.Item onClick={connectWithCoinbaseWallet}>
              {t('ConnectMetamaskButton.Coinbase Wallet')}
            </Dropdown.Item>
            <Dropdown.Item onClick={connectWithWalletConnect}>
              {t('ConnectMetamaskButton.WalletConnect')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}
