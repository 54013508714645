import {
  THREE_GET_CAMERA,
  THREE_GET_RENDERER,
  THREE_GET_SCENE,
  THREE_RESET,
  THREE_SAVE_MODEL,
} from '../constants/threeConstants'

export const threeReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case THREE_GET_RENDERER:
      return { ...state, renderer: payload }
    case THREE_GET_SCENE:
      return { ...state, scene: payload }
    case THREE_GET_CAMERA:
      return { ...state, camera: payload }
    case THREE_SAVE_MODEL:
      return { ...state, model: payload }
    case THREE_RESET:
      return {}

    default:
      return state
  }
}
