import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_RESET,
  CART_SAVE_COUPON,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from '../constants/cartConstants'

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  const { type, payload } = action
  switch (type) {
    case CART_ADD_ITEM:
      const item = payload

      const existItem = state.cartItems.find((x) => x.product === item.product)

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            // x.product === existItem.product ? item : x
            // Add quantity to existing item and change to lower price if applicable
            x.product === existItem.product
              ? {
                  ...x,
                  qty: item.atc ? item.qty : x.qty + item.qty,
                  price: item.price < x.price ? item.price : x.price,
                }
              : x
          ),
        }
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        }
      }
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.product !== payload),
      }
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: payload,
      }
    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: payload,
      }
    case CART_SAVE_COUPON:
      return {
        ...state,
        coupon: payload,
      }
    case CART_RESET:
      return {
        ...state,
        cartItems: [],
      }
    default:
      return state
  }
}
