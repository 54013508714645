// import { createStore, combineReducers, applyMiddleware } from 'redux'
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { getCookie } from './utils/cookies'
// import thunk from 'redux-thunk'
// import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productListMyReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewReducer,
  productTopRatedReducer,
  productGroupBuyReducer,
  productShoeReducer,
  productGalleryReducer,
  productFeaturedReducer,
  productGroupArtReducer,
  productsRelatedReducer,
  productsUpSaleReducer,
} from './reducers/productReducers'
import { cartReducer } from './reducers/cartReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userVerifyReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userWithdrawReducer,
  userWishListReducer,
  userTopupReducer,
  userExchangeFundReducer,
  emailReducer,
  userPaymentReducer,
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderListMyReducer,
  orderListReducer,
  orderRefListMyReducer,
  orderDeliverReducer,
  orderUpdateReducer,
  orderDeleteReducer,
} from './reducers/orderReducers'
import { transactionReducer } from './reducers/transactionReducers'
import { promoReducer } from './reducers/promoReducers'
import alert from './reducers/alertReducers'
import refReducers from './reducers/refReducers'
import mintReducers from './reducers/mintReducers'
import { reportAdminReducer } from './reducers/reportReducers'
import { podReducer } from './reducers/podReducers'
import { contractReducer } from './reducers/contractReducers'
import { storeReducer } from './reducers/storeReducers'
import { articleReducer } from './reducers/articleReducers'
import blockchainReducers from './reducers/blockchainReducers'
import { settingReducer } from './reducers/settingReducers'
import { requestReducer } from './reducers/requestReducers'
import { marketReducer } from './reducers/marketReducers'
import { auctionReducer } from './reducers/auctionReducers'
import { threeReducer } from './reducers/threeReducers'

const rootReducer = combineReducers({
  alert,
  ref: refReducers,
  mint: mintReducers,
  market: marketReducer,
  contract: contractReducer,
  setting: settingReducer,
  store: storeReducer,
  request: requestReducer,
  blockchain: blockchainReducers,
  promo: promoReducer,
  pod: podReducer,
  cart: cartReducer,
  three: threeReducer,
  articleReducer: articleReducer,
  reportAdmin: reportAdminReducer,
  transaction: transactionReducer,
  auctionDetails: auctionReducer,
  productShoe: productShoeReducer,
  productGroupBuy: productGroupBuyReducer,
  productList: productListReducer,
  productsUpSale: productsUpSaleReducer,
  productsRelated: productsRelatedReducer,
  productGallery: productGalleryReducer,
  productFeatured: productFeaturedReducer,
  productListMy: productListMyReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReview: productReviewReducer,
  productTopRated: productTopRatedReducer,
  productGroupArt: productGroupArtReducer,
  orderCreate: orderCreateReducer,
  orderUpdate: orderUpdateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderRefListMy: orderRefListMyReducer,
  orderDeliver: orderDeliverReducer,
  orderDelete: orderDeleteReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userVerify: userVerifyReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userWithdraw: userWithdrawReducer,
  userWishList: userWishListReducer,
  userTopup: userTopupReducer,
  userExchangeFund: userExchangeFundReducer,
  userPayment: userPaymentReducer,
  email: emailReducer,
})

// Check ref cookie
const refFromCookie = getCookie('ref')

// Check blockchain network
const networkFromStorage = localStorage.getItem('network')
  ? localStorage.getItem('network')
  : 'Polygon'

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const couponFromStorage = localStorage.getItem('coupon')
  ? JSON.parse(localStorage.getItem('coupon'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : null

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    coupon: couponFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
  ref: { ref: refFromCookie ? refFromCookie : null },
  blockchain: { network: networkFromStorage },
}

// const middleware = [thunk]

// const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 })
// const composeEnhancers = composeWithDevTools({})

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeEnhancers(applyMiddleware(...middleware))
// )

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  devTools: false,
  // middleware: [thunk, immutableStateInvariant, serializableStateInvariant]
})

export default store
