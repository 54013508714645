export const REQUEST_RESET = 'REQUEST_RESET'

export const REQUEST_CREATE_REQUEST = 'REQUEST_CREATE_REQUEST'
export const REQUEST_CREATE_SUCCESS = 'REQUEST_CREATE_SUCCESS'
export const REQUEST_CREATE_FAIL = 'REQUEST_CREATE_FAIL'
export const REQUEST_CREATE_RESET = 'REQUEST_CREATE_RESET'

export const REQUEST_GET_ALL_REQUEST = 'REQUEST_GET_ALL_REQUEST'
export const REQUEST_GET_ALL_SUCCESS = 'REQUEST_GET_ALL_SUCCESS'
export const REQUEST_GET_ALL_FAIL = 'REQUEST_GET_ALL_FAIL'

export const REQUEST_GET_MY_REQUEST = 'REQUEST_GET_MY_REQUEST'
export const REQUEST_GET_MY_SUCCESS = 'REQUEST_GET_MY_SUCCESS'
export const REQUEST_GET_MY_FAIL = 'REQUEST_GET_MY_FAIL'

export const REQUEST_GET_REQUEST = 'REQUEST_GET_REQUEST'
export const REQUEST_GET_SUCCESS = 'REQUEST_GET_SUCCESS'
export const REQUEST_GET_FAIL = 'REQUEST_GET_FAIL'

export const REQUEST_GET_TYPE_REQUEST = 'REQUEST_GET_TYPE_REQUEST'
export const REQUEST_GET_TYPE_SUCCESS = 'REQUEST_GET_TYPE_SUCCESS'
export const REQUEST_GET_TYPE_FAIL = 'REQUEST_GET_TYPE_FAIL'

export const REQUEST_DELETE_REQUEST = 'REQUEST_DELETE_REQUEST'
export const REQUEST_DELETE_SUCCESS = 'REQUEST_DELETE_SUCCESS'
export const REQUEST_DELETE_FAIL = 'REQUEST_DELETE_FAIL'

export const REQUEST_UPDATE_REQUEST = 'REQUEST_UPDATE_REQUEST'
export const REQUEST_UPDATE_SUCCESS = 'REQUEST_UPDATE_SUCCESS'
export const REQUEST_UPDATE_FAIL = 'REQUEST_UPDATE_FAIL'
export const REQUEST_UPDATE_RESET = 'REQUEST_UPDATE_RESET'
