import axios from 'axios'
import i18n from 'i18next'
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_COUPON,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from '../constants/cartConstants'

export const addToCart =
  ({ id, qty, variantId, comboPrice, atc, promo }) =>
  async (dispatch, getState) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
      },
    }

    const { data } = await axios.get(`/api/products/${id}`, config)
    // console.log('product', data)
    // console.log('variantId', variantId)
    // console.log('id', id)

    let variant
    let groupArt
    if (variantId) {
      variant = data.variants.find((v) => v._id.toString() === variantId)
      groupArt = data.groupArt.art.find((item) => (item.variantId = variantId))
    }

    const item = {
      product: data._id,
      productLink: data?.productLink,
      sellerEmail:
        variant && groupArt?.sellerEmail ? groupArt?.sellerEmail : null,
      variant: variant ? variant._id : null,
      name: !variant ? data.name : variant.name,
      image: !variant ? data?.images[0] : variant.image,
      price: comboPrice
        ? comboPrice
        : data?.promotion &&
          data?.promotion.isActive &&
          data?.promotion.promoQuantity > 0
        ? data?.promotion.promoPrice
        : promo && !variant
        ? data.price * (1 - promo / 100)
        : promo && variant
        ? variant.price * (1 - promo / 100)
        : !variant
        ? data.price
        : variant.price,
      countInStock: !variant ? data.countInStock : variant.quantity,
      qty: qty,
      membershipDuration: variant ? parseInt(variant.value) : null,
      isDigital: data.isDigital,
      isPhysical: data.isPhysical,
      isMintable: data?.isMintable,
      type: data?.type,
      downloadUrl: !variant ? data.downloadUrl : variant.downloadUrl,
      contractAddress: data.contractAddress,
      tokenId: data.tokenId,
      tokenDesc: data.tokenDesc,
      blockchain: data.blockchain,
      mediaUrl: data?.mediaUrl,
      atc: atc, // ** atc = from CartScreen, finetune qty not to add to existing qty
    }

    dispatch({
      type: CART_ADD_ITEM,
      payload: item,
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
  }

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })

  localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  // localStorage.setItem('paymentMethod', JSON.stringify(data))
}

export const saveCoupon = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_COUPON,
    payload: data,
  })

  localStorage.setItem('coupon', JSON.stringify(data))
}
