import {
  POD_LIST_PRODUCTS_FAIL,
  POD_LIST_PRODUCTS_REQUEST,
  POD_LIST_PRODUCTS_SUCCESS,
  POD_LIST_VARIANTS_FAIL,
  POD_LIST_VARIANTS_REQUEST,
  POD_LIST_VARIANTS_SUCCESS,
  POD_MOCKUP_CREATE_FAIL,
  POD_MOCKUP_CREATE_REQUEST,
  POD_MOCKUP_CREATE_SUCCESS,
  POD_SHIPPING_FAIL,
  POD_SHIPPING_REQUEST,
  POD_SHIPPING_SUCCESS,
  POD_ORDER_CREATED_FAIL,
  POD_ORDER_CREATED_REQUEST,
  POD_ORDER_CREATED_SUCCESS,
  POD_ORDER_PAID_FAIL,
  POD_ORDER_PAID_REQUEST,
  POD_ORDER_PAID_SUCCESS,
  POD_ORDER_TRACKING_FAIL,
  POD_ORDER_TRACKING_REQUEST,
  POD_ORDER_TRACKING_SUCCESS,
  POD_MOCKUP_CREATE_RESET,
  POD_LOAD_PRINT_FILE,
} from '../constants/podConstants'

export const podReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case POD_LIST_PRODUCTS_REQUEST:
      return {
        ...state,
        loadingProducts: true,
      }
    case POD_LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        loadingProducts: false,
        products: payload,
      }
    case POD_LIST_PRODUCTS_FAIL:
      return {
        ...state,
        loadingProducts: false,
        error: payload,
      }

    case POD_LIST_VARIANTS_REQUEST:
      return {
        ...state,
        loadingVariants: true,
      }
    case POD_LIST_VARIANTS_SUCCESS:
      return {
        ...state,
        loadingVariants: false,
        variants: payload,
      }
    case POD_LIST_VARIANTS_FAIL:
      return {
        ...state,
        loadingVariants: false,
        error: payload,
      }

    case POD_MOCKUP_CREATE_REQUEST:
      return {
        ...state,
        loadingMockups: true,
      }
    case POD_MOCKUP_CREATE_SUCCESS:
      return {
        ...state,
        loadingMockups: false,
        mockups: payload,
      }
    case POD_MOCKUP_CREATE_FAIL:
      return {
        ...state,
        loadingMockups: false,
        error: payload,
      }
    case POD_MOCKUP_CREATE_RESET:
      return {
        ...state,
        loadingMockups: false,
        mockups: {},
      }

    case POD_SHIPPING_REQUEST:
      return {
        ...state,
        loadingShipping: true,
      }
    case POD_SHIPPING_SUCCESS:
      return {
        ...state,
        loadingShipping: false,
        shippingInfo: payload,
      }
    case POD_SHIPPING_FAIL:
      return {
        ...state,
        loadingShipping: false,
        error: payload,
      }

    case POD_ORDER_PAID_REQUEST:
      return {
        ...state,
        loadingPay: true,
      }
    case POD_ORDER_PAID_SUCCESS:
      return {
        ...state,
        loadingPay: false,
        successPay: true,
        paymentResult: payload,
      }
    case POD_ORDER_PAID_FAIL:
      return {
        ...state,
        loadingPay: false,
        error: payload,
      }

    case POD_ORDER_CREATED_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case POD_ORDER_CREATED_SUCCESS:
      return {
        ...state,
        loading: false,
        order: payload,
      }
    case POD_ORDER_CREATED_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case POD_ORDER_TRACKING_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case POD_ORDER_TRACKING_SUCCESS:
      return {
        ...state,
        loading: false,
        orderTracking: payload,
      }
    case POD_ORDER_TRACKING_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case POD_LOAD_PRINT_FILE:
      return {
        ...state,
        printFile: payload,
      }
    default:
      return state
  }
}
