import axios from 'axios'
import i18n from 'i18next'
import {
  PROMO_CREATE_FAIL,
  PROMO_CREATE_REQUEST,
  PROMO_CREATE_SUCCESS,
  PROMO_DELETE_FAIL,
  PROMO_DELETE_REQUEST,
  PROMO_DELETE_SUCCESS,
  PROMO_GET_FAIL,
  PROMO_GET_REQUEST,
  PROMO_GET_SUCCESS,
  PROMO_LIST_FAIL,
  PROMO_LIST_REQUEST,
  PROMO_LIST_SUCCESS,
  PROMO_SETACTIVE_FAIL,
  PROMO_SETACTIVE_REQUEST,
  PROMO_SETACTIVE_SUCCESS,
  PROMO_UPDATE_FAIL,
  PROMO_UPDATE_REQUEST,
  PROMO_UPDATE_SUCCESS,
} from '../constants/promoConstants'

export const createPromoCode = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMO_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const promo = {
      promoId: 'EDIT_ME',
      discount: 0,
      qty: 1,
    }

    const { data } = await axios.post(`/api/promo-codes`, promo, config)

    dispatch({
      type: PROMO_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROMO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updatePromoCode = (promo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMO_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/promo-codes/${promo._id}`,
      promo,
      config
    )

    dispatch({
      type: PROMO_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROMO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deletePromoCode = (_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMO_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/promo-codes/${_id}`, config)

    dispatch({
      type: PROMO_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: PROMO_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAllPromos = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMO_LIST_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/promo-codes`, config)

    dispatch({
      type: PROMO_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROMO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getPromoCode =
  (promoId = 'A') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PROMO_GET_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }
      if (promoId === '') promoId = 'NQIVI'

      const { data } = await axios.get(`/api/promo-codes/${promoId}`, config)

      dispatch({
        type: PROMO_GET_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PROMO_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getCouponCode =
  (promoId = 'A') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PROMO_GET_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }
      if (promoId === '') promoId = 'NQIVI'

      const { data } = await axios.get(
        `/api/promo-codes/coupon/${promoId}`,
        config
      )

      dispatch({
        type: PROMO_GET_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PROMO_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getPromoEdit =
  (promoId = 'A') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PROMO_GET_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      if (promoId === '') promoId = 'NQIVI'

      const { data } = await axios.get(
        `/api/promo-codes/${promoId}/edit`,
        config
      )

      dispatch({
        type: PROMO_GET_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: PROMO_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const takePromoCode = (promoInfo) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMO_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.put(
      `/api/promo-codes/${promoInfo.promoId}/take`,
      promoInfo,
      config
    )

    dispatch({
      type: PROMO_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROMO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const setActivePromo = (_id, status) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMO_SETACTIVE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/promo-codes/${_id}/set-active`,
      { isActive: status },
      config
    )

    dispatch({
      type: PROMO_SETACTIVE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PROMO_SETACTIVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
