import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { addToWishList, removeFromWishList } from '../actions/userActions'
import { useTranslation } from 'react-i18next'

const WishList = ({ color, id, userInfo, product }) => {
  const { t } = useTranslation()
  const [liked, setLiked] = useState(product?.wishList?.includes(userInfo?.id))
  const dispatch = useDispatch()

  return (
    <>
      <OverlayTrigger
        placement='bottom'
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip>{t('ProductScreen.Add to wishlist tooltip')}</Tooltip>
        }
      >
        <i
          style={{ color, cursor: 'pointer', fontSize: '1.2rem' }}
          className={liked ? 'fas fa-heart' : 'far fa-heart'}
          onClick={() => {
            if (userInfo) {
              if (!liked) {
                dispatch(addToWishList(id))
              } else {
                dispatch(removeFromWishList(id))
              }
            }
            setLiked(!liked)
          }}
        ></i>
      </OverlayTrigger>
    </>
  )
}

WishList.defaultProps = {
  color: '#D9307D',
}

WishList.propTypes = {
  color: PropTypes.string,
}

export default WishList
