import { useEffect, useState } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Modal,
} from 'react-bootstrap'
import { logout } from '../actions/userActions'
import SearchBox from './SearchBox'
import logo from '../assets/logo120px.png'
// import BgImage from '../assets/Header_bg.jpg'
import { useTranslation } from 'react-i18next'
import { saveLangToLocal } from '../utils/saveLangToLocal'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import { saveRef } from '../actions/refActions'
import { changeNetwork } from '../actions/blockchainActions'
// import { ConnectMetamaskButton } from './ConnectMetamaskButton'
import { ConnectWalletButtonV2 } from '../components/ConnectWalletButton'
import { useAddress, useNetwork, useNetworkMismatch } from '@thirdweb-dev/react'
import { ConnectWallet, useChainId, ChainId } from '@thirdweb-dev/react'
import ReactGA from 'react-ga4'
import { FiPower } from 'react-icons/fi'
import { BsCashCoin } from 'react-icons/bs'
import { IoDiamond } from 'react-icons/io5'
// import { RiHomeHeartLine } from 'react-icons/ri'
import { getUpdatedUserInfo, changeLanguageUser } from '../actions/userActions'
import CartScreen from '../screens/CartScreen'
import store from '../store'

const flag_en = (
  <Image
    src='/assets/flag_en_40px.png'
    // roundedCircle
    style={{ width: '20px' }}
  />
)
const flag_vi = (
  <Image
    src='/assets/flag_vi_40px.png'
    // roundedCircle
    style={{ width: '20px' }}
  />
)

const networks = {
  1: 'Mainnet',
  137: 'Polygon',
  56: 'BinanceSmartChainMainnet',
  5: 'Goerli',
  42161: 'Arbitrum',
  421613: 'ArbitrumGoerli',
  43114: 'Avalanche',
  43113: 'AvalancheFujiTestnet',
  97: 'BinanceSmartChainTestnet',
  250: 'Fantom',
  4002: 'FantomTestnet',
  31337: 'Hardhat',
  1337: 'Localhost',
  80001: 'Mumbai',
  10: 'Optimism',
  420: 'OptimismGoerli',
}

function usePageViews() {
  let location = useLocation()
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize('G-9LQJQFTVFT')
      // Global web:	G-91LE7TV22C
      // VN web:	G-9LQJQFTVFT
      window.GA_INITIALIZED = true
    }
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
  }, [location])
}

const Header = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [showCart, setShowCart] = useState(false)
  const [cartItems, setCartItems] = useState([])
  const [userInfo, setUserInfo] = useState([])
  const [network, setNetwork] = useState([])

  // // Connect Wallet method
  // const chain = useChainId()

  // useEffect(() => {
  //   if (networks[chain]) {
  //     setNetwork(networks[chain])
  //     dispatch(changeNetwork(networks[chain]))
  //   }
  // }, [chain])

  // Listen to store changes
  useEffect(() => {
    store.subscribe(() => {
      const state = store.getState()
      setCartItems(state.cart.cartItems)
      setUserInfo(state.userLogin.userInfo)
      setNetwork(state.blockchain.network)
    })
  }, [])

  // // Change language of connect wallet button
  // useEffect(() => {
  //   const connectWalletButtons = document.querySelectorAll(
  //     '.connect-wallet-button > span > span'
  //   )
  //   connectWalletButtons.forEach((element) => {
  //     if (
  //       element.innerHTML === 'Connect Wallet' ||
  //       element.innerHTML === 'Kết nối ví'
  //     ) {
  //       element.innerHTML = t('ConnectMetamaskButton.Connect Wallet')
  //       // element.textContent = t('ConnectMetamaskButton.Connect Wallet')
  //     }
  //   })
  // }, [i18n.language, network, chain])

  // useEffect(() => {
  //   const connectWalletButtons = document.querySelectorAll(
  //     '.connect-wallet-button'
  //   )
  //   connectWalletButtons.forEach((element) => {
  //     element.addEventListener('click', () => {
  //       if (i18n.language !== 'en') {
  //         // if (true) {
  //         const copyButtons = document.querySelectorAll('#copy')
  //         copyButtons.forEach((element) => {
  //           const st = element?.innerHTML
  //           element.innerHTML = st.replace(
  //             'Copy address',
  //             t('ConnectMetamaskButton.Copy')
  //           )
  //         })

  //         const switchButtons = document.querySelectorAll('#switch-wallet')
  //         switchButtons.forEach((element) => {
  //           const st = element?.innerHTML
  //           element.innerHTML = st.replace(
  //             'Switch Account',
  //             t('ConnectMetamaskButton.Switch Account')
  //           )
  //         })

  //         const disconnectButtons = document.querySelectorAll('#disconnect')
  //         disconnectButtons.forEach((element) => {
  //           const st = element?.innerHTML
  //           element.innerHTML = st.replace(
  //             'Disconnect',
  //             t('ConnectMetamaskButton.Disconnect')
  //           )
  //         })
  //       }
  //     })
  //   })
  // }, [])
  // // Change language of connect wallet button

  const params = new URLSearchParams(window.location?.search)

  // const { userInfo } = useSelector((state) => state.userLogin)

  // const cartItems = useSelector((state) => state.cart.cartItems, shallowEqual)

  // const { network } = useSelector((state) => state.blockchain)

  // Remind user to change to correct network
  const networkMismatch = useNetworkMismatch()
  const [, switchNetwork] = useNetwork()
  const address = useAddress()

  useEffect(() => {
    // Ensure user is on the correct network
    if (address && networkMismatch) {
      switchNetwork && switchNetwork(ChainId[network])
      return
    }
  }, [network, address])

  const userAvatar = (
    <Image src={userInfo?.avatar} roundedCircle style={{ width: '30px' }} />
  )

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    saveLangToLocal(lang)
    dispatch(changeLanguageUser(lang))
  }

  useEffect(() => {
    if (params && params.get('r')) {
      dispatch(saveRef(params.get('r')))
      // console.log('ref: ', params.get('r'))
    }

    const currentLang =
      localStorage.getItem('language') && localStorage.getItem('language')

    if (currentLang && currentLang !== i18n.language) {
      i18n.changeLanguage(currentLang)
    }

    if (userInfo) {
      dispatch(getUpdatedUserInfo(userInfo.id, i18n.language))
    }
  }, [])

  const logoutHandler = () => {
    dispatch(logout())
  }

  usePageViews()

  return (
    <header>
      {showCart && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
          }}
        >
          <Modal
            show={showCart}
            fullscreen
            size='lg'
            onHide={() => setShowCart(false)}
          >
            <Modal.Header closeButton />
            {/* <Modal.Title>Cart</Modal.Title> */}
            {/* </Modal.Header> */}
            <Modal.Body>
              <Route
                render={({ history }) => (
                  <CartScreen history={history} setShowCart={setShowCart} />
                )}
              />
            </Modal.Body>
          </Modal>
        </div>
      )}

      <Navbar
        bg='dark'
        variant='dark'
        expand='lg'
        collapseOnSelect
        // style={{
        //   backgroundImage: `url(${BgImage})`,
        //   backgroundSize: 'cover',
        // }}
      >
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand className='me-0'>
              <Image src={logo} alt='nqivi-logo' />
            </Navbar.Brand>
          </LinkContainer>

          {/* CONNECT WALLET ON MOBILE */}

          <span className='connect-wallet-mobile'>
            {/* <ConnectMetamaskButton /> */}
            <ConnectWalletButtonV2 width={160} />
          </span>

          {/* <span
            className='connect-wallet-mobile'
            style={{
              // fontWeight: 'lighter',
              fontSize: '0.9rem',
              height: '42px',
              border: '3px solid transparent',
              borderRadius: '0.5rem',
              background:
                'linear-gradient(90deg,#ECECEC,#ECECEC),linear-gradient(90deg,#418dff,#cc25b3)',
              backgroundClip: 'padding-box,border-box',
              backgroundOrigin: 'padding-box,border-box',
            }}
          >
            <ConnectWallet
              className='connect-wallet-button'
              displayName={t('ConnectMetamaskButton.Connect Metamask')}
              accentColor='#ffffff'
              colorMode='light'
              ecosystem='evm'
            />
          </span> */}

          <Navbar.Toggle aria-controls='basic-navbar-nav' />

          <Route render={({ history }) => <SearchBox history={history} />} />

          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              <div
                style={{ position: 'relative', cursor: 'pointer' }}
                // onClick={() => setShowCart(true)}
              >
                <LinkContainer to='/cart'>
                  <Nav.Link>
                    <i className='fas fa-shopping-cart'></i>
                  </Nav.Link>
                </LinkContainer>

                {/* CART POPUP BUTTON */}
                {/* <div className='mt-1 mx-1'>
                  <i className='fas fa-shopping-cart'></i>
                </div> */}

                {cartItems.length > 0 && (
                  <span
                    style={{
                      position: 'absolute',
                      top: '0px',
                      left: '-3px',
                      background: '#ff0000',
                      color: '#fff',
                      borderRadius: '50%',
                      padding: '0px 6px',
                      fontSize: '12px',
                    }}
                  >
                    {cartItems.length}
                  </span>
                )}
              </div>

              {/* <OverlayTrigger
                placement='bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t('Header.Market Tooltip')}</Tooltip>}
              >
                <Nav.Link as={Link} to='/market'>
                  {t('Header.Market')}
                </Nav.Link>
              </OverlayTrigger> */}

              {/* <OverlayTrigger
                placement='bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t('Header.Create Tooltip')}</Tooltip>}
              >
                <Nav.Link as={Link} to='/art/upload'>
                  <i className='fas fa-palette'></i> {t('Header.Create')}
                </Nav.Link>
              </OverlayTrigger> */}

              <OverlayTrigger
                placement='bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t('Header.Track Order Tooltip')}</Tooltip>}
              >
                <Nav.Link as={Link} to='/tracking'>
                  {t('Header.Track Order')}
                </Nav.Link>
              </OverlayTrigger>

              <OverlayTrigger
                placement='bottom'
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t('Header.Program Tooltip')}</Tooltip>}
                // overlay={renderTooltip}
              >
                <Nav.Link as={Link} to='/program'>
                  {t('Header.Program')}
                  {/* <i className='fas fa-handshake'></i> */}
                </Nav.Link>
              </OverlayTrigger>

              {/* ADMIN */}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>{t('Header.Users')}</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>{t('Header.Products')}</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>{t('Header.Orders')}</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/promolist'>
                    <NavDropdown.Item>{t('Header.Promos')}</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/report'>
                    <NavDropdown.Item>{t('Header.Report')}</NavDropdown.Item>
                  </LinkContainer>

                  <NavDropdown.Divider />

                  <LinkContainer to='/admin/requestlist'>
                    <NavDropdown.Item>{t('Header.Requests')}</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/bulk-orders'>
                    <NavDropdown.Item>
                      {t('Header.Bulk Orders')}
                    </NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/admin/run'>
                    <NavDropdown.Item>{t('Header.Run')}</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}

              {/* PROFILE */}
              {userInfo ? (
                <NavDropdown
                  title={
                    userInfo?.avatar
                      ? userAvatar
                      : userInfo?.name?.split(' ')[0]
                  }
                  id='username'
                >
                  {userInfo?.credits > 0 && (
                    <LinkContainer to={`/topup/${userInfo.id}`}>
                      <NavDropdown.Item>
                        <span className='text-success'>
                          <BsCashCoin />
                        </span>
                        {'  '}
                        {t('Header.Balance')}
                        {': '}
                        {t('currency')}{' '}
                        {currentCurrencyToLocale(
                          userInfo?.credits,
                          i18n.language
                        )}
                        {userInfo.creditsLocked !== 0 && (
                          <div
                            style={{ color: 'blueviolet', fontSize: '0.9rem' }}
                          >
                            {t('Header.Locked Balance')}
                            {': '}
                            {currentCurrencyToLocale(
                              userInfo?.creditsLocked,
                              i18n.language
                            )}
                          </div>
                        )}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}

                  {userInfo?.points > 0 && (
                    <LinkContainer to={`/exchange`}>
                      <NavDropdown.Item>
                        <span className='text-info'>
                          <IoDiamond />
                        </span>
                        {'  '}
                        {t('Header.Points')}
                        {': '}
                        {userInfo.points}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}

                  <LinkContainer to={`/topup/${userInfo.id}`}>
                    <NavDropdown.Item>{t('Header.Topup')}</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/exchange'>
                    <NavDropdown.Item>{t('Header.Exchange')}</NavDropdown.Item>
                  </LinkContainer>

                  <NavDropdown.Divider />

                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>{t('Header.Profile')}</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/nft'>
                    <NavDropdown.Item>{t('Header.My NFTs')}</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to='/wishlist'>
                    <NavDropdown.Item>
                      {t('Header.My Wishlist')}
                    </NavDropdown.Item>
                  </LinkContainer>

                  {userInfo.ref && userInfo.ref.isEnabled && (
                    <LinkContainer to='/profile/ref'>
                      <NavDropdown.Item>{t('Header.Ref')}</NavDropdown.Item>
                    </LinkContainer>
                  )}

                  {/* {!userInfo.isAdmin && userInfo.isCreator && (
                    <LinkContainer to='/admin/productlist'>
                      <NavDropdown.Item>
                        {t('Header.Products')}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )} */}

                  {/* FINANCER */}
                  {userInfo.isFinancer && (
                    <>
                      <LinkContainer to='/admin/report'>
                        <NavDropdown.Item>
                          {t('Header.Finance')}
                        </NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer to='/finance/userlist'>
                        <NavDropdown.Item>
                          {t('Header.Users Balance')}
                        </NavDropdown.Item>
                      </LinkContainer>
                    </>
                  )}

                  <NavDropdown.Divider />

                  {/* CREATOR */}
                  {userInfo.isCreator && (
                    <LinkContainer to='/art/collection'>
                      <NavDropdown.Item>
                        {t('Header.Create Collection')}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {userInfo.isCreator && (
                    <LinkContainer to='/collection'>
                      <NavDropdown.Item>
                        {t('Header.My Collections')}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {userInfo.isCreator && (
                    <LinkContainer to='/store'>
                      <NavDropdown.Item>
                        {t('Header.My Store')}
                      </NavDropdown.Item>
                    </LinkContainer>
                  )}

                  <NavDropdown.Item onClick={logoutHandler}>
                    <FiPower />
                    {'  ' + t('Header.Logout')}
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <>
                  <LinkContainer to='/login'>
                    <Nav.Link>
                      {/* <RiHomeHeartLine /> */}
                      <i className='fas fa-right-to-bracket'></i>
                      {' ' + t('Header.Sign In')}
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/register'>
                    <Nav.Link>
                      <i className='fas fa-user-plus'></i> {t('Header.Sign Up')}
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}

              {/* Change language */}

              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t('Header.Language')}</Tooltip>}
              >
                <NavDropdown
                  // title={i18n.language === 'en' ? 'Eng' : 'Vie'}
                  title={i18n.language === 'vi' ? flag_vi : flag_en}
                  id='language'
                >
                  <NavDropdown.Item
                    onClick={() => changeLanguage('en')}
                    active={i18n.language === 'en'}
                  >
                    <Image
                      src='/assets/flag_en_40px.png'
                      width='15'
                      height='15'
                    />
                    {'  '}
                    {t('language.eng')}
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    onClick={() => changeLanguage('vi')}
                    active={i18n.language === 'vi'}
                  >
                    <Image
                      src='/assets/flag_vi_40px.png'
                      width='15'
                      height='15'
                    />
                    {'  '}
                    {t('language.vi')}
                  </NavDropdown.Item>
                </NavDropdown>
              </OverlayTrigger>

              {/* Change language */}

              {/* Change blockchain */}

              <OverlayTrigger
                placement='top'
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{t('Header.Blockchain')}</Tooltip>}
              >
                <NavDropdown
                  title={
                    network === 'Mainnet'
                      ? 'Ethereum'
                      : network === 'Polygon'
                      ? 'Polygon'
                      : network === 'BinanceSmartChainMainnet'
                      ? 'BSC'
                      : 'Goerli'
                  }
                  id='network'
                >
                  <NavDropdown.Item
                    onClick={() => dispatch(changeNetwork('Mainnet'))}
                    active={network === 'Mainnet'}
                  >
                    Ethereum
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    onClick={() => dispatch(changeNetwork('Polygon'))}
                    active={network === 'Polygon'}
                  >
                    Polygon
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    onClick={() =>
                      dispatch(changeNetwork('BinanceSmartChainMainnet'))
                    }
                    active={network === 'BinanceSmartChainMainnet'}
                  >
                    BSC
                  </NavDropdown.Item>

                  {userInfo && userInfo.isCreator && (
                    <>
                      <NavDropdown.Item
                        onClick={() => dispatch(changeNetwork('Goerli'))}
                        active={network === 'Goerli'}
                      >
                        Testnet Goerli
                      </NavDropdown.Item>
                    </>
                  )}
                </NavDropdown>
              </OverlayTrigger>

              {/* Change blockchain */}

              {/* <div
                className='connect-wallet-desktop'
                style={{
                  // fontWeight: 'lighter',
                  fontSize: '0.9rem',
                  height: '42px',
                  border: '3px solid transparent',
                  borderRadius: '0.5rem',
                  background:
                    'linear-gradient(90deg,#ECECEC,#ECECEC),linear-gradient(90deg,#418dff,#cc25b3)',
                  backgroundClip: 'padding-box,border-box',
                  backgroundOrigin: 'padding-box,border-box',
                }}
              >
                <ConnectWallet
                  className='connect-wallet-button'
                  displayName={t('ConnectMetamaskButton.Connect Metamask')}
                  accentColor='#ffffff'
                  colorMode='light'
                  ecosystem='evm'
                  // ml={{ base: 0, md: 2 }}
                  // colorScheme='#0000ff'
                />
              </div> */}

              <div className='mt-1 connect-wallet-desktop'>
                <ConnectWalletButtonV2 width={160} />
                {/* <ConnectMetamaskButton /> */}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
