import React, { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  ListGroup,
  Image,
  Button,
  // Card,
  FormControl,
} from 'react-bootstrap'
import Message from '../components/Message'
import Meta from '../components/Meta'
import { addToCart, removeFromCart } from '../actions/cartActions'
import { useTranslation, Trans } from 'react-i18next'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'

const CartScreen = ({ match, location, history, setShowCart }) => {
  const { t, i18n } = useTranslation()

  // const history = useHistory()

  const productId = match?.params.id

  const params = new URLSearchParams(location?.search)

  const variantId = params?.get('variant')

  const promo = params?.get('promo')

  const qty = params?.get('qty') ? Number(params?.get('qty')) : 1

  const dispatch = useDispatch()

  const { cartItems } = useSelector((state) => state.cart)

  useEffect(() => {
    if (productId) {
      dispatch(addToCart({ id: productId, qty, variantId, promo }))
    }
  }, [dispatch, productId, qty, variantId])

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    // map cartItems to check if a product is physical or digital
    const isPhysical = cartItems?.map((item) => item.isPhysical)

    // if there is a physical product, redirect to shipping
    if (isPhysical.includes(true)) {
      history.push('/login?redirect=shipping')
      if (setShowCart) {
        setShowCart(false)
      }
    } else {
      history.push('/payment')
      if (setShowCart) {
        setShowCart(false)
      }
    }
  }

  const continueHandler = () => {
    if (setShowCart) {
      setShowCart(false)
    } else {
      history.push('/')
    }
  }

  return (
    <Row>
      <Meta title={t('CartScreen.Shopping Cart') + ' - nQivi'} />
      <h1>{t('CartScreen.Shopping Cart')}</h1>
      <Col md={8} className='mb-1'>
        {cartItems.length === 0 ? (
          <Message>
            <Trans i18nKey='CartScreen.Your cart is empty'>
              Your cart is empty. <Link to='/'>Go Back</Link>
            </Trans>
          </Message>
        ) : (
          <ListGroup variant='flush'>
            {cartItems.map((item) => (
              <ListGroup.Item key={item.product}>
                <Row>
                  <Col md={2}>
                    <Image
                      src={item.image}
                      alt={item.name}
                      fluid
                      rounded
                    ></Image>
                  </Col>
                  <Col md={3}>
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                  </Col>

                  {/* PRICE */}
                  <Col md={2}>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(item.price, i18n.language)}
                  </Col>

                  <Col md={2}>
                    <FormControl
                      type='number'
                      value={item.qty}
                      min='1'
                      max={item.countInStock.toString()}
                      // onChange={(e) =>
                      //   dispatch(
                      //     addToCart(item.product, Number(e.target.value), variantId)
                      //   )
                      // }
                      onChange={(e) => {
                        if (e.target.value > item.countInStock) {
                          dispatch(
                            addToCart({
                              id: item.product,
                              qty: item.countInStock,
                              variantId,
                              atc: true,
                            })
                          )
                        } else if (e.target.value < 1) {
                          dispatch(
                            addToCart({
                              id: item.product,
                              qty: 1,
                              variantId,
                              atc: true,
                            })
                          )
                        } else {
                          dispatch(
                            addToCart({
                              id: item.product,
                              qty: Number(e.target.value),
                              variantId,
                              atc: true,
                            })
                          )
                        }
                      }}
                    ></FormControl>

                    {/* <Form.Select
                      value={item.qty}
                      style={{ color: '#212529' }}
                      // #e83283  #212529
                      onChange={(e) =>
                        dispatch(
                          addToCart(item.product, Number(e.target.value))
                        )
                      }
                    >
                      {[...Array(item.countInStock).keys()].map((x) => (
                        <option key={x + 1} value={x + 1}>
                          {x + 1}
                        </option>
                      ))}
                    </Form.Select> */}
                  </Col>

                  <Col md={2}>
                    <Button
                      type='button'
                      variant='light'
                      onClick={() => removeFromCartHandler(item.product)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col md={4}>
        <ListGroup variant='flush'>
          <ListGroup.Item>
            <h2>
              {t('CartScreen.Subtotal')} (
              {cartItems.reduce((acc, item) => acc + item.qty, 0)}){' '}
              {t('CartScreen.items')}
            </h2>
            {t('currency')}{' '}
            {currentCurrencyToLocale(
              cartItems.reduce((acc, item) => acc + item.qty * item.price, 0),
              // .toFixed(2),
              i18n.language
            )}
          </ListGroup.Item>
          <ListGroup.Item>
            <div>
              <Button
                type='button'
                className='btn-block'
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                {t('CartScreen.Proceed To Checkout')}
              </Button>
            </div>

            {/* </ListGroup.Item>
            <ListGroup.Item> */}
            <Button
              type='button'
              className='btn-block mt-2 btn-secondary'
              onClick={continueHandler}
            >
              {t('CartScreen.Continue Shopping')}
            </Button>
          </ListGroup.Item>
        </ListGroup>
      </Col>
    </Row>
  )
}

export default CartScreen
