import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description, keywords, shareTitle, image }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keyword' content={keywords} />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={shareTitle} />
      <meta property='og:image:width' content='1200' />
      <meta property='og:image:height' content='630' />
      <meta property='og:image' content={image} />
      <meta property='og:url' content={window.location.href} />
    </Helmet>
  )
}

Meta.defaultProps = {
  // title: 'nQivi - Get your own NFT, forever yours',
  title: 'nQivi - Digital Assets Platform',
  shareTitle: 'nQivi - Digital Assets Platform',
  description:
    'We deliver best art and creative digital collections. Get yours and use it your own way.',
  keywords:
    'NFT, nft, non-fungible token, crypto, cryptocurrency, digital assets',
  image:
    'https://res.cloudinary.com/nqivi/image/upload/v1656788872/NFT_Shop/images/sharing_social_iialql.jpg',
}

export default Meta
