import {
  BANNER_HIDE,
  BANNER_SHOW,
  BGCOLOR_HIDE,
  BGCOLOR_SHOW,
  GOODSOUNDS_HIDE,
  GOODSOUNDS_SHOW,
  HOME_BANNER_HIDE,
  HOME_BANNER_SHOW,
  SETTING_DOCUMENT_EDIT,
  SETTING_DOCUMENT_GET,
  SETTING_DOCUMENT_RESET,
} from '../constants/settingConstants'

export const settingReducer = (state = { goodSounds: false }, action) => {
  const { type, payload } = action

  switch (type) {
    case GOODSOUNDS_SHOW:
      return {
        ...state,
        goodSounds: true,
      }
    case GOODSOUNDS_HIDE:
      return {
        ...state,
        goodSounds: false,
      }
    case HOME_BANNER_SHOW:
      return {
        ...state,
        homeBanner: { show: true },
      }
    case HOME_BANNER_HIDE:
      return {
        ...state,
        homeBanner: { show: false },
      }
    case BANNER_SHOW:
      return {
        ...state,
        banner: payload,
      }
    case BANNER_HIDE:
      return {
        ...state,
        banner: { show: false },
      }
    case BGCOLOR_SHOW:
      return {
        ...state,
        bgColor: payload,
      }
    case BGCOLOR_HIDE:
      return { ...state, bgColor: { show: false } }
    case SETTING_DOCUMENT_GET:
      return {
        ...state,
        document: payload,
      }
    case SETTING_DOCUMENT_EDIT:
      return {
        ...state,
        document: payload,
      }
    case SETTING_DOCUMENT_RESET:
      return {
        ...state,
        document: null,
      }
    default:
      return state
  }
}
