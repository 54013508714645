import {
  STORE_CREATE_FAIL,
  STORE_CREATE_REQUEST,
  STORE_CREATE_RESET,
  STORE_CREATE_SUCCESS,
  STORE_DELETE_FAIL,
  STORE_DELETE_REQUEST,
  STORE_DELETE_SUCCESS,
  STORE_GET_ALL_FAIL,
  STORE_GET_ALL_REQUEST,
  STORE_GET_ALL_SUCCESS,
  STORE_GET_FAIL,
  STORE_GET_MY_FAIL,
  STORE_GET_MY_REQUEST,
  STORE_GET_MY_SUCCESS,
  STORE_GET_REQUEST,
  STORE_GET_SUCCESS,
  STORE_UPDATE_FAIL,
  STORE_UPDATE_REQUEST,
  STORE_UPDATE_SUCCESS,
  STORE_RESET,
  STORE_UPDATE_LIKE_FAIL,
  STORE_UPDATE_LIKE_SUCCESS,
} from '../constants/storeConstants'

export const storeReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case STORE_CREATE_REQUEST:
      return {
        ...state,
        loadingCreate: true,
      }
    case STORE_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        successCreate: true,
        store: payload,
      }
    case STORE_CREATE_FAIL:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: payload,
      }
    case STORE_CREATE_RESET:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: null,
        store: null,
      }

    case STORE_GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case STORE_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stores: payload.stores,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case STORE_GET_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case STORE_GET_MY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case STORE_GET_MY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        stores: payload.stores,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case STORE_GET_MY_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case STORE_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case STORE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        store: payload,
      }
    case STORE_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case STORE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case STORE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        store: payload,
      }
    case STORE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case STORE_DELETE_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      }
    case STORE_DELETE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      }
    case STORE_DELETE_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: payload,
      }
    case STORE_UPDATE_LIKE_SUCCESS:
      return {
        ...state,
        successLike: true,
      }
    case STORE_UPDATE_LIKE_FAIL:
      return {
        ...state,
        successLike: false,
        errorLike: payload,
      }
    case STORE_RESET:
      return {}
    default:
      return state
  }
}
