export const GOODSOUNDS_SHOW = 'GOODSOUNDS_SHOW'
export const GOODSOUNDS_HIDE = 'GOODSOUNDS_HIDE'

export const BGCOLOR_SHOW = 'BGCOLOR_SHOW'
export const BGCOLOR_HIDE = 'BGCOLOR_HIDE'

export const BANNER_SHOW = 'BANNER_SHOW'
export const BANNER_HIDE = 'BANNER_HIDE'

export const HOME_BANNER_SHOW = 'HOME_BANNER_SHOW'
export const HOME_BANNER_HIDE = 'HOME_BANNER_HIDE'

export const SETTING_DOCUMENT_GET = 'SETTING_DOCUMENT_GET'
export const SETTING_DOCUMENT_EDIT = 'SETTING_DOCUMENT_EDIT'
export const SETTING_DOCUMENT_RESET = 'SETTING_DOCUMENT_RESET'
