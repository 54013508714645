import React, { useEffect } from 'react'
import axios from 'axios'
import {
  useAddress,
  useNetworkMismatch,
  useNetwork,
  useContract,
} from '@thirdweb-dev/react'
import { ChainId } from '@thirdweb-dev/sdk'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next'
import { ConnectMetamaskButton } from '../components/ConnectMetamaskButton'
import { setAlert } from '../actions/alertActions'
import { Button, Image, ListGroup } from 'react-bootstrap'
import { updateAddress } from '../actions/contractActions'
import {
  MINT_REQUEST,
  MINT_FAIL,
  MINT_SUCCESS,
} from '../constants/mintConstants'

import {
  getOrderDetails,
  deliverOrder,
  updateOrder,
} from '../actions/orderActions'
import { changeNetwork } from '../actions/blockchainActions'

const MintScreen = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading: loadingOrder, error: errorOrder } = orderDetails

  const mint = useSelector((state) => state.mint)
  const { minting, contractAddress, blockchain, item } = mint

  // Hooks to detect user is on the right network and switch them if they are not
  const { network } = useSelector((state) => state.blockchain)
  const networkMismatch = useNetworkMismatch()
  const [, switchNetwork] = useNetwork()

  const address = useAddress()
  // console.log('address', address)

  // const { contract } = useContract(contractAddress)
  const { contract: nftDrop } = useContract(contractAddress, 'nft-drop')

  // console.log('contractAddress: ', contractAddress)
  // console.log('contract: ', contract)
  // console.log('nftCollection: ', nftCollection)
  // console.log('nftDrop: ', nftDrop)

  useEffect(() => {
    if (errorOrder && errorOrder !== '') {
      dispatch(setAlert(errorOrder, 'danger'))
    }
  }, [errorOrder])

  useEffect(() => {
    if (contractAddress && contractAddress.trim() === '') {
      dispatch(setAlert(t('OrderScreen.No contract'), 'danger'))
    }
  }, [contractAddress])

  const mintHandler = async () => {
    try {
      if (item.qty <= item.delivered) {
        dispatch(setAlert(t('OrderScreen.Mint Over'), 'danger'))
        return
      }

      if (network !== blockchain) {
        dispatch(changeNetwork(blockchain))
        dispatch(
          setAlert(t('OrderScreen.Network Changed') + blockchain, 'success')
        )
        return
      }

      if (address) {
        // Ensure user is on the correct network
        if (networkMismatch) {
          switchNetwork && switchNetwork(ChainId[network])
          return
        }

        dispatch({ type: MINT_REQUEST })

        let receipt

        if (!item.isDigital) {
          const tx = await nftDrop.claim(1)
          // const tx = await contract?.nft?.drop?.claim?.to(address, 1)

          receipt = tx[0].receipt // the transaction receipt
          // const claimedTokenId = tx[0].id // the id of the NFT claimed
          // const claimedNFT = await tx[0].data() // (optional) get the claimed NFT metadata
        } else {
          receipt = null
          // const { data } = await axios.get(
          //   `/api/nft/download/${order._id}/${item.product}`
          // )

          // // get number of tokens minted then add 1
          // const metadata = {
          //   name: item.name,
          //   description: '',
          //   image: data[0].secure_url,
          // }
          // // console.log('metadata: ', metadata)

          // const tx = await nftCollection.mintToSelf(metadata)
          // // const tx = await contract?.nft?.mint?.to(address, metadata)

          // receipt = tx.receipt // the transaction receipt
          // // const claimedTokenId = tx.id // the id of the NFT claimed
          // // const claimedNFT = await tx.data() // (optional) get the claimed NFT metadata
        }

        // console.log('receipt: ', receipt)
        // console.log(claimedTokenId)
        // console.log(claimedNFT)

        if (receipt) {
          item.delivered++
          dispatch(updateOrder(order))

          const totalQty = order.orderItems.reduce(
            (acc, item) => acc + item.qty,
            0
          )
          const totalDelivered = order.orderItems.reduce(
            (acc, item) => acc + item.delivered,
            0
          )

          if (totalDelivered >= totalQty) {
            dispatch(deliverOrder(order._id))
          } else {
            dispatch(getOrderDetails(order._id))
          }

          dispatch({ type: MINT_SUCCESS })
          dispatch(setAlert(t('OrderScreen.Mint Success'), 'success'))

          updateAddress(address, network)
        } else {
          throw new Error(t('OrderScreen.Mint Fail'))
        }
      } else {
        dispatch(setAlert(t('OrderScreen.Please connect'), 'danger'))
      }
    } catch (error) {
      dispatch({ type: MINT_FAIL, payload: error.message })
      dispatch(setAlert(t('OrderScreen.Mint Fail'), 'danger'))
    }
  }

  return loadingOrder ? (
    <Loader />
  ) : (
    <>
      <ListGroup
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ConnectMetamaskButton />

        <h2 className='mx-4 mt-1'>{t('OrderScreen.Slogan')}</h2>
        {item.isDigital ? (
          <Image src={item.image} height='300' />
        ) : (
          <video
            src={item?.mediaUrl}
            width='300'
            height='300'
            autoPlay
            loop
            muted
          />
        )}
        <ListGroup.Item>
          <Button
            type='button'
            className='btn-block btn-success my-1 rounded-pill'
            style={{ width: '160px' }}
            disabled={contractAddress && contractAddress.trim() === ''}
            onClick={mintHandler}
          >
            {minting ? <Loader size='sm' /> : t('OrderScreen.Mint')}
          </Button>
        </ListGroup.Item>
      </ListGroup>
    </>
  )
}

export default MintScreen
