// Email confirmation: https://morioh.com/p/7747be224b10
/* eslint-disable import/first */
import { lazy } from 'react'
import { useSelector } from 'react-redux'
import { ChainId, ThirdwebProvider } from '@thirdweb-dev/react'
import {
  BrowserRouter as Router,
  Switch as Routes,
  Route,
} from 'react-router-dom'
import { Container } from 'react-bootstrap'
import './App.scss'
// import './Materia.min.css'
import { BsArrowUpCircle } from 'react-icons/bs'
// import MouseParticles from 'react-mouse-particles'
import ScrollToTop from 'react-scroll-to-top'
import Announcement from './components/Announcement'
import TopBar from './components/TopBar'
import Header from './components/Header'
import Footer from './components/Footer'
import BannerFeeling from './components/BannerFeeling'
import CartScreen from './screens/CartScreen'

import ProductScreen from './screens/ProductScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'

import TrackingOrderScreen from './screens/TrackingOrderScreen'
import LoginScreen from './screens/LoginScreen'
import LoginOAuthScreen from './screens/LoginOAuthScreen'
import RegisterScreen from './screens/RegisterScreen'
import VerifyScreen from './screens/VerifyScreen'
import ResendOTPScreen from './screens/ResendOTPScreen'
import ProfileScreen from './screens/ProfileScreen'
import ProgramScreen from './screens/ProgramScreen'
// import HomeScreen from './screens/HomeScreen'

// LAZY LOAD - CODE SPLITTING
const NewsScreen = lazy(() => import('./screens/NewsScreen'))
const CustomizerScreen = lazy(() => import('./screens/CustomizerScreen'))
const ArticleScreen = lazy(() => import('./screens/ArticleScreen'))
const ArticleEditScreen = lazy(() => import('./screens/ArticleEditScreen'))
const NotFoundScreen = lazy(() => import('./screens/NotFoundScreen'))
const HomeScreen = lazy(() => import('./screens/HomeScreen'))
// const ShippingScreen = lazy(() => import('./screens/ShippingScreen'))
// const ProductScreen = lazy(() => import('./screens/ProductScreen'))
// const PaymentScreen = lazy(() => import('./screens/PaymentScreen'))
// const PlaceOrderScreen = lazy(() => import('./screens/PlaceOrderScreen'))
// const OrderScreen = lazy(() => import('./screens/OrderScreen'))
// const TrackingOrderScreen = lazy(() => import('./screens/TrackingOrderScreen'))
// const LoginScreen = lazy(() => import('./screens/LoginScreen'))
// const LoginOAuthScreen = lazy(() => import('./screens/LoginOAuthScreen'))
// const RegisterScreen = lazy(() => import('./screens/RegisterScreen'))
// const VerifyScreen = lazy(() => import('./screens/VerifyScreen'))
// const ResendOTPScreen = lazy(() => import('./screens/ResendOTPScreen'))
// const ProfileScreen = lazy(() => import('./screens/ProfileScreen'))
const UserListScreen = lazy(() => import('./screens/UserListScreen'))
const UserListFinanceScreen = lazy(() =>
  import('./screens/UserListFinanceScreen')
)
const UserEditScreen = lazy(() => import('./screens/UserEditScreen'))
const ProductListScreen = lazy(() => import('./screens/ProductListScreen'))
const ProductEditScreen = lazy(() => import('./screens/ProductEditScreen'))
const ProductEditBulkScreen = lazy(() =>
  import('./screens/ProductEditBulkScreen')
)
const OrderListScreen = lazy(() => import('./screens/OrderListScreen'))
const RequestListScreen = lazy(() => import('./screens/RequestListScreen'))
const RequestScreen = lazy(() => import('./screens/RequestScreen'))
const PaymentResultScreen = lazy(() => import('./screens/PaymentResultScreen'))
const PaymentFailScreen = lazy(() => import('./screens/PaymentFailScreen'))
const RefScreen = lazy(() => import('./screens/RefScreen'))
const ReportScreen = lazy(() => import('./screens/ReportScreen'))
const PromoListScreen = lazy(() => import('./screens/PromoListScreen'))
const PromoEditScreen = lazy(() => import('./screens/PromoEditScreen'))
const UnderConstructionScreen = lazy(() =>
  import('./screens/UnderConstructionScreen')
)
const ForgotPasswordScreen = lazy(() =>
  import('./screens/ForgotPasswordScreen')
)
const ResetPasswordScreen = lazy(() => import('./screens/ResetPasswordScreen'))
const CommandScreen = lazy(() => import('./screens/CommandScreen'))
const WishListScreen = lazy(() => import('./screens/WishListScreen'))
const ChillScreen = lazy(() => import('./screens/ChillScreen'))
const TopupScreen = lazy(() => import('./screens/TopupScreen'))
const TopupResultScreen = lazy(() => import('./screens/TopupResultScreen'))
const TopupFailScreen = lazy(() => import('./screens/TopupFailScreen'))
const GoAnywhereScreen = lazy(() => import('./screens/GoAnywhereScreen'))
const WCScreen = lazy(() => import('./screens/WCScreen'))
const ExchangeScreen = lazy(() => import('./screens/ExchangeScreen'))
const BulkOrderScreen = lazy(() => import('./screens/BulkOrderScreen'))
const MagicQRScreen = lazy(() => import('./screens/MagicQRScreen'))
const TestScreen = lazy(() => import('./screens/TestScreen'))

const GoodSounds = lazy(() => import('./components/GoodSounds'))
const Banner = lazy(() => import('./components/Banner'))

// Policy
const AboutScreen = lazy(() => import('./screens/AboutScreen'))
const ContactScreen = lazy(() => import('./screens/ContactScreen'))
const FAQScreen = lazy(() => import('./screens/FAQScreen'))
// const ProgramScreen = lazy(() => import('./screens/ProgramScreen'))
const PrivacyPolicyScreen = lazy(() => import('./screens/PrivacyPolicyScreen'))
const TermsOfServiceScreen = lazy(() =>
  import('./screens/TermsOfServiceScreen')
)
const DeliveryAndReturnScreen = lazy(() =>
  import('./screens/DeliveryAndReturnScreen')
)
const HowToBuyScreen = lazy(() => import('./screens/HowToBuyScreen'))

// Minigame
const FloppyBanner = lazy(() => import('./components/FloppyBanner'))

// 3D
const VendingMachineScreen = lazy(() =>
  import('./screens/VendingMachineScreen')
)
const GalleryScreen = lazy(() => import('./screens/GalleryScreen'))
const ShoeCustomizerScreen = lazy(() =>
  import('./components/3D/ShoeCustomizerScreen')
)

// POD
const PrintNFTScreen = lazy(() => import('./screens/PrintNFTScreen'))
const DesignScreen = lazy(() => import('./screens/DesignScreen'))
const CreatePODScreen = lazy(() => import('./screens/CreatePODScreen'))

// NFT
const MarketScreen = lazy(() => import('./screens/MarketScreen'))
const MyNFTScreen = lazy(() => import('./screens/MyNFTScreen'))
const CreateCollectionScreen = lazy(() =>
  import('./screens/CreateCollectionScreen')
)
const CollectionScreen = lazy(() => import('./screens/CollectionScreen'))
const CollectionListScreen = lazy(() =>
  import('./screens/CollectionListScreen')
)
const CollectionEditScreen = lazy(() =>
  import('./screens/CollectionEditScreen')
)
const ListingScreen = lazy(() => import('./screens/ListingScreen'))
const StoreListScreen = lazy(() => import('./screens/StoreListScreen'))
const StoreScreen = lazy(() => import('./screens/StoreScreen'))
const StoreEditScreen = lazy(() => import('./screens/StoreEditScreen'))
const NFTScreen = lazy(() => import('./screens/NFTScreen'))

// Art
const UploadNFTScreen = lazy(() => import('./screens/UploadNFTScreen'))
const SketchScreen1 = lazy(() => import('./screens/SketchScreen1'))
const SketchScreen2 = lazy(() => import('./screens/SketchScreen2'))
const SketchScreen3 = lazy(() => import('./screens/SketchScreen3'))
const SketchScreen4 = lazy(() => import('./screens/SketchScreen4'))
const SketchScreen5 = lazy(() => import('./screens/SketchScreen5'))
const SaveMemoryScreen = lazy(() => import('./screens/SaveMemoryScreen'))
const MakeArtScreen = lazy(() => import('./screens/MakeArtScreen'))
const DrawTogetherScreen = lazy(() => import('./screens/DrawTogetherScreen'))
const HarmonyScreen = lazy(() => import('./screens/HarmonyScreen'))

import { useEffect } from 'react'
window.Buffer = window.Buffer || require('buffer').Buffer

const loadTheme = async () => {
  await import(`./${process.env.REACT_APP_THEME}.css`)
}

function App() {
  const { network } = useSelector((state) => state.blockchain)

  const mint = useSelector((state) => state.mint)
  const { isGasless } = mint

  // // Load bootstrap theme & userInfo
  // useEffect(() => {
  //   loadTheme()
  // }, [])

  // const chainId =
  //   network === 'Polygon'
  //     ? ChainId.Polygon
  //     : network === 'Mainnet'
  //     ? ChainId.Mainnet
  //     : network === 'Goerli'
  //     ? ChainId.Goerli
  //     : network === 'BinanceSmartChainMainnet' && ChainId.BinanceSmartChainMainnet

  const chainId = ChainId[network]

  // console.log('chainId: ', chainId)
  // console.log('test chainId: ', ChainId['Goerli'])

  const { goodSounds, bgColor, banner, homeBanner } = useSelector(
    (state) => state.setting
  )

  return (
    <ThirdwebProvider
      sdkOptions={
        isGasless
          ? {
              gasless: {
                openzeppelin: {
                  relayerUrl: process.env.REACT_APP_OPENZEPPELIN_URL,
                },
              },
            }
          : null
      }
      desiredChainId={chainId}
    >
      <Router>
        {/* <MouseParticles g={1} color='random' cull='col,image-wrapper' /> */}
        <TopBar />
        {/* <Announcement /> */}
        <Header />

        {homeBanner?.show && <BannerFeeling />}
        {banner && banner?.show && banner?.src && <Banner src={banner?.src} />}

        <main
          id='main'
          className='pt-3'
          style={{
            minHeight: '70vh',
            backgroundColor: bgColor && bgColor?.show ? bgColor?.color : '', // #573A7F - good alternative for background color
            // background: 'linear-gradient(to bottom, #5d326c, #350048)', // fiolet
          }}
        >
          <Container style={{ minHeight: '50vh' }}>
            <GoodSounds show={goodSounds} />
            {/* <Alert /> */}
            <Routes>
              <Route path='/shipping' component={ShippingScreen} exact />
              <Route path='/payment' component={PaymentScreen} exact />
              <Route
                path='/payment/thankyou'
                component={PaymentResultScreen}
                exact
              />
              <Route path='/payment/fail' component={PaymentFailScreen} exact />
              <Route
                path='/topup/thankyou'
                component={TopupResultScreen}
                exact
              />
              <Route path='/topup/fail' component={TopupFailScreen} exact />
              <Route path='/placeorder' component={PlaceOrderScreen} exact />
              <Route path='/order/:id' component={OrderScreen} exact />
              <Route path='/login' component={LoginScreen} exact />
              <Route path='/OAuth/success' component={LoginOAuthScreen} exact />
              <Route path='/register' component={RegisterScreen} exact />
              <Route path='/verify' component={VerifyScreen} exact />
              <Route path='/admin/run' component={CommandScreen} exact />
              <Route
                path='/admin/bulk-orders'
                component={BulkOrderScreen}
                exact
              />
              <Route path='/resendotp' component={ResendOTPScreen} exact />
              <Route path='/test' component={TestScreen} exact />
              <Route
                path='/forgot-password'
                component={ForgotPasswordScreen}
                exact
              />
              <Route
                path='/reset-password/:id'
                component={ResetPasswordScreen}
                exact
              />
              <Route
                path='/privacy-policy'
                component={PrivacyPolicyScreen}
                exact
              />
              <Route
                path='/terms-of-service'
                component={TermsOfServiceScreen}
                exact
              />
              <Route
                path='/delivery-return'
                component={DeliveryAndReturnScreen}
                exact
              />
              <Route path='/how-to-buy' component={HowToBuyScreen} exact />
              <Route path='/about' component={AboutScreen} exact />

              <Route path='/qr' component={MagicQRScreen} exact />

              <Route path='/print' component={PrintNFTScreen} exact />
              <Route path='/design' component={DesignScreen} exact />
              <Route path='/shoe' component={ShoeCustomizerScreen} exact />

              <Route path='/floppy' component={FloppyBanner} exact />
              <Route path='/chill' component={ChillScreen} exact />
              <Route path='/wc' component={WCScreen} exact />
              <Route path='/go' component={GoAnywhereScreen} exact />
              <Route path='/gallery' component={GalleryScreen} exact />
              <Route path='/profile' component={ProfileScreen} exact />
              <Route
                path='/profile/page/:pageNumber'
                component={ProfileScreen}
                exact
              />
              <Route path='/topup/:id?' component={TopupScreen} exact />
              <Route path='/exchange' component={ExchangeScreen} exact />
              <Route path='/market' component={MarketScreen} exact />
              <Route
                path='/market/page/:pageNumber'
                component={MarketScreen}
                exact
              />
              <Route path='/makeart' component={MakeArtScreen} exact />

              <Route
                path='/art/collection/:id?'
                component={CreateCollectionScreen}
                exact
              />
              <Route
                path='/collection'
                component={CollectionListScreen}
                exact
              />
              <Route
                path='/collection/page/:pageNumber'
                component={CollectionListScreen}
                exact
              />
              <Route
                path='/market/collection/:id/edit'
                component={CollectionEditScreen}
                exact
              />
              <Route
                path='/market/collection/:id'
                component={CollectionScreen}
                exact
              />
              <Route
                path='/market/listing/:id'
                component={ListingScreen}
                exact
              />
              <Route path='/nft' component={MyNFTScreen} exact />

              <Route
                path='/nft/:contractId/:tokenId'
                component={NFTScreen}
                exact
              />
              <Route path='/store' component={StoreListScreen} exact />
              <Route path='/store/:id' component={StoreScreen} exact />
              <Route path='/store/:id/edit' component={StoreEditScreen} exact />
              <Route
                path='/store/page/:pageNumber'
                component={StoreListScreen}
                exact
              />

              <Route
                path='/art/savememory'
                component={SaveMemoryScreen}
                exact
              />
              <Route
                path='/customizer/:id?'
                component={CustomizerScreen}
                exact
              />
              <Route
                path='/vending-machine/:id?'
                component={VendingMachineScreen}
                exact
              />
              <Route
                path='/art/nft-together/:id?'
                component={DrawTogetherScreen}
                exact
              />
              <Route path='/art/draw/:id?' component={HarmonyScreen} exact />

              <Route path='/art/upload' component={UploadNFTScreen} exact />
              <Route path='/art/flow' component={SketchScreen1} exact />
              <Route path='/art/sketch' component={SketchScreen2} exact />
              <Route path='/art/fabric' component={SketchScreen3} exact />
              <Route path='/art/foggy' component={SketchScreen4} exact />
              <Route path='/art/rings' component={SketchScreen5} exact />
              <Route
                path='/pod/:orderId/:productId'
                component={CreatePODScreen}
                exact
              />
              <Route
                path='/comingsoon'
                component={UnderConstructionScreen}
                exact
              />
              <Route path='/profile/ref' component={RefScreen} exact />
              <Route
                path='/profile/ref/page/:pageNumber'
                component={RefScreen}
                exact
              />
              <Route path='/wishlist' component={WishListScreen} exact />
              <Route
                path='/wishlist/page/:pageNumber'
                component={WishListScreen}
                exact
              />
              <Route path='/tracking' component={TrackingOrderScreen} exact />
              <Route
                path='/tracking/page/:pageNumber'
                component={TrackingOrderScreen}
                exact
              />
              {/* <Route path='/checkout?' component={PaymentResultScreen} exact /> */}
              <Route path='/news' component={NewsScreen} exact />
              <Route
                path='/article/:id/edit'
                component={ArticleEditScreen}
                exact
              />
              <Route path='/article/:id' component={ArticleScreen} exact />
              <Route path='/product/:id' component={ProductScreen} exact />

              <Route path='/cart/:id?' component={CartScreen} exact />
              <Route path='/admin/report' component={ReportScreen} exact />
              <Route
                exact
                path='/admin/report/page/:pageNumber'
                component={ReportScreen}
              />
              <Route
                path='/admin/promolist'
                component={PromoListScreen}
                exact
              />
              <Route
                path='/admin/promo/:id/edit'
                component={PromoEditScreen}
                exact
              />
              <Route path='/admin/userlist' component={UserListScreen} exact />
              <Route
                path='/finance/userlist'
                component={UserListFinanceScreen}
                exact
              />
              <Route
                path='/admin/userlist/page/:pageNumber'
                component={UserListScreen}
                exact
              />
              <Route
                path='/finance/userlist/page/:pageNumber'
                component={UserListFinanceScreen}
                exact
              />
              <Route
                path='/admin/user/:id/edit'
                component={UserEditScreen}
                exact
              />
              <Route
                exact
                path='/admin/orderlist/page/:pageNumber'
                component={OrderListScreen}
              />
              <Route
                exact
                path='/admin/orderlist'
                component={OrderListScreen}
              />
              <Route
                exact
                path='/admin/requestlist/page/:pageNumber'
                component={RequestListScreen}
              />
              <Route
                exact
                path='/admin/requestlist'
                component={RequestListScreen}
              />
              <Route
                exact
                path='/admin/request/:id'
                component={RequestScreen}
              />
              <Route
                exact
                path='/admin/edit-products'
                component={ProductEditBulkScreen}
              />
              <Route
                exact
                path='/admin/edit-products/page/:pageNumber'
                component={ProductEditBulkScreen}
              />
              <Route
                exact
                path='/admin/product/:id/edit'
                component={ProductEditScreen}
              />
              <Route exact path='/search/:keyword' component={HomeScreen} />
              <Route
                exact
                path='/search/:keyword/page/:pageNumber'
                component={HomeScreen}
              />
              <Route
                path='/admin/productlist'
                component={ProductListScreen}
                exact
              />
              <Route
                path='/admin/productlist/:pageNumber'
                component={ProductListScreen}
                exact
              />
              <Route
                path='/admin/productlist/search/:keyword'
                component={ProductListScreen}
                exact
              />
              <Route
                path='/admin/productlist//search/:keyword/:pageNumber'
                component={ProductListScreen}
                exact
              />
              <Route exact path='/page/:pageNumber' component={HomeScreen} />
              <Route exact path='/' component={HomeScreen} />
              <Route exact path='/contact' component={ContactScreen} />
              <Route exact path='/faq' component={FAQScreen} />
              <Route exact path='/program' component={ProgramScreen} />
              {/* <Route exact path='/api/auth/google' redirect /> */}

              <Route component={NotFoundScreen} />
            </Routes>
          </Container>

          <ScrollToTop
            smooth
            top={window.innerHeight}
            // color='#33B6E1'
            component={<BsArrowUpCircle />}
            style={{
              bottom: '0px',
              right: '10px',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              // display: 'flex',
              // justifyContent: 'center',
              // alignItems: 'center',
              width: '80px',
              height: '80px',
              fontSize: '2em',
              color: '#e9e9e8',
            }}
          />
          <Footer />
        </main>
      </Router>
    </ThirdwebProvider>
  )
  // : (
  //   <UnderConstructionScreen />
  // )
}

export default App
