import React from 'react'

function SocialFollowFooter({
  website,
  facebook,
  twitter,
  instagram,
  youtube,
  linkedin,
  tiktok,
}) {
  return (
    <div className='social-container'>
      {website && (
        <a
          href={website}
          className='website social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fa-solid fa-globe fa-2x'></i>
        </a>
      )}

      {youtube && (
        <p
          href={youtube}
          className='youtube social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-youtube fa-2x'></i>
        </p>
      )}

      {facebook && (
        <p
          href={facebook}
          className='facebook social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-facebook fa-2x'></i>
        </p>
      )}

      {twitter && (
        <p
          href={twitter}
          className='twitter social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-twitter fa-2x'></i>
        </p>
      )}

      {instagram && (
        <p
          href={instagram}
          className='instagram social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-instagram fa-2x'></i>
        </p>
      )}

      {linkedin && (
        <a
          href={linkedin}
          className='linkedin social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-linkedin fa-2x'></i>
        </a>
      )}

      {tiktok && (
        <a
          href={tiktok}
          className='tiktok social'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fab fa-tiktok fa-2x'></i>
        </a>
      )}
    </div>
  )
}

SocialFollowFooter.defaultProps = {
  facebook: ' ',
  twitter: ' ',
  instagram: ' ',
  youtube: ' ',
}

export default SocialFollowFooter
