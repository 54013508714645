import React from 'react'
import PropTypes from 'prop-types'

const View = ({ value, text, showNo }) => {
  return (
    <>
      {value > showNo && (
        <>
          <span>
            <i className='fas fa-eye'></i>
          </span>

          <span>
            {'      '}
            {value} {text && text}
          </span>
        </>
      )}
    </>
  )
}

View.defaultProps = {
  text: 'views',
  showNo: 50,
}

View.propTypes = {
  value: PropTypes.number,
  showNo: PropTypes.number,
  text: PropTypes.string,
}

export default View
