import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import { addToCart } from '../actions/cartActions'
import { useTranslation } from 'react-i18next'
import { setAlert } from '../actions/alertActions'

const AddToCart = ({ product, qty, setShowCart, addedClass, openCart }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const addToCartHandler = () => {
    if (product?._id) {
      dispatch(addToCart({ id: product._id, qty: qty ? qty : 1 }))
    }

    if (openCart && setShowCart) {
      setShowCart(true)
    } else {
      dispatch(setAlert(t('ProductScreen.Added to cart'), 'success', 3000))
    }
  }

  return (
    <Button
      onClick={addToCartHandler}
      className={`btn-block ${addedClass ? addedClass : ''}`}
      type='button'
      size='lg'
      disabled={product?.countInStock <= 0}
    >
      <strong>{t('ProductScreen.Add To Cart')}</strong>
    </Button>
  )
}

export default AddToCart
