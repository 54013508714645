import { useState } from 'react'
// import { Route } from 'react-router-dom'
import { Link, useHistory } from 'react-router-dom'
import { Card, Badge, Modal, Row, Col } from 'react-bootstrap'
import Rating from './Rating'
import WishList from './WishList'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import { useTranslation } from 'react-i18next'
import CartScreen from '../screens/CartScreen'
import AddToCart from './AddToCart'
import arrayToString from '../utils/arrayToString'
import LazyLoad from 'react-lazyload'

const Product = ({
  product,
  userInfo,
  minimal,
  addToCart,
  notShowRefRate,
  showPrice,
}) => {
  const { t, i18n } = useTranslation()

  const history = useHistory()

  const [showCart, setShowCart] = useState(false)

  return (
    <>
      {showCart && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
          }}
        >
          <Modal
            show={showCart}
            fullscreen
            size='lg'
            onHide={() => setShowCart(false)}
          >
            <Modal.Header closeButton />
            <Modal.Body>
              {/* <Route
                render={({ history }) => (
                  <CartScreen history={history} setShowCart={setShowCart} />
                  )}
              /> */}
              <CartScreen history={history} setShowCart={setShowCart} />
            </Modal.Body>
          </Modal>
        </div>
      )}

      <Card
        className='mt-1 mb-4 p-0 rounded product-card'
        style={{ minHeight: '350px', position: 'relative', overflow: 'hidden' }}
      >
        {/* <div style={{ zIndex: 2 }}> */}
        <Link
          to={`/${product?.productLink ? product?.productLink : 'product'}/${
            product._id
          }`}
        >
          <LazyLoad height={300} once>
            <Card.Img
              src={product?.thumbnail ? product?.thumbnail : product?.images[0]}
              alt={
                arrayToString(product?.tags)?.length > 0
                  ? arrayToString(product?.tags)
                  : product.name
              }
              variant='top'
              style={{ width: '100%', aspectRatio: '1/1', objectFit: 'cover' }}
            />
          </LazyLoad>
        </Link>

        <div
          style={{
            // width: '50px',
            position: 'absolute',
            top: '10px',
            left: '10px',
            right: '50px',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '5px',
          }}
        >
          {product.isAuction && product?.auction?.isActive && (
            <Badge bg='danger'>{t('ProductEditScreen.AUCTION')}</Badge>
          )}

          {product?.promotion?.promoType === 'flashsale' &&
          !product.isAuction &&
          product?.promotion?.isActive &&
          (!product?.promotion?.endTime ||
            new Date(product?.promotion?.endTime) > Date.now()) ? (
            <Badge bg='danger'>FLASHSALE</Badge>
          ) : (
            ((product.noDiscountPrice &&
              product.noDiscountPrice > product.price) ||
              product?.promotion?.promoPrice < product.price) && (
              <Badge bg='primary'>SALE</Badge>
            )
          )}

          {product?.type === 'groupart' && (
            <Badge bg='success'>{t('ProductEditScreen.GROUP ART')}</Badge>
          )}

          {product?.promotion?.promoType === 'groupbuy' && (
            <Badge bg='success'>{t('ProductEditScreen.GROUP BUY')}</Badge>
          )}

          {product?.type === 'gallery' && (
            <Badge bg='success'>{t('ProductEditScreen.GALLERY')}</Badge>
          )}

          {product?.type === 'voting' &&
            product.isVoting &&
            (!product?.voting?.votingEndTime ||
              new Date(product?.voting?.votingEndTime) > Date.now()) && (
              <Badge bg='danger'>{t('ProductEditScreen.VOTING')}</Badge>
            )}

          {product?.type === 'launching' && (
            <Badge bg='danger'>{t('ProductEditScreen.LAUNCHING')}</Badge>
          )}
        </div>

        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
        >
          <WishList id={product._id} product={product} userInfo={userInfo} />
        </div>

        <Card.Body style={{ fontWeight: 'lighter' }}>
          <Row>
            <Col xs={9}>
              <Link
                to={`/${
                  product?.productLink ? product?.productLink : 'product'
                }/${product._id}`}
              >
                <Card.Title as='p' style={{ width: '80%', fontSize: '1.2rem' }}>
                  <strong>{product.name}</strong>
                </Card.Title>
              </Link>
            </Col>
            <Col xs={3}>
              <Link
                to={`/${
                  product?.productLink ? product?.productLink : 'product'
                }/${product._id}`}
                className='btn btn-secondary btn-sm py-1 px-2'
                // style={{ width: '80px', height: '50px' }}
              >
                {t('HomeScreen.Banner CTA')}
              </Link>
            </Col>
          </Row>

          {/* <Link
            to={`/${product?.productLink ? product?.productLink : 'product'}/${
              product._id
            }`}
          >
            <Card.Title as='p' style={{ width: '80%', fontSize: '1.2rem' }}>
              <strong>{product.name}</strong>
            </Card.Title>
          </Link> */}

          {!minimal && (
            <>
              {/* ARTIST */}
              {product.brand && product.brand.trim().length > 0 && (
                <Card.Text as='div'>
                  {t('ProductScreen.Brand') + ': ' + product.brand}
                </Card.Text>
              )}

              {/* COLLECTION */}
              {product.album && product.album.trim().length > 0 && (
                <Card.Text as='div'>
                  {t('ProductEditScreen.Collection') + ': ' + product.album}
                </Card.Text>
              )}
            </>
          )}

          {product.numReviews > 0 && (
            <Card.Text as='div'>
              <Rating
                value={product.rating}
                text={` ${product.numReviews} ${t('ProductScreen.reviews')}`}
              />
            </Card.Text>
          )}

          {/* PRICE */}
          {showPrice && (
            <Card.Text as='p' style={{ fontWeight: 'normal' }}>
              {product?.promotion?.promoPrice < product.price &&
              product?.promotion?.isActive &&
              product?.promotion?.promoPrice >= 0 ? (
                <>
                  <span style={{ textDecoration: 'line-through' }}>
                    {currentCurrencyToLocale(product.price, i18n.language)}
                  </span>
                  <br />
                  <strong>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(
                      product?.promotion?.promoPrice,
                      i18n.language
                    )}
                  </strong>
                </>
              ) : product?.auction?.isActive &&
                product?.auction?.bids &&
                product?.auction?.bids.length > 0 ? (
                <strong>
                  {t('currency')}{' '}
                  {currentCurrencyToLocale(
                    product?.auction?.bids[0].bidPrice,
                    i18n.language
                  )}
                </strong>
              ) : (
                <>
                  {product?.noDiscountPrice > 0 &&
                    product?.noDiscountPrice > product.price && (
                      <>
                        <span style={{ textDecoration: 'line-through' }}>
                          {currentCurrencyToLocale(
                            product.noDiscountPrice,
                            i18n.language
                          )}
                        </span>
                        <br />
                      </>
                    )}
                  <strong>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(product.price, i18n.language)}
                  </strong>
                </>
              )}
            </Card.Text>
          )}

          {!notShowRefRate &&
            userInfo &&
            userInfo?.ref?.isEnabled &&
            product?.commissionCreator > 0 && (
              <Card.Text as='div' style={{ color: 'yellow' }}>
                {t('ProductScreen.You earn') + ' - '}
                {(
                  userInfo?.ref?.commissionRate *
                  (1 - product?.commissionCreator) *
                  100
                ).toFixed(0)}
                %
              </Card.Text>
            )}

          {/* BUY BUTTON */}
          {/* {!addToCart && (
            <div
              style={{
                position: 'absolute',
                bottom: '20px',
                right: '15px',
              }}
            >
              <Link
                to={`/${
                  product?.productLink ? product?.productLink : 'product'
                }/${product._id}`}
                className='btn btn-secondary btn-sm mt-1 py-1 px-2'
                // style={{ width: '80px', height: '50px' }}
              >
                {t('HomeScreen.Banner CTA')}
              </Link>
            </div>
          )} */}

          {addToCart && (
            <AddToCart
              product={product}
              setShowCart={setShowCart}
              addedClass='btn-sm'
            />
          )}
        </Card.Body>
        {/* </div>
        <span className='product-span'></span> */}
      </Card>
    </>
  )
}

export default Product
