import {
  NFT_LOAD_FAIL,
  NFT_LOAD_REQUEST,
  NFT_LOAD_RESET,
  NFT_LOAD_SUCCESS,
} from '../constants/marketConstants'

export const marketReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case NFT_LOAD_REQUEST:
      return {
        loading: true,
      }
    case NFT_LOAD_SUCCESS:
      return {
        loading: false,
        success: true,
        contractAddress: payload.contractAddress,
        token_id: payload.token_id,
      }
    case NFT_LOAD_FAIL:
      return {
        loading: false,
        error: payload,
      }
    case NFT_LOAD_RESET:
      return {}
    default:
      return state
  }
}
