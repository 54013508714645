const displayPayment = (paymentMethod, lang) => {
  switch (paymentMethod) {
    case 'PayPal':
      return 'PayPal'
    case 'Crypto':
      return 'Crypto'
    case 'VISA':
      return 'Visa / Mastercard / JCB'
    case 'QRCODE':
      return 'QRCODE'
    case 'QRCODE247':
      return 'QRCODE247'
    case 'Topup':
      if (lang === 'en') {
        return "Account's Balance"
      } else {
        return 'Số dư tài khoản'
      }
    case 'Bank Transfer':
      if (lang === 'en') {
        return 'Bank Transfer'
      } else {
        return 'Chuyển khoản ngân hàng'
      }
    case 'ATM_ONLINE':
      if (lang === 'en') {
        return 'ATM ONLINE'
      } else {
        return 'Thẻ ATM nội địa'
      }
    case 'IB_ONLINE':
      return 'Internet Banking'
  }
}

export default displayPayment
