// Request Vietqr: img.vietqr.io/image/vietcombank-9944886488-compact2.jpg?amount=199000&addInfo=congnv88pay&accountName=Nguyen%20Viet%20Cong
// Official react paypal package: https://www.npmjs.com/package/@paypal/react-paypal-js
// Thong tin cac ngan hang Ngan luong ATM: https://www.nganluong.vn/vn/payment/atm.html

import { ThirdwebSDK } from '@thirdweb-dev/sdk'
// import { ConnectMetamaskButton } from '../components/ConnectMetamaskButton'
import { useAddress, useContract } from '@thirdweb-dev/react'
import Moment from 'react-moment'
import React, { useState, useEffect } from 'react'
import { PayPalButton } from 'react-paypal-button-v2'
import { Link } from 'react-router-dom'
import Popup from '../components/Popup'
import MintScreen from './MintScreen'
import MintDropScreen from './MintDropScreen'
import DownloadScreen from './DownloadScreen'
import {
  Form,
  Row,
  Col,
  ListGroup,
  Image,
  // Card,
  Button,
  Accordion,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import { MdContentCopy } from 'react-icons/md'
import {
  getOrderDetails,
  payOrder,
  setDeliverOrder,
  updateOrder,
  payOrderByNganluong,
} from '../actions/orderActions'
import { getUpdatedUserInfo } from '../actions/userActions'
import { createTransaction } from '../actions/transactionActions'
import { changeNetwork } from '../actions/blockchainActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
  ORDER_UPDATE_RESET,
} from '../constants/orderConstants'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import currentCurrency from '../utils/currentCurrency'
import { Trans, useTranslation } from 'react-i18next'
import displayPayment from '../utils/displayPayment'
import { setAlert } from '../actions/alertActions'
import { MINT_SET_CONTRACT } from '../constants/mintConstants'
import arrow from '../assets/down_arrow_120px.gif'
import confetti from 'canvas-confetti'

// const baseUrl = 'https://nftshop.herokuapp.com'

const paymentBanks = {
  ATM_ONLINE: [
    'VCB',
    'TCB',
    'STB',
    'MB',
    'VPB',
    'VIB',
    'TPB',
    'BIDV',
    'ICB',
    'AGB',
    'BAB',
    'SGCB',
    'DAB',
    'SHB',
    'EXB',
    'MSB',
    'NAB',
    'HDB',
    'OJB',
    'SGB',
    'VAB',
    'LVB',
    // 'BVB', // Tam khoa bao tri - Da bo 17.10.2022
    // 'ACB',// Tam khoa bao tri
    // 'KLB', // Tam khoa bao tri
    // 'GPB', // Tam khoa bao tri
    // 'NVB', // Tam khoa bao tri
    // 'PGB', // Tam khoa bao tri
  ],
  IB_ONLINE: ['VCB', 'DAB', 'BIDV', 'ABB'],
  QRCODE: [
    'VCB',
    'TCB',
    'BIDV',
    'DAB',
    'ICB',
    'STB',
    'ACB',
    'VIB',
    'MSB',
    'EXB',
    'MB',
  ],
  QRCODE247: [
    'VCB',
    'TCB',
    'BIDV',
    'DAB',
    'ICB',
    'STB',
    'ACB',
    'VIB',
    'MSB',
    'EXB',
    'MB',
  ],
}
let paymentMethod

const OrderScreen = ({ match, history }) => {
  const address = useAddress()
  const [mintPopup, setMintPopup] = useState(false)
  const [mintDropPopup, setMintDropPopup] = useState(false)
  const [downloadPopup, setDownloadPopup] = useState(false)
  const [loadingCryptoPayment, setLoadingCryptoPayment] = useState(false)
  const [canUseCrypto, setCanUseCrypto] = useState(true)

  const { t, i18n } = useTranslation()
  const [sdkReady, setSdkReady] = useState(false)
  const [payment, setPayment] = useState(false)
  const [isGift, setIsGift] = useState(false)
  const [giftName, setGiftName] = useState('')
  const [giftEmail, setGiftEmail] = useState('')
  const [giftMessage, setGiftMessage] = useState('')
  // const [qrImage, setQrImage] = useState('')

  const dispatch = useDispatch()
  const orderId = match.params.id

  const { network } = useSelector((state) => state.blockchain)

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderUpdate = useSelector((state) => state.orderUpdate)
  const {
    loading: loadingUpdate,
    success: successUpdate,
    order: updatedOrder,
  } = orderUpdate

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // console.log(
  //   'checking filter: ',
  //   order?.shipping.filter((i) => {
  //     return i.id === '78092011'
  //   })[0].id
  // )

  // console.log(order?.shipping)

  useEffect(() => {
    dispatch(getOrderDetails(orderId))

    // // get user's location
    // navigator.geolocation.getCurrentPosition(
    //   function (position) {
    //     console.log('position: ', position)
    //   },
    //   function (error) {
    //     console.error('Error Code = ' + error.code + ' - ' + error.message)
    //   }
    // )
  }, [])

  useEffect(() => {
    if (successUpdate) {
      dispatch(setAlert(t('OrderScreen.Successfully updated'), 'success'))
      dispatch({ type: ORDER_UPDATE_RESET })
    }
  }, [successUpdate])

  useEffect(() => {
    if (order) {
      setIsGift(order.isGift)
      setGiftName(order.giftName)
      setGiftEmail(order.giftEmail)
      setGiftMessage(order.giftMessage)
    }
  }, [order])

  useEffect(() => {
    if (order?.user) {
      if (userInfo?.id !== order?.user._id && !userInfo?.isAdmin) {
        history.push('/')
      }
    }

    const addPaypalScript = async () => {
      // const { data: clientId } = await axios.get('/api/config/paypal')
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`
      script.async = true
      script.onload = () => {
        setSdkReady(true)
      }
      document.body.appendChild(script)
    }

    if (successPay || successDeliver) {
      if (successPay && userInfo && paymentMethod === 'Topup') {
        dispatch(getUpdatedUserInfo(userInfo.id))
      }

      if (successPay) {
        dispatch(setAlert(t('OrderScreen.Thank you'), 'success'))
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        })
      }

      dispatch(getOrderDetails(orderId))

      dispatch({ type: ORDER_UPDATE_RESET })
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
    } else if (!order?.isPaid) {
      if (!window.paypal) {
        addPaypalScript()
      } else {
        setSdkReady(true)
      }
    }
  }, [dispatch, orderId, successPay, successDeliver, order, userInfo])

  const successPaymentHandler = (paymentResult) => {
    // console.log(paymentResult)
    dispatch(
      createTransaction({
        user: userInfo ? userInfo.id : null,
        type: 'buy',
        amount: order.totalPrice,
        order: orderId,
        paymentMethod: order.paymentMethod,
        status: 'pending',
      })
    )
    dispatch(payOrder(orderId, { ...paymentResult, payment_gateway: 'PayPal' }))
    // dispatch(setAlert(t('OrderScreen.Thank you'), 'success'))
  }

  const getFreeHandler = () => {
    const paymentResult = {}
    dispatch(payOrder(orderId, paymentResult))
    // dispatch(setAlert(t('OrderScreen.Thank you'), 'success'))
  }

  const topupHandler = () => {
    paymentMethod = 'Topup'
    const paymentResult = {
      payment_gateway: 'Topup',
    }
    dispatch(payOrder(orderId, paymentResult))
    // dispatch(setAlert(t('OrderScreen.Thank you'), 'success'))
  }

  const qrCodeHandler = () => {
    const amount = currentCurrency(order.totalPrice, 'vi')
    window.open(
      `https://img.vietqr.io/image/vietcombank-0011004336534-compact2.jpg?amount=${amount}&addInfo=${order._id}&accountName=Nguyen%20Quang%20Vinh`,
      '_blank',
      'width=600,height=600,location=no,toolbar=no'
    )
  }

  const nganluongHandler = async (bank) => {
    dispatch(payOrderByNganluong(order, i18n.language, bank))
  }

  // const token = useToken(process.env.REACT_APP_PAYMENT_TOKEN) // Address of token contract USDT / USDC
  const { contract: token } = useContract(
    process.env.REACT_APP_PAYMENT_TOKEN,
    'token'
  ) // Address of token contract USDT / USDC
  // Another TUSDT: 0xD92E713d051C37EbB2561803a3b5FBAbc4962431
  // console.log('token: ', token)

  const cryptoHandler = async () => {
    try {
      if (address) {
        if (network !== 'Polygon') {
          dispatch(changeNetwork('Polygon'))
          dispatch(
            setAlert(t('OrderScreen.Network Changed') + 'Polygon', 'success')
          )
          return
        }

        setLoadingCryptoPayment(true)
        const { displayValue } = await token.balance()
        const balance = Number(displayValue)
        // console.log('balance: ', balance)

        if (balance > order.totalPrice) {
          const tx = await token.transfer(
            process.env.REACT_APP_RECEIVER_WALLET,
            order.totalPrice
          )
          // console.log('tx: ', tx)
          if (tx && tx.receipt) {
            const paymentResult = {
              status: 'Success',
              payment_gateway: 'Crypto',
            }
            dispatch(payOrder(orderId, paymentResult))

            dispatch(
              createTransaction({
                user: userInfo ? userInfo.id : null,
                type: 'buy',
                amount: order.totalPrice,
                paymentMethod: order.paymentMethod,
                status: 'completed',
              })
            )

            // dispatch(setAlert(t('OrderScreen.Thank you'), 'success'))
            setLoadingCryptoPayment(false)
          }
        } else {
          dispatch(setAlert(t('OrderScreen.Not enough balance'), 'danger'))
          setLoadingCryptoPayment(false)
        }
      } else {
        dispatch(setAlert(t('OrderScreen.Please connect'), 'danger'))
        setLoadingCryptoPayment(false)
      }
    } catch (error) {
      dispatch(setAlert(t('OrderScreen.Crypto Payment Fail'), 'danger'))
      setLoadingCryptoPayment(false)
      console.log(
        'Something went wrong... Please try again later or contact us for help.'
      )
    }
  }

  // DOWNLOAD OPTION 1
  const downloadHandler = (item) => {
    dispatch({
      type: MINT_SET_CONTRACT,
      payload: {
        contractAddress: '',
        blockchain: '',
        item: item,
      },
    })

    setDownloadPopup(true)
  }

  const mintHandler = async (item) => {
    dispatch({
      type: MINT_SET_CONTRACT,
      payload: {
        contractAddress: item.contractAddress,
        blockchain: item?.blockchain,
        item: item,
        tokenId: item?.tokenId,
      },
    })

    setMintPopup(true)

    // if (!item.isDigital) {
    //   setMintDropPopup(true)
    // } else {
    //   setMintPopup(true)
    // }
  }

  const setPaidHandler = () => {
    const paymentResult = {
      status: 'admin',
    }
    const admin = true
    dispatch(payOrder(orderId, paymentResult, admin))
    // dispatch(setAlert(t('OrderScreen.Thank you'), 'success'))
  }

  const setDeliverHandler = () => {
    dispatch(setDeliverOrder(orderId))
  }

  const changePaymentHandler = () => {
    // console.log('change payment to: ', payment)
    order.paymentMethod = payment
    dispatch(updateOrder(order))
  }

  const setGiftHandler = () => {
    // console.log('change payment to: ', payment)
    if (
      !giftEmail ||
      giftEmail === '' ||
      !giftEmail?.includes('@') ||
      !giftEmail?.includes('.')
    ) {
      dispatch(setAlert(t('OrderScreen.Please enter email'), 'danger'))
      return
    }

    order.isGift = isGift
    order.giftName = giftName
    order.giftEmail = giftEmail
    order.giftMessage = giftMessage
    dispatch(updateOrder(order))
  }

  const copyToClipboard = (item) => {
    navigator.clipboard.writeText(item)
    dispatch(setAlert(t('RefScreen.Link copied'), 'success'))
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <>
      <Meta title={t('OrderScreen.Order') + ' - nQivi'} />
      <Message variant='danger'>{error}</Message>
    </>
  ) : (
    <>
      <Popup trigger={downloadPopup} setTrigger={setDownloadPopup}>
        <DownloadScreen />
      </Popup>
      <Popup trigger={mintPopup} setTrigger={setMintPopup}>
        <MintScreen setTrigger={setMintPopup} />
      </Popup>
      <Popup trigger={mintDropPopup} setTrigger={setMintDropPopup}>
        <MintDropScreen />
      </Popup>
      <Meta title={t('OrderScreen.Order') + ' - nQivi'} />
      {/* <Row>
        <Col style={{ display: 'flex', justifyContent: 'right' }}>
          <ConnectMetamaskButton style={{ float: 'right' }} />
        </Col>
      </Row> */}
      {/* {order.paymentMethod === 'Crypto' && <ConnectMetamaskButton />} */}
      <Row>
        <Col sm={12}>
          <h1 className='mb-0'>{t('OrderScreen.Order')}</h1>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <h4 className='m-0'>{order._id}</h4>
            <Button
              className='btn-sm ms-1 mb-3'
              variant='secondary'
              onClick={() => copyToClipboard(order._id)}
            >
              {/* {t('OrderScreen.Copy')} */}
              <MdContentCopy />
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>{t('OrderScreen.Shipping')}</h2>
              {order.user && (
                <>
                  <p>
                    <strong>{t('OrderScreen.Name')}: </strong>
                    {order.user.name}
                  </p>
                  <p>
                    <strong>{t('OrderScreen.Email')}: </strong>
                    <a href={`mailto:${order.user.email}`}>
                      {order.user.email}
                    </a>
                  </p>
                </>
              )}
              {order.shippingAddress && (
                <>
                  <p>
                    <strong>{t('OrderScreen.Address')}: </strong>
                    {order.shippingAddress.address},{' '}
                    {order.shippingAddress.city},{' '}
                    {order.shippingAddress.postalCode},{' '}
                    {order.shippingAddress.country}
                  </p>
                </>
              )}
              {order.ref && userInfo && userInfo.isAdmin && (
                <p>
                  <strong>Ref ID: </strong>
                  {order.ref}
                </p>
              )}
              {order.isDelivered ? (
                <Message variant='success'>
                  {t('OrderScreen.Delivered on')}{' '}
                  <Moment format='YYYY/MM/DD hh:mm'>{order.deliveredAt}</Moment>
                </Message>
              ) : (
                <Message variant='danger'>
                  {t('OrderScreen.Not Delivered')}
                </Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>{t('OrderScreen.Payment Method')}</h2>
              <Row>
                <p>
                  <strong>{t('OrderScreen.Method')}: </strong>
                  {displayPayment(order.paymentMethod, i18n.language)}
                </p>
                {order.paymentMethod === 'Topup' &&
                  userInfo?.credits < order.totalPrice && (
                    <Message variant='info'>
                      <Trans i18nKey='OrderScreen.Please topup'>
                        Please <Link to='/topup'>top-up</Link> your balance to
                        continue.
                      </Trans>
                    </Message>
                  )}

                <Form onSubmit={changePaymentHandler} className='d-flex my-1'>
                  {/* <Form.Group controlId='selectpayment'> */}
                  {/* <Form.Label>{t('OrderScreen.Rating')}</Form.Label> */}
                  <Form.Select
                    value={payment}
                    onChange={(e) => setPayment(e.target.value)}
                    // style={{ color: '#212529' }}
                  >
                    <option value={order.paymentMethod}>
                      {t('OrderScreen.Change')}...
                    </option>
                    {window.location.hostname.includes('.com') && (
                      // {i18n.language === 'en' && (
                      <>
                        <option value='PayPal'>
                          {t('OrderScreen.PayPal')}
                        </option>

                        <option value='Crypto'>
                          {t('PaymentScreen.Crypto')}
                        </option>
                      </>
                    )}
                    <option value='Topup'>{t('PaymentScreen.Topup')}</option>
                    {i18n.language === 'vi' && (
                      <>
                        <option value='VISA'>{t('OrderScreen.VISA')}</option>
                        <option value='ATM_ONLINE'>
                          {t('OrderScreen.ATM_ONLINE')}
                        </option>
                        <option value='IB_ONLINE'>
                          {t('OrderScreen.IB_ONLINE')}
                        </option>
                      </>
                    )}
                  </Form.Select>
                  {/* </Form.Group> */}
                  <Button
                    type='submit'
                    variant='secondary'
                    style={{ width: '160px' }}
                  >
                    {t('OrderScreen.Submit')}
                  </Button>
                </Form>
              </Row>

              {(order.paymentMethod === 'ATM_ONLINE' ||
                order.paymentMethod === 'IB_ONLINE') &&
                !order.isPaid && (
                  <div className='mb-2'>
                    <div style={{ color: 'yellow' }}>
                      <strong>{t('OrderScreen.Choose Bank')}</strong>
                      <Image src={arrow} width={80} fluid />
                    </div>
                    {loadingPay && <Loader />}
                    <div>
                      {paymentBanks[order.paymentMethod].map((bank) => (
                        <Image
                          key={bank}
                          src={`/assets/banks/${bank}.png`}
                          alt={bank}
                          className='m-1'
                          width='100'
                          style={{
                            cursor: 'pointer',
                            backgroundColor: 'white',
                          }}
                          rounded
                          onClick={() => nganluongHandler(bank)}
                        ></Image>
                      ))}
                    </div>
                  </div>
                )}

              {/* {(order.paymentMethod === 'QRCODE' ||
                order.paymentMethod === 'QRCODE247') &&
                !order.isPaid && (
                  <div className='mb-2'>
                    <div>
                      <strong>{t('OrderScreen.Choose Bank')}</strong>
                    </div>
                    {loadingPay && <Loader />}
                    <div>
                      {paymentBanks[order.paymentMethod].map((bank) => (
                        <Image
                          key={bank}
                          src={`/assets/banks/${bank}.png`}
                          alt={bank}
                          className='m-1'
                          width='100'
                          style={{
                            cursor: 'pointer',
                            backgroundColor: 'white',
                          }}
                          rounded
                          onClick={() => nganluongHandler(bank)}
                        ></Image>
                      ))}
                    </div>
                  </div>
                )} */}

              {order.isPaid ? (
                <Message variant='success'>
                  {t('OrderScreen.Paid on')}{' '}
                  <Moment format='YYYY/MM/DD hh:mm'>{order.paidAt}</Moment>
                </Message>
              ) : (
                <Message variant='danger'>{t('OrderScreen.Not Paid')}</Message>
              )}

              {/* Group Buy Explaination of Not Paid */}
              {order.paymentMethod === 'GROUP_BUY' && !order.isPaid && (
                <Message variant='info'>
                  {t('OrderScreen.Group Buy Not Paid')}
                </Message>
              )}
            </ListGroup.Item>

            {/* SET GIFT */}
            <ListGroup.Item>
              <Row>
                <Col className='d-flex'>
                  <Form.Check
                    type='checkbox'
                    className='me-3'
                    label={t('OrderScreen.Buy as Gift')}
                    checked={isGift}
                    onChange={(e) => setIsGift(e.target.checked)}
                  ></Form.Check>
                  <Button
                    type='submit'
                    className='btn-sm'
                    variant='secondary'
                    onClick={setGiftHandler}
                  >
                    {loadingUpdate ? (
                      <Loader size='sm' />
                    ) : (
                      t('OrderScreen.Set Gift')
                    )}
                  </Button>
                </Col>
              </Row>

              {isGift && (
                <Form>
                  <Form.Group controlId='giftName'>
                    <Form.Label>{t('OrderScreen.Gift Name')}</Form.Label>
                    <Form.Control
                      type='text'
                      value={giftName}
                      onChange={(e) => setGiftName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='giftEmail'>
                    <Form.Label>{t('OrderScreen.Gift Email')}</Form.Label>
                    <Form.Control
                      type='text'
                      value={giftEmail}
                      onChange={(e) => setGiftEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId='giftMessage'>
                    <Form.Label>{t('OrderScreen.Gift Message')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows='3'
                      value={giftMessage}
                      onChange={(e) => setGiftMessage(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Form>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>{t('OrderScreen.Order Items')}</h2>
              {order.orderItems.length === 0 ? (
                <Message>{t('OrderScreen.Your order is empty')}</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      {/* {setTotalQty(totalQty + item.qty)} */}
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image || ''}
                            // alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link
                            to={`/${
                              item?.productLink ? item?.productLink : 'product'
                            }/${item.product}${
                              order.isPaid ? `?order_code=${order._id}` : ''
                            }`}
                          >
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x {t('currency')}{' '}
                          {currentCurrencyToLocale(item.price, i18n.language)} ={' '}
                          {t('currency')}{' '}
                          {currentCurrencyToLocale(
                            item.qty * item.price,
                            i18n.language
                          )}
                        </Col>
                        <Col md={2}>
                          {!order.isDelivered &&
                            item.delivered < item.qty &&
                            item?.type !== 'membership' &&
                            item?.productLink !== 'customizer' && (
                              <>
                                <Row>
                                  <Button
                                    type='button'
                                    disabled={!order.isPaid}
                                    className='btn-block btn-sm mb-1 btn-primary rounded-pill'
                                    onClick={() => downloadHandler(item)}
                                  >
                                    {t('OrderScreen.Download')}
                                  </Button>
                                </Row>

                                {item?.isMintable !== false && (
                                  <Row>
                                    <Button
                                      type='button'
                                      disabled={!order.isPaid}
                                      className='btn-block btn-sm mb-1 btn-primary rounded-pill'
                                      onClick={() => mintHandler(item)}
                                    >
                                      {t('OrderScreen.Mint')}
                                    </Button>
                                  </Row>
                                )}
                              </>
                            )}

                          {!order.isDelivered &&
                            item.delivered < item.qty &&
                            item?.productLink === 'customizer' && (
                              <Row>
                                <Link
                                  className='btn btn-sm mb-1 btn-primary rounded-pill'
                                  to={`/${
                                    item?.productLink
                                      ? item?.productLink
                                      : 'product'
                                  }/${item.product}${
                                    order.isPaid
                                      ? `?order_code=${order._id}`
                                      : ''
                                  }`}
                                >
                                  {t('OrderScreen.Mint')}
                                </Link>
                              </Row>
                            )}

                          {/* {window.location.hostname.includes('.com') && ( */}
                          {/* {i18n.language === 'en' && (
                            <Row>
                              <Button
                                type='button'
                                disabled={!order.isPaid}
                                className='btn-block btn-sm mb-1 btn-primary rounded-pill'
                                onClick={() =>
                                  history.push(
                                    `/pod/${orderId}/${item.product}`
                                  )
                                }
                              >
                                {t('OrderScreen.POD')}
                              </Button>
                            </Row>
                          )} */}
                        </Col>
                      </Row>
                      {/* Embeded mint button */}
                      {/* {order.isPaid &&
                        !order.isDelivered &&
                        item.delivered < item.qty && (
                          <Row>
                            <Accordion flush>
                              <Accordion.Item eventKey='0'>
                                <Accordion.Header
                                  style={{ backgroundColor: 'black' }}
                                >
                                  {t('OrderScreen.Mint')}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <iframe
                                    src={`https://gateway.ipfscdn.io/ipfs/QmfJu3spsSJot6givCK2VjwEgVHymc5RCXHqfG1W5WZyFX/nft-drop.html?contract=${item.contractAddress}&chainId=4`}
                                    width='500px'
                                    height='500px'
                                    style={{ maxWidth: '100%' }}
                                    frameBorder={0}
                                  />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Row>
                        )} */}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>

            {/* POD orders */}
            {order.podOrders && order.podOrders.length > 0 && (
              <ListGroup.Item>
                <h2>{t('OrderScreen.POD Order')}</h2>
                <ListGroup variant='flush'>
                  {order.podOrders.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={2}>{item.id}</Col>
                        <Col md={1}>{item.status}</Col>
                        <Col md={1}>{item.retail_costs}</Col>
                        <Col md={2}>{item.shipping}</Col>
                        <Col md={2}>{item.shipping_service_name}</Col>
                        {order.shipping &&
                          order.shipping.length > 0 &&
                          order.shipping.filter((i) => {
                            return i.id === item.id
                          }).length > 0 && (
                            <>
                              <Col>
                                {order.shipping.filter((i) => {
                                  return i.id === item.id
                                })[0]?.shipped && t('OrderScreen.Shipped')}
                              </Col>
                              <Col>
                                <Moment format='YYYY/MM/DD hh:mm'>
                                  {
                                    order.shipping.filter((i) => {
                                      return i.id === item.id
                                    })[0]?.shipped_at
                                  }
                                </Moment>
                              </Col>
                              <Col>
                                <a
                                  href={
                                    order.shipping.filter((i) => {
                                      return i.id === item.id
                                    })[0]?.tracking_url
                                  }
                                >
                                  {t('OrderScreen.Tracking URL')}
                                </a>
                              </Col>
                            </>
                          )}
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </ListGroup.Item>
            )}
          </ListGroup>
        </Col>

        <Col md={4}>
          {/* <Card> */}
          <ListGroup>
            <ListGroup.Item>
              <h2>{t('OrderScreen.Order Summary')}</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>{t('OrderScreen.Items')}</Col>
                <Col>
                  {t('currency')}{' '}
                  {currentCurrencyToLocale(order.itemsPrice, i18n.language)}
                </Col>
              </Row>
            </ListGroup.Item>

            {/* <ListGroup.Item>
                <Row>
                  <Col>{t('OrderScreen.ShippingPrice')}</Col>
                  <Col>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(
                      order.shippingPrice,
                      i18n.language
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>{t('OrderScreen.Tax')}</Col>
                  <Col>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(order.taxPrice, i18n.language)}
                  </Col>
                </Row>
              </ListGroup.Item> */}

            <ListGroup.Item>
              <Row>
                <Col>
                  <strong>{t('OrderScreen.Total')}</strong>
                </Col>
                <Col>
                  {t('currency')}{' '}
                  <strong>
                    {currentCurrencyToLocale(order.totalPrice, i18n.language)}
                  </strong>
                </Col>
              </Row>
            </ListGroup.Item>

            {!order.isPaid && order.totalPrice > 0 && (
              <ListGroup.Item>
                {loadingPay && <Loader />}

                {order.paymentMethod === 'PayPal' &&
                  i18n.language === 'en' &&
                  (!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={order.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  ))}

                {order.paymentMethod === 'Bank Transfer' && (
                  <Button
                    type='button'
                    className='btn btn-block rounded-pill'
                    onClick={qrCodeHandler}
                  >
                    {t('OrderScreen.Pay QR')}
                  </Button>
                )}

                {order.paymentMethod === 'VISA' && (
                  <Button
                    type='button'
                    className='btn btn-block rounded-pill'
                    onClick={() => nganluongHandler(null)}
                  >
                    {t('OrderScreen.Pay')}
                  </Button>
                )}

                {/* Confirm not Vietnamese */}
                {order.paymentMethod === 'Crypto' && (
                  // window.location.href.includes('nqivi.vn') &&
                  <div
                    style={{ fontWeight: 'lighter', fontSize: '0.9rem' }}
                    className='mb-2'
                  >
                    <Form.Label
                      style={{
                        outlineStyle: 'solid',
                        outlineWidth: '1px',
                        padding: '10px',
                      }}
                    >
                      {t('OrderScreen.Countries ban crypto')}
                    </Form.Label>
                    <Form.Check
                      type='checkbox'
                      label={t('OrderScreen.Can use crypto')}
                      checked={canUseCrypto}
                      onChange={(e) => setCanUseCrypto(e.target.checked)}
                    ></Form.Check>
                  </div>
                )}

                {order.paymentMethod === 'Crypto' && canUseCrypto && (
                  <Button
                    type='button'
                    className='btn btn-block rounded-pill'
                    onClick={cryptoHandler}
                  >
                    {loadingCryptoPayment ? (
                      <Loader size='sm' />
                    ) : (
                      t('OrderScreen.Pay')
                    )}
                  </Button>
                )}
              </ListGroup.Item>
            )}

            {/* PAY BY CREDITS BUTTON */}
            {!order.isPaid &&
              order.paymentMethod !== 'GROUP_BUY' &&
              userInfo &&
              order.totalPrice > 0 &&
              userInfo?.credits >= order.totalPrice && (
                <ListGroup.Item>
                  <Message variant='success'>
                    {t('OrderScreen.Enough Credits')}
                  </Message>
                  <Button
                    type='button'
                    className='btn btn-block rounded-pill'
                    onClick={topupHandler}
                  >
                    {t('OrderScreen.Pay By Credits')}
                  </Button>
                </ListGroup.Item>
              )}

            {/* GET FREE BUTTON */}
            {!order.isPaid && order.totalPrice <= 0 && (
              <ListGroup.Item>
                {loadingPay && <Loader />}

                {userInfo ? (
                  <Button
                    type='button'
                    className='btn btn-block rounded-pill'
                    onClick={getFreeHandler}
                  >
                    {t('OrderScreen.Get free')}
                  </Button>
                ) : (
                  <Message variant='info'>
                    <Trans i18nKey='OrderScreen.Please login'>
                      Please <Link to='/login'>login</Link>
                    </Trans>
                    <Trans i18nKey='OrderScreen.Create an account'>
                      or <Link to='/register'>create an account</Link> to get
                      your NFT
                    </Trans>
                  </Message>
                )}
              </ListGroup.Item>
            )}

            {/* Mark paid button */}
            {userInfo &&
              userInfo.isAdmin &&
              order.paymentMethod !== 'GROUP_BUY' &&
              !order.isPaid &&
              !order.isDelivered && (
                <ListGroup.Item>
                  <Button
                    type='button'
                    className='btn btn-block rounded-pill'
                    onClick={setPaidHandler}
                  >
                    {t('OrderScreen.Mark As Paid')}
                  </Button>
                </ListGroup.Item>
              )}

            {/* Mark delivered button */}
            {loadingDeliver && <Loader />}
            {userInfo &&
              userInfo.isAdmin &&
              order.isPaid &&
              !order.isDelivered && (
                <ListGroup.Item>
                  <Button
                    type='button'
                    className='btn btn-block rounded-pill'
                    onClick={setDeliverHandler}
                  >
                    {t('OrderScreen.Mark As Delivered')}
                  </Button>
                </ListGroup.Item>
              )}
          </ListGroup>
          {/* </Card> */}
        </Col>
      </Row>
    </>
  )
}

export default OrderScreen
