import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_RESET,
  PRODUCT_LIST_RELATED,
  PRODUCT_LIST_BY_IDS,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_RESET,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_LIST_MY_REQUEST,
  PRODUCT_LIST_MY_SUCCESS,
  PRODUCT_LIST_MY_FAIL,
  PRODUCT_UPDATE_LIKE_SUCCESS,
  PRODUCT_UPDATE_LIKE_FAIL,
  PRODUCT_GROUPART_APPROVE,
  PRODUCT_GROUPART_DELETE,
  PRODUCT_GROUPART_FAIL,
  PRODUCT_GROUPART_ADD,
  PRODUCT_GROUPART_EDIT,
  PRODUCT_GROUPART_REQUEST,
  PRODUCT_GROUPART_RESET,
  PRODUCT_GROUPBUY_REQUEST,
  PRODUCT_GROUPBUY_FAIL,
  PRODUCT_GROUPBUY_CLOSE,
  PRODUCT_GROUPBUY_ADD_ORDER,
  PRODUCT_GROUPBUY_RESET,
  SHOE_RESET,
  SHOE_CHANGE_CURRENT,
  SHOE_CHANGE_COLOR,
  PRODUCT_VARIANT_DELETE,
  PRODUCT_VARIANT_ADD,
  PRODUCT_VARIANT_RESET,
  PRODUCT_VOTE,
  PRODUCT_VOTE_RESET,
  PRODUCT_GALLERY_REQUEST,
  PRODUCT_GALLERY_SUCCESS,
  PRODUCT_GALLERY_FAIL,
  PRODUCT_GALLERY_RESET,
  PRODUCT_RELATED_REQUEST,
  PRODUCT_RELATED_SUCCESS,
  PRODUCT_RELATED_FAIL,
  PRODUCT_RELATED_RESET,
  PRODUCT_UPSALE_REQUEST,
  PRODUCT_UPSALE_SUCCESS,
  PRODUCT_UPSALE_FAIL,
  PRODUCT_UPSALE_RESET,
  PRODUCT_UPDATE_TOKENID_SUCCESS,
  PRODUCT_UPDATE_TOKENID_FAIL,
  PRODUCT_DELETE_MANY_SUCCESS,
  PRODUCT_UPDATE_MANY_SUCCESS,
  PRODUCT_FEATURED_REQUEST,
  PRODUCT_FEATURED_SUCCESS,
  PRODUCT_FEATURED_FAIL,
  PRODUCT_FEATURED_RESET,
} from '../constants/productConstants'

export const productListReducer = (state = { products: [] }, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_LIST_REQUEST:
      return { ...state, loading: true, products: [] }
    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        products: payload.products,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case PRODUCT_LIST_BY_IDS:
      return { ...state, loading: false, products: payload }
    case PRODUCT_LIST_FAIL:
      return { ...state, loading: false, error: payload }
    case PRODUCT_LIST_RESET:
      return { products: [] }
    default:
      return state
  }
}

export const productsUpSaleReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_UPSALE_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_UPSALE_SUCCESS:
      return {
        ...state,
        loading: false,
        products: payload,
      }
    case PRODUCT_UPSALE_FAIL:
      return { ...state, loading: false, error: payload }
    case PRODUCT_UPSALE_RESET:
      return {}
    default:
      return state
  }
}

export const productsRelatedReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_RELATED_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_RELATED_SUCCESS:
      return {
        ...state,
        loading: false,
        products: payload,
      }
    case PRODUCT_RELATED_FAIL:
      return { ...state, loading: false, error: payload }
    case PRODUCT_RELATED_RESET:
      return {}
    default:
      return state
  }
}

export const productGalleryReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_GALLERY_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_GALLERY_SUCCESS:
      return {
        ...state,
        loading: false,
        products: payload,
      }
    case PRODUCT_GALLERY_FAIL:
      return { ...state, loading: false, error: payload }
    case PRODUCT_GALLERY_RESET:
      return {}
    default:
      return state
  }
}

export const productFeaturedReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_FEATURED_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_FEATURED_SUCCESS:
      return {
        ...state,
        loading: false,
        products: payload,
      }
    case PRODUCT_FEATURED_FAIL:
      return { ...state, loading: false, error: payload }
    case PRODUCT_FEATURED_RESET:
      return {}
    default:
      return state
  }
}

export const productListMyReducer = (state = { products: [] }, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_LIST_MY_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_LIST_MY_SUCCESS:
      return {
        loading: false,
        products: payload,
      }
    case PRODUCT_LIST_MY_FAIL:
      return { loading: false, error: payload }
    default:
      return state
  }
}

export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_DETAILS_SUCCESS:
      return { ...state, loading: false, product: payload }
    case PRODUCT_DETAILS_FAIL:
      return { ...state, loading: false, error: payload }

    case PRODUCT_UPDATE_LIKE_SUCCESS:
      return { ...state, successLike: true }
    case PRODUCT_UPDATE_LIKE_FAIL:
      return { ...state, successLike: false, errorLike: payload }

    case PRODUCT_UPDATE_TOKENID_SUCCESS:
      return { ...state, successTokenId: true }
    case PRODUCT_UPDATE_TOKENID_FAIL:
      return { ...state, successTokenId: false, errorTokenId: payload }

    case PRODUCT_VOTE:
      return { ...state, successVote: true, product: payload, loading: false }
    case PRODUCT_VOTE_RESET:
      return { ...state, successVote: false }

    case PRODUCT_VARIANT_ADD:
      return {
        ...state,
        successAddVariant: true,
        loading: false,
        product: payload,
      }
    case PRODUCT_VARIANT_DELETE:
      return {
        ...state,
        successDeleteVariant: true,
        loading: false,
        product: payload,
      }
    case PRODUCT_VARIANT_RESET:
      return {
        ...state,
        successAddVariant: false,
        successDeleteVariant: false,
        loading: false,
        error: null,
      }

    case PRODUCT_DETAILS_RESET:
      return {}
    default:
      return state
  }
}

export const productGroupArtReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_GROUPART_REQUEST:
      return { ...state, loadingGroupArt: true }
    case PRODUCT_GROUPART_ADD:
      return { ...state, loadingGroupArt: false, successAddGroupArt: true }
    case PRODUCT_GROUPART_EDIT:
      return { ...state, loadingGroupArt: false, successEditGroupArt: true }
    case PRODUCT_GROUPART_APPROVE:
      return { ...state, loadingGroupArt: false, successApproveGroupArt: true }
    case PRODUCT_GROUPART_DELETE:
      return { ...state, loadingGroupArt: false, successDeleteGroupArt: true }
    case PRODUCT_GROUPART_FAIL:
      return { ...state, loadingGroupArt: false, errorGroupArt: payload }
    case PRODUCT_GROUPART_RESET:
      return {}

    default:
      return state
  }
}

export const productDeleteReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true }
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_DELETE_MANY_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: payload }
    default:
      return state
  }
}

export const productCreateReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, product: payload }
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: payload }
    case PRODUCT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const productUpdateReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: payload }
    case PRODUCT_UPDATE_MANY_SUCCESS:
      return { loading: false, successUpdateMany: true, products: payload }
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: payload }
    case PRODUCT_UPDATE_RESET:
      return {}
    default:
      return state
  }
}

export const productReviewReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: payload }
    case PRODUCT_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const productTopRatedReducer = (state = { products: [] }, action) => {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_TOP_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_TOP_SUCCESS:
      return { loading: false, products: payload }
    case PRODUCT_TOP_FAIL:
      return { loading: false, error: payload }
    default:
      return state
  }
}

export const productGroupBuyReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case PRODUCT_GROUPBUY_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_GROUPBUY_FAIL:
      return { ...state, loading: false, error: payload }
    case PRODUCT_GROUPBUY_ADD_ORDER:
      return { ...state, loading: false, successAdd: true, promotion: payload }
    case PRODUCT_GROUPBUY_CLOSE:
      return {
        ...state,
        loading: false,
        successClose: true,
        promotion: payload,
      }
    case PRODUCT_GROUPBUY_RESET:
      return {}
    default:
      return state
  }
}

export const productShoeReducer = (
  state = {
    snap: {
      current: null,
      items: {
        laces: '#ffffff',
        mesh: '#ffffff',
        caps: '#ffffff',
        inner: '#ffffff',
        sole: '#ffffff',
        stripes: '#ffffff',
        band: '#ffffff',
        patch: '#ffffff',
      },
    },
  },
  action
) => {
  const { type, payload } = action

  switch (type) {
    case SHOE_CHANGE_COLOR:
      return {
        ...state,
        snap: {
          ...state.snap,
          items: {
            ...state.snap.items,
            [payload.name]: payload.color,
          },
        },
      }

    case SHOE_CHANGE_CURRENT:
      return {
        ...state,
        snap: {
          ...state.snap,
          current: payload,
        },
      }

    case SHOE_RESET:
      return {
        snap: {
          current: null,
          items: {
            laces: '#ffffff',
            mesh: '#ffffff',
            caps: '#ffffff',
            inner: '#ffffff',
            sole: '#ffffff',
            stripes: '#ffffff',
            band: '#ffffff',
            patch: '#ffffff',
          },
        },
      }
    default:
      return state
  }
}
