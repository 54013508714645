import React, { useEffect, useState } from 'react'
import { Form, Button, Row, Col, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { saveShippingAddress } from '../actions/cartActions'
import { useTranslation } from 'react-i18next'
import Meta from '../components/Meta'
import printful_country_state_codes from '../printful_country_state_codes'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'

const ShippingScreen = ({ history }) => {
  const { t, i18n } = useTranslation()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    // * (1 - discount)
  )
  cart.shippingPrice = addDecimals(0)
  cart.taxPrice = addDecimals(Number(0 * cart.itemsPrice))

  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2)

  const [name, setName] = useState(shippingAddress?.name || '')
  const [email, setEmail] = useState(shippingAddress?.email || '')
  const [phone, setPhone] = useState(shippingAddress?.phone || '')
  const [address, setAddress] = useState(shippingAddress?.address || '')
  // const [address2, setAddress2] = useState(shippingAddress?.address2 || '')
  const [city, setCity] = useState(shippingAddress?.city || '')
  const [state, setState] = useState(shippingAddress?.state || undefined)
  const [postalCode, setPostalCode] = useState(
    shippingAddress?.postalCode || ''
  )
  const [country, setCountry] = useState(shippingAddress?.country || undefined)
  const [currentCountry, setCurrentCountry] = useState(
    country &&
      printful_country_state_codes.find((item) => item.code === country)
  )

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name)
      setEmail(userInfo.email)
      setPhone(userInfo.phone)
    }
  }, [userInfo])

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      saveShippingAddress({
        name,
        email,
        phone,
        address,
        // address2,
        city,
        state,
        postalCode,
        country,
      })
    )
    history.push('/payment')
  }

  return (
    <Row>
      <CheckoutSteps step1 step2 />

      <Meta title={t('ShippingScreen.Shipping') + ' - nQivi'} />

      <Col md={8}>
        <FormContainer size='lg'>
          <h1>{t('ShippingScreen.Shipping')}</h1>

          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>{t('CreatePODScreen.Name')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('CreatePODScreen.Enter name')}
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label>{t('CreatePODScreen.Email')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('CreatePODScreen.Enter email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='phone'>
              <Form.Label>{t('CreatePODScreen.Phone')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('CreatePODScreen.Enter phone')}
                value={phone}
                required
                onChange={(e) => setPhone(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='address'>
              <Form.Label>{t('ShippingScreen.Address')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('ShippingScreen.Enter address')}
                value={address}
                required
                onChange={(e) => setAddress(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='city'>
              <Form.Label>{t('ShippingScreen.City')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('ShippingScreen.Enter city')}
                value={city}
                required
                onChange={(e) => setCity(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {/* <Form.Group controlId='state'>
          <Form.Label>{t('CreatePODScreen.State')}</Form.Label>
          <Form.Control
            type='text'
            placeholder={t('CreatePODScreen.Enter state')}
            value={state}
            required
            onChange={(e) => setState(e.target.value)}
          ></Form.Control>
        </Form.Group> */}

            {currentCountry &&
              currentCountry?.states &&
              currentCountry?.states.length > 0 && (
                <Form.Group controlId='state'>
                  <Form.Label>{t('CreatePODScreen.State')}</Form.Label>
                  <Form.Select
                    value={state}
                    defaultValue=''
                    onChange={(e) => setState(e.target.value)}
                    // style={{ color: 'yellow' }}
                    // style={{ color: '#212529' }}
                  >
                    <option value=''>
                      {t('CreatePODScreen.Select state')}
                    </option>
                    {currentCountry?.states.map((item) => (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}

            <Form.Group controlId='postalCode'>
              <Form.Label>{t('ShippingScreen.Postal code')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('ShippingScreen.Enter postal code')}
                value={postalCode}
                required
                onChange={(e) => setPostalCode(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {/* <Form.Group controlId='country'>
          <Form.Label>{t('ShippingScreen.Country')}</Form.Label>
          <Form.Control
            type='text'
            placeholder={t('ShippingScreen.Enter country')}
            value={country}
            required
            onChange={(e) => setCountry(e.target.value)}
          ></Form.Control>
        </Form.Group> */}

            <Form.Group controlId='country'>
              <Form.Label>{t('ShippingScreen.Country')}</Form.Label>
              <Form.Select
                value={country}
                defaultValue=''
                onChange={(e) => {
                  setCountry(e.target.value)
                  setCurrentCountry(
                    printful_country_state_codes.find(
                      (item) => item.code === e.target.value
                    )
                  )
                }}
                // style={{ color: 'yellow' }}
                // style={{ color: '#212529' }}
              >
                {printful_country_state_codes && (
                  <>
                    <option value=''>
                      {t('CreatePODScreen.Select country')}
                    </option>
                    {printful_country_state_codes.map((item) => (
                      <option key={item.code} value={item.code}>
                        {item.name}
                      </option>
                    ))}
                  </>
                )}
              </Form.Select>
            </Form.Group>

            <Button type='submit' variant='primary' className='my-2'>
              {t('ShippingScreen.Continue')}
            </Button>
          </Form>
        </FormContainer>
      </Col>

      <Col md={4} className='mt-2'>
        <ListGroup variant='flush'>
          <ListGroup.Item>
            <h2>{t('OrderScreen.Order Summary')}</h2>
          </ListGroup.Item>
          <ListGroup.Item>
            <Row>
              <Col>{t('OrderScreen.Items')}</Col>
              <Col>
                {t('currency')}{' '}
                {currentCurrencyToLocale(cart.itemsPrice, i18n.language)}
              </Col>
            </Row>
          </ListGroup.Item>

          {/* <ListGroup.Item>
              <Row>
                <Col>{t('OrderScreen.ShippingPrice')}</Col>
                <Col>
                  {t('currency')}{' '}
                  {currentCurrencyToLocale(cart.shippingPrice, i18n.language)}
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>{t('OrderScreen.Tax')}</Col>
                <Col>
                  {t('currency')}{' '}
                  {currentCurrencyToLocale(cart.taxPrice, i18n.language)}
                </Col>
              </Row>
            </ListGroup.Item> */}

          <ListGroup.Item>
            <Row>
              <Col>{t('OrderScreen.Total')}</Col>
              <Col>
                {t('currency')}{' '}
                {currentCurrencyToLocale(cart.totalPrice, i18n.language)}
              </Col>
            </Row>
          </ListGroup.Item>
        </ListGroup>
      </Col>
    </Row>
  )
}

export default ShippingScreen
