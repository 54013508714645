import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, ListGroup, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { setAlert } from '../actions/alertActions'
import CheckoutSteps from '../components/CheckoutSteps'
import { createOrder } from '../actions/orderActions'
import { sendEmailGallery } from '../actions/userActions'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import displayPayment from '../utils/displayPayment'
import { useTranslation, Trans } from 'react-i18next'
import { getPromoCode, takePromoCode } from '../actions/promoActions'
import { saveCoupon } from '../actions/cartActions'
import { PROMO_GET_APPLIED, PROMO_RESET } from '../constants/promoConstants'
import Meta from '../components/Meta'

const PlaceOrderScreen = ({ history }) => {
  const { t, i18n } = useTranslation()

  const dispatch = useDispatch()
  const [discount, setDiscount] = useState(0)
  const [promoCode, setPromoCode] = useState('')

  const cart = useSelector((state) => state.cart)

  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0) *
      (1 - discount)
  )
  cart.shippingPrice = addDecimals(0)
  cart.taxPrice = addDecimals(Number(0 * cart.itemsPrice))

  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(1)

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order, success, error } = orderCreate

  const promo = useSelector((state) => state.promo)
  const {
    loading,
    error: errorPromo,
    promoInfo,
    couponApplied,
    successGet,
  } = promo

  const { ref } = useSelector((state) => state.ref)

  // useEffect(() => {
  //   dispatch({ type: PROMO_RESET })
  // }, [])

  useEffect(() => {
    if (success) {
      // send email to seller if product type is gallery
      order.orderItems.forEach((item) => {
        // cartItems doesn't work because it already RESET when creating order
        // console.log('item?.type', item?.type)
        // console.log('item?.sellerEmail', item?.sellerEmail)

        if (item?.type === 'gallery' && item?.sellerEmail) {
          // console.log('sending email to seller')
          // not yet check whether gallery is active or not
          const baseURL = `https://${window.location.host}`
          const subject = `nQivi: New Order at $${item.price}`
          const content = `<p>Congratulations! You have a new order for artwork <a href="${baseURL}/product/${item?.product}">${item.name}</a></p>
          <p>After the customer make payment & gallery event is over, you will receive your payment for the artwork.</p>
          <p>Thank you for working with us. Keep making art!</p>
    <p>From nQivi with love <3</p>
          `
          const subjectVN = `nQivi: Tác phẩm của bạn được đặt mua với giá $${item.price}`
          const contentVN = `<p>Xin chúc mừng! Tác phẩm <a href="${baseURL}/product/${item?.product}">${item.name}</a> của bạn vừa được đặt mua.</p>
          <p>Sau khi khách hàng hoàn tất thanh toán và triển lãm kết thúc, bạn sẽ nhận được thanh toán.</p>
          <p>Cảm ơn bạn đã đồng hành!</p>
    <p>From nQivi with love <3</p>
          `
          dispatch(
            sendEmailGallery(
              item?.sellerEmail,
              i18n.language === 'vi' ? subjectVN : subject,
              i18n.language === 'vi' ? contentVN : content
            )
          )
        }
      })

      if (promoInfo && promoInfo.qty > 0) {
        dispatch(takePromoCode(promoInfo))
      } else if (couponApplied) {
        dispatch(
          takePromoCode({
            _id: couponApplied,
            promoId: couponApplied,
            isActive: true,
          })
        )
      }
      // else if (discount > 0) {
      //   dispatch(
      //     takePromoCode({
      //       _id: cart?.coupon?.promoId,
      //       promoId: cart?.coupon?.promoId,
      //       qty: 1000,
      //       discount: cart?.coupon?.discount,
      //       isActive: true,
      //     })
      //   )
      // }

      dispatch({ type: PROMO_RESET })
      dispatch({ type: ORDER_CREATE_RESET })
      history.push(`/order/${order._id}`)
    }
    if (!cart.paymentMethod) {
      history.push(`/payment`)
    }
    // eslint-disable-next-line
  }, [history, success])

  useEffect(() => {
    if (
      promoInfo &&
      promoInfo.qty > 0 &&
      promoInfo.isActive &&
      !couponApplied
    ) {
      setDiscount(promoInfo.discount)

      dispatch(
        saveCoupon({
          promoId: promoInfo.promoId,
          discount: promoInfo.discount,
        })
      )
    } else {
      setDiscount(0)
    }
  }, [promoInfo])

  useEffect(() => {
    if (cart?.coupon?.discount > 0 && !couponApplied) {
      setDiscount(cart?.coupon?.discount)

      dispatch({ type: PROMO_GET_APPLIED, payload: cart?.coupon?.promoId })
    }
  }, [cart])

  useEffect(() => {
    if (error && error !== '') {
      dispatch(setAlert(error, 'danger'))
    }
  }, [error])

  const placeOrderHandler = () => {
    let items
    if (discount > 0) {
      items = cart.cartItems.map((item) => {
        return {
          ...item,
          price: item.price * (1 - discount),
        }
      })
    } else {
      items = cart.cartItems
    }

    dispatch(
      createOrder(
        {
          orderItems: items,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
          ref: ref,
          language: i18n.language,
          coupon: cart.coupon?.promoId,
        },
        i18n.language
      )
    )
  }

  const promoCodeHandler = (e) => {
    e.preventDefault()
    dispatch({ type: PROMO_RESET })

    if (couponApplied) return

    //  Check if promo code is valid
    dispatch(getPromoCode(promoCode))
  }

  return (
    <>
      <CheckoutSteps step1 step2 step3 step4 />
      <Meta title={t('CheckoutSteps.Place Order') + ' - nQivi'} />
      <Row>
        <Col md={8} className='mb-1'>
          <ListGroup variant='flush'>
            {cart.shippingAddress && (
              <ListGroup.Item>
                <h2>{t('OrderScreen.Shipping')}</h2>
                <p>
                  <strong>{t('OrderScreen.Address')}:</strong>
                  {cart.shippingAddress.address}, {cart.shippingAddress.city},{' '}
                  {cart.shippingAddress.postalCode},{' '}
                  {cart.shippingAddress.country}
                </p>
              </ListGroup.Item>
            )}

            <ListGroup.Item>
              <h2>{t('OrderScreen.Payment Method')}</h2>
              <strong>{t('OrderScreen.Method')}: </strong>
              {displayPayment(cart.paymentMethod, i18n.language)}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>{t('OrderScreen.Order Items')}</h2>
              {cart.cartItems.length === 0 ? (
                <Message>
                  <Trans i18nKey='CartScreen.Your cart is empty'>
                    Your cart is empty. <Link to='/'>Go Back</Link>
                  </Trans>
                </Message>
              ) : (
                <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x {t('currency')}{' '}
                          {currentCurrencyToLocale(item.price, i18n.language)} ={' '}
                          {t('currency')}{' '}
                          {currentCurrencyToLocale(
                            item.qty * item.price,
                            i18n.language
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>{t('OrderScreen.Order Summary')}</h2>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>{t('OrderScreen.Items')}</Col>
                <Col>
                  {t('currency')}{' '}
                  {currentCurrencyToLocale(cart.itemsPrice, i18n.language)}
                </Col>
              </Row>
            </ListGroup.Item>

            {/* <ListGroup.Item>
              <Row>
                <Col>{t('OrderScreen.ShippingPrice')}</Col>
                <Col>
                  {t('currency')}{' '}
                  {currentCurrencyToLocale(cart.shippingPrice, i18n.language)}
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item>
              <Row>
                <Col>{t('OrderScreen.Tax')}</Col>
                <Col>
                  {t('currency')}{' '}
                  {currentCurrencyToLocale(cart.taxPrice, i18n.language)}
                </Col>
              </Row>
            </ListGroup.Item> */}

            <ListGroup.Item>
              <Row>
                <Col>
                  <strong>{t('OrderScreen.Total')}</strong>
                </Col>
                <Col>
                  {t('currency')}{' '}
                  <strong>
                    {currentCurrencyToLocale(cart.totalPrice, i18n.language)}
                  </strong>
                </Col>
              </Row>
            </ListGroup.Item>

            {/* Promo code */}
            <ListGroup.Item>
              <Form className='d-flex' onSubmit={promoCodeHandler}>
                <Form.Control
                  type='text'
                  onChange={(e) => setPromoCode(e.target.value)}
                  placeholder={t('OrderScreen.Enter promo code')}
                />
                <Button type='submit'>{t('OrderScreen.Apply')}</Button>
              </Form>
              {loading ? (
                <Loader />
              ) : errorPromo ? (
                <Message variant='danger'>
                  {t('PlaceOrderScreen.Promo Not Found')}
                </Message>
              ) : null}

              {discount > 0 && (
                <Message variant='success'>
                  {t('PlaceOrderScreen.Your Discount') + ' ' + discount * 100}%
                </Message>
              )}
            </ListGroup.Item>

            <Button
              type='button'
              size='lg'
              className='btn-block rounded-0 my-3'
              disabled={cart.cartItems === 0}
              onClick={placeOrderHandler}
            >
              <strong>{t('PlaceOrderScreen.Place Order')}</strong>
            </Button>
          </ListGroup>
        </Col>
      </Row>
    </>
  )
}

export default PlaceOrderScreen
