import {
  DOWNLOAD_FAIL,
  DOWNLOAD_REQUEST,
  DOWNLOAD_RESET,
  DOWNLOAD_SUCCESS,
  MINT_FAIL,
  MINT_GASLESS_OPTION,
  MINT_REQUEST,
  MINT_RESET,
  MINT_SET_CONTRACT,
  MINT_SUCCESS,
} from '../constants/mintConstants'

export default function (state = { isGasless: false }, action) {
  const { type, payload } = action

  switch (type) {
    case MINT_SET_CONTRACT:
      return {
        ...state,
        contractAddress: payload.contractAddress,
        blockchain: payload.blockchain,
        item: payload.item,
        tokenId: payload.tokenId,
      }
    case MINT_REQUEST:
      return { ...state, minting: true }
    case MINT_SUCCESS:
      return { ...state, minting: false, minted: true }
    case MINT_FAIL:
      return { ...state, minting: false, errorMint: payload }
    case MINT_RESET:
      return { ...state, minting: false, minted: false }
    case DOWNLOAD_REQUEST:
      return { ...state, downloading: true }
    case DOWNLOAD_SUCCESS:
      return { ...state, downloading: false, downloaded: true }
    case DOWNLOAD_FAIL:
      return { ...state, downloading: false, errorDownload: payload }
    case DOWNLOAD_RESET:
      return { ...state, downloading: false, downloaded: false }
    case MINT_GASLESS_OPTION:
      return { ...state, isGasless: payload }
    default:
      return state
  }
}
