import {
  AUCTION_BID_CANCEL_FAIL,
  AUCTION_BID_CANCEL_REQUEST,
  AUCTION_BID_CANCEL_SUCCESS,
  AUCTION_BID_FAIL,
  AUCTION_BID_REQUEST,
  AUCTION_BID_SUCCESS,
  AUCTION_CLOSE_FAIL,
  AUCTION_CLOSE_REQUEST,
  AUCTION_CLOSE_SUCCESS,
  AUCTION_RESET,
} from '../constants/auctionConstants'

export const auctionReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case AUCTION_BID_REQUEST:
      return {
        ...state,
        loadingBid: true,
      }
    case AUCTION_BID_SUCCESS:
      return {
        ...state,
        loadingBid: false,
        successBid: true,
        auction: payload,
      }
    case AUCTION_BID_FAIL:
      return {
        ...state,
        loadingBid: false,
        errorBid: payload,
      }

    case AUCTION_CLOSE_REQUEST:
      return {
        ...state,
        loadingClose: true,
      }
    case AUCTION_CLOSE_SUCCESS:
      return {
        ...state,
        loadingClose: false,
        successClose: true,
        auction: payload,
      }
    case AUCTION_CLOSE_FAIL:
      return {
        ...state,
        loadingClose: false,
        errorClose: payload,
      }

    case AUCTION_BID_CANCEL_REQUEST:
      return {
        ...state,
        loadingCancel: true,
      }
    case AUCTION_BID_CANCEL_SUCCESS:
      return {
        ...state,
        loadingCancel: false,
        successCancel: true,
      }
    case AUCTION_BID_CANCEL_FAIL:
      return {
        ...state,
        loadingCancel: false,
        errorCancel: payload,
      }
    case AUCTION_RESET:
      return {}
    default:
      return state
  }
}
