import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import { setAlert } from '../actions/alertActions'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import { useTranslation } from 'react-i18next'
import { USER_REGISTER_RESET } from '../constants/userConstants'
import Meta from '../components/Meta'
import { GoogleLoginButton } from 'react-social-login-buttons'

const RegisterScreen = ({ location, history }) => {
  const { t, i18n } = useTranslation()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [referral, setReferral] = useState('')
  const [agreeTerms, setAgreeTerms] = useState(true)
  const [agreePromotion, setAgreePromotion] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  // const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userStatus, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userStatus && userStatus === 'PENDING') {
      history.push(`/verify?id=${userInfo.userId}`)
      dispatch({ type: USER_REGISTER_RESET })
      // history.push(redirect)
    }
  }, [history, userStatus, redirect])

  useEffect(() => {
    if (error && error !== '') {
      dispatch(setAlert(error, 'danger'))
    }
  }, [error])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      // setMessage('Passwords do not match')
      dispatch(setAlert(t('RegisterScreen.Passwords do not match'), 'danger'))
    } else {
      dispatch(
        register(name, email, password, agreePromotion, referral, i18n.language)
      )
    }
  }

  return (
    <FormContainer>
      <Meta title={t('RegisterScreen.Sign Up') + ' - nQivi'} />

      <h1>{t('RegisterScreen.Sign Up')}</h1>
      {loading && <Loader />}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='name' className='mb-3'>
          {/* <Form.Label>{t('RegisterScreen.Name')}</Form.Label> */}
          <Form.Control
            type='name'
            // autoFocus
            placeholder={t('RegisterScreen.Enter name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='email' className='mb-3'>
          {/* <Form.Label>{t('RegisterScreen.Email Address')}</Form.Label> */}
          <Form.Control
            type='email'
            placeholder={t('RegisterScreen.Enter email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password' className='mb-3'>
          {/* <Form.Label>{t('RegisterScreen.Password')}</Form.Label> */}
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              minLength='6'
              placeholder={t('RegisterScreen.Enter password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            ></Form.Control>
            <Button
              variant='outline-secondary'
              style={{
                backgroundColor: 'transparent',
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              <i className='fas fa-eye' />
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId='confirmPassword' className='mb-2'>
          {/* <Form.Label>{t('RegisterScreen.Confirm Password')}</Form.Label> */}
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              minLength='6'
              placeholder={t('RegisterScreen.Confirm Password')}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            ></Form.Control>
            <Button
              variant='outline-secondary'
              style={{
                backgroundColor: 'transparent',
              }}
              onClick={() => setShowPassword(!showPassword)}
            >
              <i className='fas fa-eye' />
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId='referral' className='mb-2'>
          <Form.Label>{t('RegisterScreen.Referral')}</Form.Label>
          <Form.Control
            type='text'
            placeholder={t('RegisterScreen.Enter referral')}
            value={referral}
            onChange={(e) => setReferral(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='agreeTerms'>
          <Form.Check
            type='checkbox'
            className='mt-1'
            label={t('RegisterScreen.agreeTerms')}
            checked={agreeTerms}
            onChange={(e) => setAgreeTerms(e.target.checked)}
          ></Form.Check>
        </Form.Group>

        <Form.Group controlId='agreePromotion'>
          <Form.Check
            type='checkbox'
            className='mt-1'
            label={t('RegisterScreen.agreePromotion')}
            checked={agreePromotion}
            onChange={(e) => setAgreePromotion(e.target.checked)}
          ></Form.Check>
        </Form.Group>

        <Button
          type='submit'
          variant='primary'
          className='my-3'
          disabled={!agreeTerms}
          style={{ minWidth: '160px' }}
        >
          {t('RegisterScreen.Register')}
        </Button>
      </Form>

      <GoogleLoginButton
        onClick={() => {
          window.location.href = `http://${window.location.hostname}/api/auth/google`
        }}
      >
        {t('RegisterScreen.Register with Google')}
      </GoogleLoginButton>

      <Row className='py-3' style={{ fontWeight: 'lighter' }}>
        <Col>
          {t('RegisterScreen.Have an account')}{' '}
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            {t('RegisterScreen.Login')}
          </Link>
        </Col>
        <Col>
          <Link to='/resendotp'>{t('RegisterScreen.Verify')}</Link>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default RegisterScreen
