import React, { useEffect, useState } from 'react'
import {
  Table,
  Button,
  Row,
  Col,
  ListGroup,
  Form,
  Image,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import { useTranslation, Trans } from 'react-i18next'
import { setAlert } from '../actions/alertActions'
import { createRequest } from '../actions/requestActions'
import { REQUEST_RESET } from '../constants/requestConstants'
import Meta from '../components/Meta'

const ProgramScreen = () => {
  const { t, i18n } = useTranslation()

  const profitLevels = [0, 10, 1000, 30000, 100000, 9999999]

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const request = useSelector((state) => state.request)
  const { errorCreate, successCreate } = request

  const dispatch = useDispatch()

  const [nameRef, setNameRef] = useState('')
  const [emailRef, setEmailRef] = useState('')
  const [messageRef, setMessageRef] = useState('')

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (userInfo) {
      setName(userInfo.name)
      setEmail(userInfo.email)
      setNameRef(userInfo.name)
      setEmailRef(userInfo.email)
    }
  }, [userInfo])

  useEffect(() => {
    if (successCreate) {
      dispatch(setAlert(t('ProgramScreen.Register success'), 'success'))
      dispatch({ type: REQUEST_RESET })
    }
  }, [successCreate])

  useEffect(() => {
    if (errorCreate) {
      dispatch(setAlert(errorCreate, 'danger'))
      dispatch({ type: REQUEST_RESET })
    }
  }, [errorCreate])

  const registerRef = (e) => {
    e.preventDefault()

    dispatch(
      createRequest(
        {
          user: userInfo?.id,
          name: nameRef,
          email: emailRef,
          type: 'ref',
          program: 'Affiliate',
          content: messageRef,
        },
        i18n.language
      )
    )
  }

  const registerCreator = (e) => {
    e.preventDefault()

    dispatch(
      createRequest(
        {
          user: userInfo?.id,
          name: name,
          email: email,
          type: 'creator',
          program: 'Creator',
          content: message,
        },
        i18n.language
      )
    )
  }

  return (
    <>
      <Meta title={t('Header.Program') + ' - nQivi'} />
      <Row className='mb-3'>
        <Row>
          <h1>{t('ProgramScreen.Affiliate')}</h1>
          <Col md={6}>
            <Image
              src='https://res.cloudinary.com/nqivi/image/upload/v1665633211/NFT_Shop/images/arm-wrestling-g8d746a6ca_640_o342kh.jpg'
              fluid
              rounded
              className='program-screen-image-ref1 mb-2'
            />
            <div style={{ fontWeight: 'lighter' }} className='me-3 mb-2'>
              <em>{t('ProgramScreen.Ref Program')}</em>
            </div>
            <Form onSubmit={registerRef}>
              {!userInfo && (
                <Form.Label style={{ fontWeight: 'lighter' }}>
                  <div className='ms-auto'>
                    <em>
                      <Trans i18nKey='ProgramScreen.Please login'>
                        Please <Link to='/login'>login</Link>
                      </Trans>
                    </em>
                  </div>
                </Form.Label>
              )}

              <Form.Group controlId='name' className='mb-2'>
                <Form.Label>{t('ProgramScreen.Name')}</Form.Label>
                <Form.Control
                  type='text'
                  value={nameRef}
                  onChange={(e) => setNameRef(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='email' className='mb-2'>
                <Form.Label>{t('ProgramScreen.Email')}</Form.Label>
                <Form.Control
                  type='email'
                  value={emailRef}
                  onChange={(e) => setEmailRef(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='message'>
                <Form.Label>{t('ProgramScreen.Introduction')}</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='5'
                  placeholder={t('ProgramScreen.Enter introduction')}
                  value={messageRef}
                  onChange={(e) => setMessageRef(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button
                type='submit'
                variant='primary'
                className='my-2'
                // disabled={!userInfo}
              >
                {t('ProgramScreen.Register')}
              </Button>
            </Form>
          </Col>
          <Col md={6} sm={0} xs={0} className='program-screen-image-ref2'>
            <Image
              src='https://res.cloudinary.com/nqivi/image/upload/v1665633211/NFT_Shop/images/arm-wrestling-g8d746a6ca_640_o342kh.jpg'
              fluid
              rounded
            />
          </Col>
        </Row>

        <ListGroup className='my-3'>
          <ListGroup.Item>
            <h5>{t('RefScreen.Level Up')}</h5>
            <span style={{ fontWeight: 'lighter' }}>
              <em>{t('ProfileScreen.Table Note')}</em>
            </span>
            <Table striped bordered hover responsive className='table-sm'>
              <thead>
                <tr>
                  <th></th>
                  <th>Lv1</th>
                  <th>Lv2</th>
                  <th>Lv3</th>
                  <th>Lv4</th>
                  <th>Lv5</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('RefScreen.From')}</td>
                  <td>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(profitLevels[0], i18n.language)}
                  </td>
                  <td>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(profitLevels[1], i18n.language)}
                  </td>
                  <td>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(profitLevels[2], i18n.language)}
                  </td>
                  <td>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(profitLevels[3], i18n.language)}
                  </td>
                  <td>
                    {t('currency')}{' '}
                    {currentCurrencyToLocale(profitLevels[4], i18n.language)}
                  </td>
                </tr>

                <tr>
                  <td>{t('RefScreen.Rate')}</td>
                  <td>10%</td>
                  <td>20%</td>
                  <td>30%</td>
                  <td>40%</td>
                  <td>45%</td>
                </tr>
              </tbody>
            </Table>
          </ListGroup.Item>
        </ListGroup>

        <Row>
          <Row>
            <Col md={6}></Col>
            <Col md={6}>
              <h1 className='ms-1'>{t('ProgramScreen.Creator')}</h1>
            </Col>
          </Row>
          <Col md={6} className='mb-2'>
            <Image
              src='https://res.cloudinary.com/nqivi/image/upload/v1665633204/NFT_Shop/images/art-g878a90f30_640_xk4vov.jpg'
              className='mt-1'
              fluid
              rounded
            />
          </Col>
          <Col md={6}>
            <div style={{ fontWeight: 'lighter' }} className='me-3 mb-2'>
              <em>{t('ProgramScreen.Creator Program')}</em>
            </div>
            <Form onSubmit={registerCreator}>
              <Form.Group controlId='name2' className='mb-2'>
                <Form.Label>{t('ProgramScreen.Name')}</Form.Label>
                <Form.Control
                  type='text'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='email2' className='mb-2'>
                <Form.Label>{t('ProgramScreen.Email')}</Form.Label>
                <Form.Control
                  type='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId='message2'>
                <Form.Label>{t('ProgramScreen.Introduction')}</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='5'
                  placeholder={t('ProgramScreen.Enter introduction')}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button
                type='submit'
                variant='primary'
                className='mt-2'
                // disabled={!userInfo}
              >
                {t('ProgramScreen.Register')}
              </Button>
            </Form>
          </Col>
        </Row>
      </Row>
    </>
  )
}

export default ProgramScreen
