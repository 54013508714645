export const PROMO_CREATE_REQUEST = 'PROMO_CREATE_REQUEST'
export const PROMO_CREATE_SUCCESS = 'PROMO_CREATE_SUCCESS'
export const PROMO_CREATE_FAIL = 'PROMO_CREATE_FAIL'

export const PROMO_GET_REQUEST = 'PROMO_GET_REQUEST'
export const PROMO_GET_SUCCESS = 'PROMO_GET_SUCCESS'
export const PROMO_GET_APPLIED = 'PROMO_GET_APPLIED'
export const PROMO_GET_FAIL = 'PROMO_GET_FAIL'

export const PROMO_UPDATE_REQUEST = 'PROMO_UPDATE_REQUEST'
export const PROMO_UPDATE_SUCCESS = 'PROMO_UPDATE_SUCCESS'
export const PROMO_UPDATE_FAIL = 'PROMO_UPDATE_FAIL'

export const PROMO_DELETE_REQUEST = 'PROMO_DELETE_REQUEST'
export const PROMO_DELETE_SUCCESS = 'PROMO_DELETE_SUCCESS'
export const PROMO_DELETE_FAIL = 'PROMO_DELETE_FAIL'

export const PROMO_RESET = 'PROMO_RESET'

export const PROMO_LIST_REQUEST = 'PROMO_LIST_REQUEST'
export const PROMO_LIST_SUCCESS = 'PROMO_LIST_SUCCESS'
export const PROMO_LIST_FAIL = 'PROMO_LIST_FAIL'

export const PROMO_SETACTIVE_REQUEST = 'PROMO_SETACTIVE_REQUEST'
export const PROMO_SETACTIVE_SUCCESS = 'PROMO_SETACTIVE_SUCCESS'
export const PROMO_SETACTIVE_FAIL = 'PROMO_SETACTIVE_FAIL'
