import { Button, Image } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { voteProduct } from '../actions/productActions'
import { FaRegThumbsUp } from 'react-icons/fa'

const VotingProduct = ({ product }) => {
  const dispatch = useDispatch()

  const totalVotes = product.voting.votingVariants.reduce(
    (acc, vote) => acc + vote.votingCount,
    0
  )

  const voteHandler = (variantId) => {
    dispatch(voteProduct(product._id, variantId))
  }

  return (
    <div className='d-flex'>
      <div
        className='left'
        style={{
          backgroundColor: 'red',
          position: 'relative',
          width: `${
            (totalVotes == 0
              ? 0.5
              : product.voting.votingVariants[0].votingCount / totalVotes) * 100
          }%`,
          height: '30px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '5px',
            zIndex: '2',
          }}
        >
          <Button
            variant='secondary'
            className='btn-sm'
            onClick={() =>
              voteHandler(product.voting.votingVariants[0].votingVariant)
            }
          >
            {product.voting.votingVariants[0].votingCount + ' '}
            <FaRegThumbsUp />
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '-20px',
            right: '-35px',
            zIndex: '1',
          }}
        >
          <Image
            src='https://res.cloudinary.com/nqivi/image/upload/v1663748909/NFT_Shop/images/vs-250px_wjfyvq.png'
            style={{ width: '70px', height: '70px' }}
          />
        </div>
      </div>

      <div
        className='right'
        style={{
          backgroundColor: 'blue',
          position: 'relative',
          width: `${
            (totalVotes == 0
              ? 0.5
              : product.voting.votingVariants[1].votingCount / totalVotes) * 100
          }%`,
          height: '30px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: '5px',
            zIndex: '2',
          }}
        >
          <Button
            variant='secondary'
            className='btn-sm'
            onClick={() =>
              voteHandler(product.voting.votingVariants[1].votingVariant)
            }
          >
            {product.voting.votingVariants[1].votingCount + ' '}
            <FaRegThumbsUp />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VotingProduct
