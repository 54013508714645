import { REF_SAVE } from '../constants/refConstants'

export default function (state = {}, action) {
  const { type, payload } = action

  switch (type) {
    case REF_SAVE:
      return { ref: payload }
    default:
      return state
  }
}
