import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const TrackingOrder = () => {
  const [orderId, setOrderId] = useState('')
  const { t } = useTranslation()
  const history = useHistory()

  const submitHandler = (e) => {
    e.preventDefault()
    // console.log(history)
    if (orderId.trim()) {
      history.push(`/order/${orderId.trim()}`)
    }
  }
  return (
    <Form
      onSubmit={submitHandler}
      className='d-flex'
      style={{ height: '60px' }}
    >
      <Form.Control
        type='text'
        name='q'
        onChange={(e) => setOrderId(e.target.value)}
        placeholder={t('TrackingOrder.Search Orders')}
        className='mr-sm-2 ml-sm-5'
      ></Form.Control>
      <Button
        type='submit'
        variant='outline-success'
        className='p-2 fs-5'
        style={{ width: '60px' }}
      >
        <i className='fas fa-magnifying-glass'></i>
      </Button>
    </Form>
  )
}

export default TrackingOrder
