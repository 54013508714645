import axios from 'axios'
import i18n from 'i18next'
import {
  REQUEST_CREATE_FAIL,
  REQUEST_CREATE_REQUEST,
  REQUEST_CREATE_SUCCESS,
  REQUEST_GET_ALL_FAIL,
  REQUEST_GET_ALL_REQUEST,
  REQUEST_GET_ALL_SUCCESS,
  REQUEST_GET_MY_FAIL,
  REQUEST_GET_MY_REQUEST,
  REQUEST_GET_MY_SUCCESS,
  REQUEST_GET_TYPE_REQUEST,
  REQUEST_GET_TYPE_SUCCESS,
  REQUEST_GET_TYPE_FAIL,
  REQUEST_GET_REQUEST,
  REQUEST_GET_SUCCESS,
  REQUEST_GET_FAIL,
  REQUEST_DELETE_REQUEST,
  REQUEST_DELETE_SUCCESS,
  REQUEST_DELETE_FAIL,
  REQUEST_UPDATE_REQUEST,
  REQUEST_UPDATE_SUCCESS,
  REQUEST_UPDATE_FAIL,
} from '../constants/requestConstants'

export const createRequest = (request, lang) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    if (!request) {
      request = {
        user: userInfo?.id,
        name: userInfo?.name,
        email: userInfo?.email,
        type: 'ref',
        program: 'Affiliate',
        content: 'Register Affiliate',
      }
    }

    const { data } = await axios.post(
      `/api/requests?lang=${lang}`,
      request,
      config
    )

    dispatch({
      type: REQUEST_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: REQUEST_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getRequests = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_GET_ALL_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.get(
      `/api/requests?pageNumber=${pageNumber}`,
      config
    )

    dispatch({
      type: REQUEST_GET_ALL_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: REQUEST_GET_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserRequests =
  (userId, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST_GET_MY_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const { data } = await axios.get(
        `/api/requests/user/${userId}?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: REQUEST_GET_MY_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: REQUEST_GET_MY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getRequestsByType =
  (type, pageNumber) => async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST_GET_TYPE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      const { data } = await axios.get(
        `/api/requests/type/${type}?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: REQUEST_GET_TYPE_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: REQUEST_GET_TYPE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getRequest = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_GET_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.get(`/api/requests/${id}`, config)

    dispatch({
      type: REQUEST_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: REQUEST_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteRequest = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.delete(`/api/requests/${id}`, config)

    dispatch({
      type: REQUEST_DELETE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: REQUEST_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateRequest = (id, request) => async (dispatch, getState) => {
  try {
    dispatch({
      type: REQUEST_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.put(`/api/requests/${id}`, request, config)

    dispatch({
      type: REQUEST_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: REQUEST_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
