import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import Message from '../components/Message'
import { setAlert } from '../actions/alertActions'
import Loader from '../components/Loader'
import { loginOauth } from '../actions/userActions'
import { useTranslation } from 'react-i18next'

const LoginOAuthScreen = ({ location, history }) => {
  const { t, i18n } = useTranslation()

  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const id = params.get('id')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  useEffect(() => {
    dispatch(loginOauth(token, i18n.language))
  }, [])

  useEffect(() => {
    if (userInfo) {
      history.push('/')
    }
  }, [userInfo])

  useEffect(() => {
    if (error && error !== '') {
      dispatch(setAlert(error, 'danger'))
    }
  }, [error])

  return <Loader />
}

export default LoginOAuthScreen
