export const TRANSACTION_CREATE_REQUEST = 'TRANSACTION_CREATE_REQUEST'
export const TRANSACTION_CREATE_SUCCESS = 'TRANSACTION_CREATE_SUCCESS'
export const TRANSACTION_CREATE_FAIL = 'TRANSACTION_CREATE_FAIL'

export const TRANSACTION_UPDATE_REQUEST = 'TRANSACTION_UPDATE_REQUEST'
export const TRANSACTION_UPDATE_SUCCESS = 'TRANSACTION_UPDATE_SUCCESS'
export const TRANSACTION_UPDATE_FAIL = 'TRANSACTION_UPDATE_FAIL'

export const TRANSACTION_GET_ALL_REQUEST = 'TRANSACTION_GET_ALL_REQUEST'
export const TRANSACTION_GET_ALL_SUCCESS = 'TRANSACTION_GET_ALL_SUCCESS'
export const TRANSACTION_GET_ALL_FAIL = 'TRANSACTION_GET_ALL_FAIL'

export const TRANSACTION_GET_USER_REQUEST = 'TRANSACTION_GET_USER_REQUEST'
export const TRANSACTION_GET_USER_SUCCESS = 'TRANSACTION_GET_USER_SUCCESS'
export const TRANSACTION_GET_USER_FAIL = 'TRANSACTION_GET_USER_FAIL'

export const TRANSACTION_GET_REQUEST = 'TRANSACTION_GET_REQUEST'
export const TRANSACTION_GET_SUCCESS = 'TRANSACTION_GET_SUCCESS'
export const TRANSACTION_GET_FAIL = 'TRANSACTION_GET_FAIL'

export const TRANSACTION_DELETE_REQUEST = 'TRANSACTION_DELETE_REQUEST'
export const TRANSACTION_DELETE_SUCCESS = 'TRANSACTION_DELETE_SUCCESS'
export const TRANSACTION_DELETE_FAIL = 'TRANSACTION_DELETE_FAIL'
