// thirdweb credit card payment: https://portal.thirdweb.com/guides/accept-credit-card-payments

import { useState, useEffect } from 'react'
import { Form, Button, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { savePaymentMethod } from '../actions/cartActions'
import { useTranslation } from 'react-i18next'
import Meta from '../components/Meta'

const PaymentScreen = ({ history }) => {
  const { t, i18n } = useTranslation()

  // const cart = useSelector((state) => state.cart)
  // const { shippingAddress } = cart

  // if (!shippingAddress) {
  //   history.push('/shipping')
  // }

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [paymentMethod, setPaymentMethod] = useState(
    i18n.language === 'en' ? 'PayPal' : 'ATM_ONLINE'
  )

  useEffect(() => {
    setPaymentMethod(i18n.language === 'en' ? 'PayPal' : 'ATM_ONLINE')
  }, [i18n.language])

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(savePaymentMethod(paymentMethod))
    history.push('/placeorder')
  }

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 step3 />

      <Meta title={t('PaymentScreen.Payment Method') + ' - nQivi'} />

      <h1>{t('PaymentScreen.Payment Method')}</h1>
      <Form onSubmit={submitHandler}>
        <Form.Group>
          <Form.Label as='legend'>
            {t('PaymentScreen.Select Method')}
          </Form.Label>

          <Col>
            {window.location.hostname.includes('.com') && (
              // {i18n.language === 'en' && (
              <>
                <Form.Check
                  type='radio'
                  label={t('PaymentScreen.Paypal or Credit Card')}
                  id='PayPal'
                  name='paymentMethod'
                  value='PayPal'
                  defaultChecked={paymentMethod === 'PayPal'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
                <Form.Check
                  type='radio'
                  label={t('PaymentScreen.Crypto')}
                  id='crypto'
                  name='paymentMethod'
                  value='Crypto'
                  defaultChecked={paymentMethod === 'Crypto'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
              </>
            )}

            {userInfo && userInfo?.credits > 0 && (
              <Form.Check
                type='radio'
                label={t('PaymentScreen.Topup')}
                id='Topup'
                name='paymentMethod'
                value='Topup'
                defaultChecked={paymentMethod === 'Topup'}
                onChange={(e) => setPaymentMethod(e.target.value)}
              ></Form.Check>
            )}

            {window.location.hostname.includes('.vn') && (
              <>
                {/* VietQR */}
                {/* <Form.Check
                  type='radio'
                  label={t('PaymentScreen.Bank Transfer')}
                  id='VietQr'
                  name='paymentMethod'
                  value='Bank Transfer'
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check> */}

                <Form.Check
                  type='radio'
                  label={t('PaymentScreen.Visa')}
                  id='Visa'
                  name='paymentMethod'
                  value='VISA'
                  defaultChecked={paymentMethod === 'VISA'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>

                <Form.Check
                  type='radio'
                  label={t('PaymentScreen.ATM Online')}
                  id='Atmonline'
                  name='paymentMethod'
                  value='ATM_ONLINE'
                  defaultChecked={paymentMethod === 'ATM_ONLINE'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>

                <Form.Check
                  type='radio'
                  label={t('PaymentScreen.Internet Banking')}
                  id='Ib'
                  name='paymentMethod'
                  value='IB_ONLINE'
                  defaultChecked={paymentMethod === 'IB_ONLINE'}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>

                {/* <Form.Check
                  type='radio'
                  label='QRCODE'
                  id='qrcode'
                  name='paymentMethod'
                  value='QRCODE'
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check>
                <Form.Check
                  type='radio'
                  label='QRCODE247'
                  id='qrcode247'
                  name='paymentMethod'
                  value='QRCODE247'
                  onChange={(e) => setPaymentMethod(e.target.value)}
                ></Form.Check> */}
              </>
            )}
          </Col>
        </Form.Group>
        <Button type='submit' variant='primary' className='my-2'>
          {t('PaymentScreen.Continue')}
        </Button>
      </Form>
    </FormContainer>
  )
}

export default PaymentScreen
