import {
  CONTRACT_CREATE_FAIL,
  CONTRACT_CREATE_REQUEST,
  CONTRACT_CREATE_SUCCESS,
  CONTRACT_DELETE_FAIL,
  CONTRACT_DELETE_REQUEST,
  CONTRACT_DELETE_SUCCESS,
  CONTRACT_GET_ALL_FAIL,
  CONTRACT_GET_ALL_REQUEST,
  CONTRACT_GET_ALL_SUCCESS,
  CONTRACT_GET_FAIL,
  CONTRACT_GET_MY_FAIL,
  CONTRACT_GET_MY_REQUEST,
  CONTRACT_GET_MY_SUCCESS,
  CONTRACT_GET_REQUEST,
  CONTRACT_GET_SUCCESS,
  CONTRACT_UPDATE_FAIL,
  CONTRACT_UPDATE_REQUEST,
  CONTRACT_UPDATE_SUCCESS,
  CONTRACT_UPDATE_RESET,
  CONTRACT_UPDATE_LIKE_FAIL,
  CONTRACT_UPDATE_LIKE_SUCCESS,
  CONTRACT_DELETE_RESET,
} from '../constants/contractConstants'

export const contractReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case CONTRACT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CONTRACT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        contract: payload,
      }
    case CONTRACT_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case CONTRACT_GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CONTRACT_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        contracts: payload.contracts,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case CONTRACT_GET_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case CONTRACT_GET_MY_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CONTRACT_GET_MY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        contracts: payload.contracts,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case CONTRACT_GET_MY_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case CONTRACT_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case CONTRACT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        contract: payload,
      }
    case CONTRACT_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case CONTRACT_UPDATE_REQUEST:
      return {
        ...state,
        loadingUpdate: true,
      }
    case CONTRACT_UPDATE_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        successUpdate: true,
        contract: payload,
      }
    case CONTRACT_UPDATE_FAIL:
      return {
        ...state,
        loadingUpdate: false,
        errorUpdate: payload,
      }
    case CONTRACT_UPDATE_RESET:
      return {
        ...state,
        loadingUpdate: false,
        successUpdate: false,
        errorUpdate: null,
        contract: null,
      }

    case CONTRACT_DELETE_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      }
    case CONTRACT_DELETE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      }
    case CONTRACT_DELETE_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: payload,
      }
    case CONTRACT_DELETE_RESET:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: null,
        successDelete: false,
      }

    case CONTRACT_UPDATE_LIKE_SUCCESS:
      return {
        ...state,
        successLike: true,
      }
    case CONTRACT_UPDATE_LIKE_FAIL:
      return {
        ...state,
        successLike: false,
        errorLike: payload,
      }
    default:
      return state
  }
}
