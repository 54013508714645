// https://graph.facebook.com/?id={your-url}&fields=engagement&access_token={your-token}
// This new long-lived access token will expire on September 2, 2022:

// Cach lay token long-lived
// https://blog.tomosia.com/access-token-never-expire-facebook/
// https://developers.facebook.com/tools/explorer

// FB token Expiration: 27/1/2023

// Countdown animation
// https://www.npmjs.com/package/react-native-countdown-circle-timer
// https://www.npmjs.com/package/react-countdown

// Countdown Timer
// https://www.npmjs.com/package/@leenguyen/react-flip-clock-countdown

import React, { useState, useEffect, useRef, lazy } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  Row,
  Col,
  Image,
  Badge,
  ListGroup,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  Accordion,
  InputGroup,
} from 'react-bootstrap'
import Rating from '../components/Rating'
// import Like from '../components/Like'
import View from '../components/View'
import WishList from '../components/WishList'
import Moment from 'react-moment'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'

// // Product Swiper
// import ProductImagesSlider from '../components/ProductImagesSlider'
// import 'swiper/css'
// import 'swiper/css/navigation'
// import 'swiper/css/thumbs'

import {
  listProductDetails,
  createProductReview,
  showProductReview,
  hideProductReview,
  closeAuctionByTime,
  newBidProduct,
  cancelBidProduct,
  addToGroupProduct,
  editGroupProductItem,
  approveGroupProduct,
  removeImageGroupProduct,
  addGroupBuyOrder,
  closeGroupBuy,
} from '../actions/productActions'
import { createOrder } from '../actions/orderActions'
import { getUpdatedUserInfo } from '../actions/userActions'
import { getCouponCode } from '../actions/promoActions'
import { addToCart, saveCoupon } from '../actions/cartActions'
// import { saveRef } from '../actions/refActions'
import {
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_GROUPART_RESET,
  PRODUCT_GROUPBUY_RESET,
  PRODUCT_VOTE_RESET,
  PRODUCT_DETAILS_RESET,
} from '../constants/productConstants'
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import { AUCTION_RESET } from '../constants/auctionConstants'
import { PROMO_GET_APPLIED } from '../constants/promoConstants'
import currentCurrencyToLocale from '../utils/currentCurrencyToLocale'
import { Trans, useTranslation } from 'react-i18next'
import payments from '../assets/payments.png'
import nganluong from '../assets/nganluong.png'

import {
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'
// import { FacebookShareCount, RedditShareCount } from 'react-share'

// import MiniGameWheel from '../components/MiniGameWheel'
import ProductCombo from '../components/ProductCombo'
import ProductsUpSale from '../components/ProductsUpSale'
import ProductsRelated from '../components/ProductsRelated'
import VotingProduct from '../components/VotingProduct'
import VotingProductImages from '../components/VotingProductImages'
import PromotionBox from '../components/PromotionBox'
import Popup from '../components/Popup'
import { setAlert } from '../actions/alertActions'
// import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown'
import '@leenguyen/react-flip-clock-countdown/dist/index.css'
import convertCurrency from '../utils/convertCurrency'
import arrayToString from '../utils/arrayToString'
import LazyLoad from 'react-lazyload'

// Lazy load
const Gallery = lazy(() => import('../components/3D/Gallery'))
const Launching = lazy(() => import('../components/3D/Launching'))
const FlipClockCountdown = lazy(() =>
  import('@leenguyen/react-flip-clock-countdown')
)
const MiniGameWheel = lazy(() => import('../components/MiniGameWheel'))
// import { Cloudinary } from '@cloudinary/url-gen'

// // Create your instance
// const cloudinary = new Cloudinary({
//   cloud: {
//     cloudName: 'nqivi',
//   },
//   url: {
//     secure: true, // force https, set to false to force http
//   },
// })

let callCloseGroupBuy = false

const OVERLAYS = ['NFT_Shop:Partners:logo-small-trans-60'] // Replace / with : for overlay public ids

const getPublicId = (url) => {
  const temp = url.split('upload/')[1]
  const result = temp.slice(temp.indexOf('/') + 1, temp.lastIndexOf('.'))
  return result
}

const ProductScreen = ({ match, history, location }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()

  const productId = match?.params?.id
  // console.log(
  //   'get public id: ',
  //   getPublicId(
  //     'https://res.cloudinary.com/nqivi/image/upload/v1660708060/NFT_Shop/Partners/logo.small.trans-60.png'
  //   )
  // )

  const [isProductBuyer, setIsProductBuyer] = useState(false)

  const groupArtContainer = useRef()

  const params = new URLSearchParams(location.search)
  const video = params.get('v')
  const platform = params.get('s')
  const page = params.get('pg')
  const coupon = params.get('coupon')

  const [couponValue, setCouponValue] = useState(0)
  const [image, setImage] = useState()
  const [artFile, setArtFile] = useState()
  const [artName, setArtName] = useState()
  const [artPrice, setArtPrice] = useState()
  const [artDesc, setArtDesc] = useState()
  const [selectedVariant, setSelectedVariant] = useState()
  const [selectedVariantObj, setSelectedVariantObj] = useState()
  const [callCloseAuction, setCallCloseAuction] = useState(false)
  // const [callCloseGroupBuy, setCallCloseGroupBuy] = useState(false)
  const [copyright, setCopyright] = useState(true)
  const [uploading, setUploading] = useState()
  const [promoPrice, setPromoPrice] = useState(-1)
  const [bid, setBid] = useState()
  const [shareFB, setShareFB] = useState(0)
  const [qty, setQty] = useState(1)
  const [rating, setRating] = useState(5)
  const [comment, setComment] = useState('')
  const [productImage, setProductImage] = useState('')
  const [wheelPopup, setWheelPopup] = useState(false)
  const [wheelPopupCount, setWheelPopupCount] = useState(1)

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product, successVote } = productDetails

  const productGroupArt = useSelector((state) => state.productGroupArt)
  const {
    successAddGroupArt,
    successEditGroupArt,
    successApproveGroupArt,
    successDeleteGroupArt,
    loadingGroupArt,
    errorGroupArt,
  } = productGroupArt

  const { cartItems } = useSelector((state) => state.cart)

  const auctionDetails = useSelector((state) => state.auctionDetails)
  const { successBid, successCancel, successClose, errorBid } = auctionDetails

  const productReview = useSelector((state) => state.productReview)
  const { success: successProductReview, error: errorProductReview } =
    productReview

  const { ref } = useSelector((state) => state.ref)

  const promo = useSelector((state) => state.promo)
  const {
    loading: loadingPromo,
    error: errorPromo,
    promoInfo,
    successGet,
  } = promo

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderCreate = useSelector((state) => state.orderCreate)
  const { order: orderGroup } = orderCreate

  const productGroupBuy = useSelector((state) => state.productGroupBuy)
  const {
    successAdd: successAddGroupBuy,
    successClose: successCloseGroupBuy,
    error: errorGroupBuy,
  } = productGroupBuy

  useEffect(() => {
    if (
      promoInfo &&
      promoInfo?.discount > 0 &&
      promoInfo?.qty > 0 &&
      promoInfo?.isActive
    ) {
      dispatch(
        saveCoupon({
          promoId: promoInfo.promoId,
          discount: promoInfo.discount,
        })
      )
    }
  }, [promoInfo])

  useEffect(() => {
    if (orderGroup) {
      dispatch(addGroupBuyOrder(productId, orderGroup))
    }
  }, [orderGroup])

  useEffect(() => {
    if (orderGroup) {
      dispatch(addGroupBuyOrder(productId, orderGroup))
    }
  }, [orderGroup])

  useEffect(() => {
    if (successVote) {
      // Find winner
      let winner
      let maxVote = 0
      product.voting.votingVariants.forEach((item) => {
        if (item.votingCount > maxVote) {
          maxVote = item.votingCount
          winner = item.votingVariant
        }
      })
      const obj = product?.variants.find((v) => v._id.toString() === winner)
      // console.log('winner: ', obj)
      setSelectedVariantObj(obj)
      setSelectedVariant(winner)
      // setProductImage(obj?.image)

      dispatch({ type: PRODUCT_VOTE_RESET })
    }
  }, [successVote])

  useEffect(() => {
    if (successAddGroupBuy || successCloseGroupBuy) {
      if (userInfo) {
        dispatch(getUpdatedUserInfo(userInfo.id))
        dispatch({ type: PRODUCT_GROUPBUY_RESET })
      }
      if (successAddGroupBuy && orderGroup) {
        history.push(`/order/${orderGroup._id}`)
        dispatch({ type: ORDER_CREATE_RESET })
      }
    }
  }, [successAddGroupBuy, successCloseGroupBuy])

  const getFBShareCount = async (url) => {
    const { data } = await axios.get(`/api/products/${productId}/promotion`, {
      headers: {
        'Accept-Language': i18n.language,
      },
    })
    // console.log('getFBShareCount: ', data)
    setShareFB(data.shareCount)
    setPromoPrice(data.promotionPrice)
  }

  useEffect(() => {
    dispatch({ type: PRODUCT_DETAILS_RESET })

    dispatch(listProductDetails(productId))

    if (localStorage.getItem('wheelPopupCount')) {
      setWheelPopupCount(Number(localStorage.getItem('wheelPopupCount')))
    } else {
      localStorage.setItem('wheelPopupCount', 1)
    }

    getFBShareCount(
      window.location.href.split('?')[0]
      // 'https://vnexpress.net/gia-xang-dau-tang-ngan-sach-tang-thu-hon-9-000-ty-dong-4483671.html'
    )

    if (coupon) {
      dispatch(getCouponCode(coupon))
    }

    // Check if user is the product buyer
    const myOrders = JSON.parse(localStorage.getItem('myOrders'))

    const didBuyProduct =
      myOrders &&
      myOrders.some((order) => {
        return (
          order.orderItems.map((item) => item.product).includes(productId) &&
          order.isPaid
        )
      })
    setIsProductBuyer(didBuyProduct)
  }, [])

  useEffect(() => {
    if (errorBid && errorBid !== '') {
      dispatch(setAlert(errorBid, 'danger'))
    }
  }, [errorBid])

  // // Detect if user is leaving => turn on mini game
  // useEffect(() => {
  //   const mouseleave = (event) => {
  //     if (
  //       event.clientY <= 0 ||
  //       event.clientX <= 0 ||
  //       event.clientX >= window.innerWidth ||
  //       event.clientY >= window.innerHeight
  //     ) {
  //       setWheelPopup(true)
  //       localStorage.setItem('wheelPopupCount', wheelPopupCount - 1)
  //       // console.log('wheelPopupCount: ', wheelPopupCount - 1)
  //       setWheelPopupCount((prevCount) => prevCount - 1)
  //     }
  //   }

  //   document.addEventListener('mouseleave', mouseleave)

  //   if (wheelPopupCount <= 0) {
  //     document.removeEventListener('mouseleave', mouseleave)
  //   }

  //   return () => {
  //     document.removeEventListener('mouseleave', mouseleave)
  //   }
  // }, [wheelPopupCount])

  useEffect(() => {
    if (
      successAddGroupArt ||
      successEditGroupArt ||
      successApproveGroupArt ||
      successDeleteGroupArt
    ) {
      if (successAddGroupArt) {
        dispatch(setAlert(t('ProductScreen.Group art added'), 'success'))
      }
      dispatch(listProductDetails(productId))
      setImage('')
      dispatch({ type: PRODUCT_GROUPART_RESET })
    } else if (successBid || successCancel || successClose) {
      dispatch(listProductDetails(productId))
      if (userInfo) {
        dispatch(getUpdatedUserInfo(userInfo.id))
      }
      dispatch({ type: AUCTION_RESET })
    } else if (successProductReview) {
      // alert('Review Submitted')
      dispatch(setAlert(t('ProductScreen.Review Submitted'), 'success'))
      setRating(0)
      setComment('')
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
    } else if (product?.images && !successVote) {
      setProductImage(product?.images[0])
    }
    // else {
    //   dispatch(listProductDetails(productId))
    // }
  }, [
    dispatch,
    productId,
    successProductReview,
    product,
    successBid,
    successCancel,
    successClose,
    successAddGroupArt,
    successEditGroupArt,
    successApproveGroupArt,
    successDeleteGroupArt,
  ])

  // useEffect(() => {
  //   console.log('successAddGroupArt || successApproveGroupArt || successDeleteGroupArt')
  //   if (successAddGroupArt) {
  //     dispatch(listProductDetails(productId))
  //     setImage('')
  //     // dispatch({ type: PRODUCT_GROUPART_RESET })
  //   }
  // }, [successAddGroupArt])

  useEffect(() => {
    // Choose variant for membership products
    if (product?.type === 'membership' && product?.variants?.length > 0) {
      setSelectedVariant(product?.variants[0]._id)
      setSelectedVariantObj(product?.variants[0])
    }

    // Close auction
    if (
      product &&
      !callCloseAuction &&
      product?.isAuction &&
      product?.auction?.isActive &&
      new Date(product?.auction?.endTime) < new Date()
    ) {
      auctionCompletedHandler()
    }

    // Close promotion
    if (
      product &&
      !callCloseGroupBuy &&
      product?.promotion?.promoType === 'groupbuy' &&
      product?.promotion?.isActive &&
      new Date(product?.promotion?.endTime) < new Date()
    ) {
      groupBuyCompletedHandler()
    }

    // Close voting
    if (
      product &&
      product?.type === 'voting' &&
      product?.voting?.votingEndTime &&
      new Date(product?.voting?.votingEndTime) < new Date()
    ) {
      const winner = getMaxFromArray(product?.voting?.votingVariants)
      setSelectedVariant(winner.votingVariant)
      const temp = product?.variants.find(
        (v) => v._id.toString() === winner.votingVariant
      )
      setSelectedVariantObj(temp)
      setProductImage(temp?.image)
    }
  }, [product])

  const addToCartHandler = () => {
    if (!userInfo && product?.membership) {
      dispatch(setAlert(t('ProductScreen.Please login to continue'), 'danger'))
      history.push(`/login?redirect=/product/${productId}`)
    }

    if (selectedVariantObj) {
      if (
        cartItems.some((item) => item.product === productId) ||
        cartItems?.length === 0
      ) {
        history.push(
          `/cart/${productId}?qty=${qty}&variant=${selectedVariant}&promo=${
            coupon && promoInfo?.isActive && promoInfo?.qty > 0
              ? parseInt(promoInfo?.discount * 100)
              : ''
          }`
        )
      } else {
        dispatch(
          addToCart({
            id: productId,
            qty,
            variantId: selectedVariant,
            promo:
              coupon && promoInfo?.isActive && promoInfo?.qty > 0
                ? promoInfo.discount * 100
                : null,
          })
        )
      }

      if (coupon && promoInfo?.isActive && promoInfo?.qty > 0) {
        dispatch({ type: PROMO_GET_APPLIED, payload: coupon })
      }

      dispatch(
        setAlert(
          t('ProductScreen.Added to cart') + `: ${selectedVariantObj.name}`,
          'success'
          // 3000
        )
      )
    } else {
      if (
        cartItems.some((item) => item.product === productId) ||
        cartItems?.length === 0
      ) {
        history.push(
          `/cart/${productId}?qty=${qty}&promo=${
            coupon && promoInfo?.isActive && promoInfo?.qty > 0
              ? parseInt(promoInfo?.discount * 100)
              : ''
          }`
        )
      } else {
        dispatch(
          addToCart({
            id: productId,
            qty,
            promo:
              coupon && promoInfo?.isActive && promoInfo?.qty > 0
                ? promoInfo.discount * 100
                : null,
          })
        )
      }

      if (coupon && promoInfo?.isActive && promoInfo?.qty > 0) {
        dispatch({ type: PROMO_GET_APPLIED, payload: coupon })
      }

      dispatch(
        setAlert(
          t('ProductScreen.Added to cart') + `: ${product.name}`,
          'success'
          // 3000
        )
      )
    }
  }

  const buyGroupHandler = () => {
    if (userInfo?.credits < product?.promotion?.promoPrice) {
      dispatch(setAlert(t('ProductScreen.Not enough credits'), 'danger'))
      return
    }

    if (selectedVariantObj) {
      dispatch(
        createOrder(
          {
            user: userInfo.id,
            orderItems: [
              {
                product: product._id,
                productLink: product?.productLink,
                variant: selectedVariantObj._id,
                name: selectedVariantObj.name,
                image: selectedVariantObj?.image,
                price:
                  product?.promotion && product?.promotion.promoQuantity > 0
                    ? product?.promotion.promoPrice
                    : selectedVariantObj.price,
                countInStock: selectedVariantObj.quantity,
                qty,

                isDigital: product.isDigital,
                isPhysical: product.isPhysical,
                isMintable: product?.isMintable,
                type: product?.type,
                downloadUrl: selectedVariantObj.downloadUrl,
                contractAddress: product.contractAddress,
                blockchain: product.blockchain,
                mediaUrl: product?.mediaUrl,
                tokenId: product?.tokenId,
                tokenDesc: product?.tokenDesc,
              },
            ],
            paymentMethod: 'GROUP_BUY',
            itemsPrice: product?.promotion?.promoPrice * qty,
            totalPrice: product?.promotion?.promoPrice * qty,
            ref: ref,
          },
          i18n.language
        )
      )
    } else {
      dispatch(
        createOrder(
          {
            user: userInfo.id,
            orderItems: [
              {
                product: product._id,
                productLink: product?.productLink,
                // variant: variant ? variant._id : null,
                name: product.name,
                image: product?.images[0] || product?.image,
                price:
                  product?.promotion && product?.promotion.promoQuantity > 0
                    ? product?.promotion.promoPrice
                    : product.price,
                countInStock: product.countInStock,
                qty,

                isDigital: product.isDigital,
                isPhysical: product.isPhysical,
                isMintable: product?.isMintable,
                type: product?.type,
                downloadUrl: product.downloadUrl,
                contractAddress: product.contractAddress,
                blockchain: product.blockchain,
                mediaUrl: product?.mediaUrl,
                tokenId: product?.tokenId,
                tokenDesc: product?.tokenDesc,
              },
            ],
            paymentMethod: 'GROUP_BUY',
            itemsPrice: product?.promotion?.promoPrice * qty,
            totalPrice: product?.promotion?.promoPrice * qty,
            ref: ref,
          },
          i18n.language
        )
      )
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview(productId, {
        rating,
        comment,
      })
    )
  }

  const uploadFileHandler = async (e) => {
    try {
      const file = e.target.files[0]

      const formData = new FormData()
      formData.append('image', file)

      setUploading(true)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
          folder: `NFT_Shop/Downloads/Group_Art/${productId}`,
        },
      }

      const { data } = await axios.post(`/api/upload`, formData, config)
      // console.log('data: ', data)
      setImage(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const uploadCloudinaryHandler = async (file, path) => {
    try {
      const formData = new FormData()
      formData.append('image', file)

      setUploading(true)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
          folder: `NFT_Shop${path}`,
        },
      }

      const { data } = await axios.post(`/api/upload`, formData, config)
      setUploading(false)
      return data
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const buyNowHandler = (e) => {
    e.preventDefault()
    dispatch(
      newBidProduct(
        productId,
        i18n.language === 'en'
          ? product?.auction?.buyoutPrice
          : convertCurrency(product?.auction?.buyoutPrice, 'vi', 'en'),
        ref
      )
    )
  }

  const bidHandler = (e) => {
    e.preventDefault()
    if (bid) {
      dispatch(
        newBidProduct(
          productId,
          i18n.language === 'en' ? bid : convertCurrency(bid, 'vi', 'en'),
          ref
        )
      )
    }
  }

  const cancelBidHandler = (e) => {
    e.preventDefault()
    dispatch(cancelBidProduct(productId))
  }

  const groupBuyCompletedHandler = () => {
    if (!callCloseGroupBuy) {
      dispatch(closeGroupBuy(product._id))
      callCloseGroupBuy = true
    }
  }

  const auctionCompletedHandler = () => {
    dispatch(closeAuctionByTime(productId))
    setCallCloseAuction(true)
  }

  const addArt = async (e) => {
    try {
      e.preventDefault()

      const uploadedUrl = await uploadCloudinaryHandler(
        artFile,
        `/Downloads/Group_Art/${productId}`
      )

      if (
        product?.groupArt?.artistsList &&
        product?.groupArt?.artistsList.length > 0
      ) {
        const temp = product?.groupArt?.artistsList.find(
          (item) => item == userInfo?.email
        )
        if (!temp) {
          dispatch(
            setAlert(t('ProductScreen.Only registered authors'), 'danger')
          )
          return
        }
      }

      dispatch(
        addToGroupProduct({
          productId,
          image: image && image?.includes('blob:') ? uploadedUrl : image,
          artName,
          artPrice,
          artDesc,
        })
      )
    } catch (error) {
      dispatch(setAlert(error.message, 'danger'))
    }
  }

  const editArt = (e) => {
    try {
      e.preventDefault()

      if (
        product?.groupArt?.artistsList &&
        product?.groupArt?.artistsList.length > 0
      ) {
        const temp = product?.groupArt?.artistsList.find(
          (item) => item == userInfo?.email
        )
        if (!temp) {
          dispatch(
            setAlert(t('ProductScreen.Only registered authors'), 'danger')
          )
          return
        }
      }

      dispatch(
        editGroupProductItem({ productId, image, artName, artPrice, artDesc })
      )
    } catch (error) {
      dispatch(setAlert(error.message, 'danger'))
    }
  }

  const approveArt = (id) => {
    dispatch(approveGroupProduct(productId, id))
  }

  const deleteArt = (id) => {
    dispatch(removeImageGroupProduct(productId, id))
  }

  const hideReviewHandler = (reviewId) => {
    dispatch(hideProductReview(productId, reviewId))
  }

  const showReviewHandler = (reviewId) => {
    dispatch(showProductReview(productId, reviewId))
  }

  const getMaxFromArray = (arr) => {
    let max = arr[0]
    for (let i = 1; i < arr.length; i++) {
      if (arr[i].votingCount > max.votingCount) {
        max = arr[i]
      }
    }
    return max
  }

  const goBackButton = () => {
    history.goBack()
  }

  return (
    <div className='mb-3'>
      <Meta
        title={product?.name}
        image={
          platform === 'yt'
            ? `https://i3.ytimg.com/vi/${video}/hqdefault.jpg`
            : product?.images && product?.images[0]
        }
      />

      {product?.type === 'launching' &&
      new Date(product?.launching?.launchingTime) > new Date() ? (
        <Launching product={product} />
      ) : loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>
          <Popup
            trigger={wheelPopup}
            setTrigger={setWheelPopup}
            background='bg-info'
          >
            <MiniGameWheel />
          </Popup>

          <Button
            className='btn p-1 btn-light mt-1 mb-2'
            onClick={goBackButton}
          >
            {t('ProductScreen.Go Back')}
          </Button>

          {/* {wheelPopupCount > 0 && (
            <Button
              className='btn p-1 btn-success mt-1 mb-2 ms-1 text-dark'
              onClick={() => {
                setWheelPopup(true)
                setWheelPopupCount((prevCount) => prevCount - 1)
              }}
              style={{
                backgroundImage:
                  'linear-gradient( 109.6deg,  rgba(247,253,166,1) 11.2%, rgba(128,255,221,1) 57.8%, rgba(255,128,249,1) 85.9% )',
              }}
            >
              {t('ProductScreen.Minigame')}
            </Button>
          )} */}

          {userInfo && (userInfo.isAdmin || product?.owner === userInfo.id) && (
            <Link
              to={`/admin/product/${productId}/edit`}
              className='btn p-1 btn-light ms-1 mt-1 mb-2'
            >
              <i className='fas fa-edit'></i>
              {/* {t('ProductScreen.Edit')} */}
            </Link>
          )}

          <Row>
            <Col md={6}>
              <div
                className='videoWrapper'
                style={video && { paddingBottom: '56.25%' }}
              >
                {video && (
                  <>
                    {/* facebook video embed */}

                    {platform === 'fb' && (
                      <iframe
                        // src='https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F10153231379946729%2F&width=560&show_text=false&height=315&appId'
                        src={
                          page
                            ? `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F${page}%2Fvideos%2F${video}%2F&width=600&show_text=false&height=337&appId`
                            : `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F?v=${video}&width=600&show_text=false&height=337&appId`
                        }
                        // width='560'
                        // height='315'
                        style={{
                          border: 'none',
                          overFlow: 'hidden',
                          width: '100%',
                        }}
                        scrolling='no'
                        frameBorder='0'
                        allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
                        allowFullScreen={true}
                      />
                    )}
                    {/* youtube video embed */}
                    {platform === 'yt' && (
                      <iframe
                        // width='560'
                        // height='315'
                        src={`https://www.youtube-nocookie.com/embed/${video}`}
                        title='YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen={true}
                      ></iframe>
                    )}
                  </>
                )}
              </div>

              {/* PRODUCT IMAGE */}

              {product?.type === 'gallery' &&
              product?.groupArt?.art?.filter((item) => item.isApproved).length >
                0 ? (
                <>
                  <Gallery
                    productId={productId}
                    images={product.groupArt.art
                      .filter((item) => item.isApproved)
                      .map((item) => {
                        return {
                          author: item?.name,
                          url: item?.image,
                          title: item?.title,
                          description: item?.description,
                        }
                      })}
                  />
                </>
              ) : product?.type === 'groupart' &&
                product?.groupArt?.art?.filter((item) => item.isApproved)
                  .length > 0 ? (
                <>
                  <div
                    ref={groupArtContainer}
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      backgroundColor: 'white',
                    }}
                  >
                    {product?.groupArt &&
                      product?.groupArt?.art.map((item) => (
                        <div key={item._id}>
                          {(item?.isApproved || userInfo?.isAdmin) && (
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                              }}
                            >
                              <LazyLoad height={100} once>
                                {/* <a href={item.image} target='_blank'> */}
                                <OverlayTrigger
                                  placement='top'
                                  delay={{ show: 50, hide: 50 }}
                                  overlay={<Tooltip>{item.name}</Tooltip>}
                                >
                                  <Image
                                    src={item.image}
                                    alt={item.name}
                                    fluid
                                    style={{
                                      // objectFit: 'cover',
                                      minWidth: '100px',
                                      width: `${
                                        groupArtContainer.current
                                          ? groupArtContainer.current
                                              .clientWidth /
                                            Math.ceil(
                                              Math.sqrt(
                                                product?.groupArt?.art?.filter(
                                                  (item) => item.isApproved
                                                ).length
                                              )
                                            )
                                          : 100
                                      }px`,
                                    }}
                                  />
                                </OverlayTrigger>
                                {/* </a> */}
                              </LazyLoad>

                              {(userInfo?.isMod ||
                                userInfo?.id === product?.owner) &&
                                !item.isApproved && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexWrap: 'wrap',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <Button
                                      variant='outline-dark'
                                      className='btn-sm me-1'
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        fontSize: '0.6rem',
                                      }}
                                      onClick={() => approveArt(item._id)}
                                    >
                                      <i className='fas fa-check'></i>
                                    </Button>

                                    <Button
                                      variant='outline-dark'
                                      className='btn-sm me-1'
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        fontSize: '0.6rem',
                                      }}
                                      onClick={() => deleteArt(item._id)}
                                    >
                                      <i className='fas fa-trash'></i>
                                    </Button>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </>
              ) : product?.displayMedia && product?.mediaUrl ? (
                <video
                  controls
                  style={{ width: '100%' }}
                  src={product?.mediaUrl}
                  loop
                  preload='auto'
                  // autoPlay
                  // muted
                ></video>
              ) : product?.isVoting &&
                new Date(product?.voting?.votingEndTime) > Date.now() ? (
                <VotingProductImages product={product} />
              ) : (
                productImage?.length > 0 && (
                  // // Product IMAGES thumbs style 2
                  // <div
                  //   style={{
                  //     // height: '100vh',
                  //     display: 'flex',
                  //     alignItems: 'center',
                  //     justifyContent: 'center',
                  //   }}
                  // >
                  //   <div
                  //     style={{
                  //       width: '100%',
                  //       backgroundColor: 'white',
                  //       // padding: '20px',
                  //     }}
                  //   >
                  //     <ProductImagesSlider images={product.images} />
                  //   </div>
                  // </div>

                  // Product IMAGES thumbs style 1

                  <Image
                    // Add watermark
                    // src={cloudinary
                    //   .image(getPublicId(productImage))
                    //   .addTransformation(`l_${OVERLAYS[0]}/fl_layer_apply`)
                    //   .toURL()}

                    src={productImage}
                    alt={
                      arrayToString(product?.tags)?.length > 0
                        ? arrayToString(product?.tags)
                        : product.name
                    }
                    style={{
                      width: '100%',
                      maxHeight: '1500px',
                      // aspectRatio: '1/1',
                      // objectFit: 'contain',
                    }}
                    fluid
                  />
                )
              )}

              <ListGroup variant='flush'>
                {/* Product IMAGES thumbs style 1 */}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  {product?.images &&
                    product?.images.length > 1 &&
                    product?.images.map((image, index) => (
                      <Image
                        key={index}
                        className='m-1'
                        thumbnail
                        // autoFocus
                        src={image}
                        alt={product.name}
                        style={{
                          height: '50px',
                          maxWidth: '150px',
                          cursor: 'pointer',
                          // hover: 'opacity: 0.3',
                        }}
                        onClick={() => setProductImage(product?.images[index])}
                      />
                    ))}
                </div>

                {/* Social sharing */}
                <ListGroup.Item>
                  {/* <div className='ms-3 mt-2'> */}
                  <div>
                    <span className='me-1'>{t('ProductScreen.Share')}:</span>
                    {/* <FacebookShareCount
                      url={window.location.href.split('?')[0]}
                      className='me-1'
                    >
                      {(shareCount) => (
                        <span className='myShareCountWrapper'>
                          {shareCount}
                        </span>
                      )}
                    </FacebookShareCount> */}
                    <span style={{ color: 'yellow' }}>
                      {shareFB && shareFB}{' '}
                    </span>
                    <FacebookShareButton
                      url={window.location.href.split('?')[0]}
                      quote={product?.name}
                      hashtag='#nft #crypto #cryptocurrency'
                      className='me-1'
                    >
                      <FacebookIcon size={30} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      url={window.location.href.split('?')[0]}
                      title={product?.name}
                      hashtag='#NFT #crypto #cryptocurrency'
                      className='me-1'
                    >
                      <TwitterIcon size={30} round />
                    </TwitterShareButton>
                    <TelegramShareButton
                      url={window.location.href.split('?')[0]}
                      title={product?.name}
                      className='me-1'
                    >
                      <TelegramIcon size={30} round />
                    </TelegramShareButton>
                    <RedditShareButton
                      url={window.location.href.split('?')[0]}
                      title={product?.name}
                      className='me-1'
                    >
                      <RedditIcon size={30} round />
                    </RedditShareButton>
                    {/* <RedditShareCount url={window.location.href.split('?')[0]} className='me-1' /> */}
                  </div>
                </ListGroup.Item>
              </ListGroup>

              {product?.type === 'voting' &&
                product.isVoting &&
                (!product?.voting?.votingEndTime ||
                  new Date(product?.voting?.votingEndTime) > Date.now()) && (
                  <>
                    <VotingProduct product={product} />

                    {product?.voting?.votingEndTime &&
                      new Date(product?.voting?.votingEndTime) > Date.now() && (
                        <div className='my-3'>
                          <FlipClockCountdown
                            style={{
                              width: '100px',
                            }}
                            to={product?.voting?.votingEndTime}
                            labels={[
                              t('ProductScreen.Days'),
                              t('ProductScreen.Hours'),
                              t('ProductScreen.Minutes'),
                              t('ProductScreen.Seconds'),
                            ]}
                            digitBlockStyle={{
                              width: 30,
                              height: 45,
                              fontSize: 22,
                              color: 'black',
                              backgroundColor: '#ffe16b',
                            }}
                          />
                        </div>
                      )}
                  </>
                )}

              {product?.promotion &&
                product?.promotion?.isActive &&
                !product.isAuction &&
                (!product?.promotion?.endTime ||
                  new Date(product?.promotion?.endTime) > Date.now()) && (
                  <PromotionBox promotion={product?.promotion} />
                )}

              {coupon &&
                promoInfo?.qty > 0 &&
                !product?.promotion?.isActive &&
                !product.isAuction && (
                  <PromotionBox
                    promotion={{
                      promoName: `SALE ${parseInt(promoInfo.discount * 100)}%`,
                      promoDetails: t('ProductScreen.Coupon Applied'),
                      promoDetailsVN: t('ProductScreen.Coupon Applied'),
                    }}
                    notShowQuantity
                  />
                )}

              {/* COUNTDOWN TIMER */}
              {product?.promotion?.isActive && product?.promotion?.endTime && (
                <ListGroup.Item>
                  <div className='mb-2 mt-0'>
                    <FlipClockCountdown
                      style={{
                        width: '100px',
                      }}
                      onComplete={groupBuyCompletedHandler}
                      to={product?.promotion?.endTime}
                      labels={[
                        t('ProductScreen.Days'),
                        t('ProductScreen.Hours'),
                        t('ProductScreen.Minutes'),
                        t('ProductScreen.Seconds'),
                      ]}
                      digitBlockStyle={{
                        width: 30,
                        height: 45,
                        fontSize: 22,
                        color: 'black',
                        backgroundColor: '#ffe16b',
                      }}
                    />
                  </div>
                </ListGroup.Item>
              )}

              {/* AUCTION SECTION */}
              {product?.isAuction && (
                <ListGroup variant='flush' className='my-3 rounded'>
                  <ListGroup.Item>
                    <h3 className='text-center'>
                      {product.auction?.isActive
                        ? t('ProductEditScreen.Auction')
                        : t('ProductEditScreen.Auction Ended')}
                    </h3>
                  </ListGroup.Item>

                  {product.auction?.isActive && (
                    <>
                      {/* COUNTDOWN TIMER */}
                      <ListGroup.Item>
                        <div className='mb-2 mt-0'>
                          <FlipClockCountdown
                            style={{
                              width: '100px',
                            }}
                            onComplete={auctionCompletedHandler}
                            to={product?.auction?.endTime}
                            labels={[
                              t('ProductScreen.Days'),
                              t('ProductScreen.Hours'),
                              t('ProductScreen.Minutes'),
                              t('ProductScreen.Seconds'),
                            ]}
                            digitBlockStyle={{
                              width: 30,
                              height: 45,
                              fontSize: 22,
                              color: 'black',
                              backgroundColor: '#ffe16b',
                            }}
                            // labelStyle={{
                            //   fontSize: 10,
                            //   fontWeight: 500,
                            //   textTransform: 'uppercase',
                            // }}
                            // dividerStyle={{ color: 'white', height: 1 }}
                            // separatorStyle={{ color: 'red', size: '6px' }}
                            // duration={0.5}
                          />
                        </div>
                      </ListGroup.Item>

                      {userInfo && (
                        <ListGroup.Item>
                          <Form onSubmit={bidHandler}>
                            <Form.Group className='d-flex'>
                              {/* <Form.Label>{t('ProductScreen.Bid')}</Form.Label> */}
                              <InputGroup>
                                <InputGroup.Text variant='outline-secondary'>
                                  {t('currency').toUpperCase()}
                                </InputGroup.Text>

                                <Form.Control
                                  type='number'
                                  name='bid'
                                  value={bid}
                                  placeholder={t('ProductScreen.Make a bid')}
                                  onChange={(e) => setBid(e.target.value)}
                                  required
                                />
                              </InputGroup>

                              <Button
                                variant='primary'
                                type='submit'
                                style={{ width: '100px' }}
                              >
                                {t('ProductScreen.Bid')}
                              </Button>
                            </Form.Group>
                          </Form>
                        </ListGroup.Item>
                      )}
                    </>
                  )}

                  {product?.auction?.isActive &&
                    product?.auction?.bids &&
                    product?.auction?.bids.length <= 0 && (
                      <ListGroup.Item>
                        <Row className='mb-2'>
                          <Col md={3} sm={6}>
                            {t('ProductScreen.Start Price')}{' '}
                          </Col>
                          <Col>
                            <strong>
                              {t('currency')}{' '}
                              {currentCurrencyToLocale(
                                product?.auction?.startPrice,
                                i18n.language
                              )}
                            </strong>
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    )}

                  <ListGroup.Item>
                    {product?.auction?.bids?.length > 0 ? (
                      <div className='mb-2'>
                        <h6>{t('ProductScreen.Bidding')}</h6>

                        {product?.auction?.bids?.map((bid, index) => (
                          <Row
                            key={index}
                            style={{
                              backgroundColor:
                                index === 0 ? 'rgba(0, 0, 0, 0.1)' : '',
                              borderRadius: '5px',
                              padding: '5px',
                              border: index === 0 ? '1px solid #ffe16b' : '',
                            }}
                          >
                            <Col>{bid.name}</Col>
                            <Col>
                              {t('currency')}{' '}
                              {currentCurrencyToLocale(
                                bid.bidPrice,
                                i18n.language
                              )}
                            </Col>

                            <Col>
                              <Moment format='YYYY/MM/DD hh:mm'>
                                {bid.time}
                              </Moment>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    ) : (
                      <div
                        className='text-center my-3'
                        style={{ fontWeight: 'lighter' }}
                      >
                        {t('ProductScreen.No bids yet')}
                      </div>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              )}

              {/* GROUP ART */}
              {(product?.type === 'groupart' ||
                product?.type === 'gallery') && (
                <div className='mt-3'>
                  <h4 className='text-center'>
                    {t('ProductScreen.Group Art')}
                  </h4>
                  <p style={{ fontWeight: 'lighter' }}>
                    {t('ProductScreen.Explain group art')}
                  </p>
                  {product?.type !== 'groupart' && (
                    <div
                      className='my-3'
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      {product?.groupArt &&
                        (product?.groupArt?.art.length > 9 ||
                          userInfo?.isAdmin) &&
                        product?.groupArt?.art.map((item) => (
                          <div key={item._id}>
                            {item?.isApproved ||
                              (userInfo?.isAdmin && (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <OverlayTrigger
                                    placement='top'
                                    delay={{ show: 50, hide: 50 }}
                                    overlay={<Tooltip>{item.name}</Tooltip>}
                                  >
                                    <LazyLoad height={50} once>
                                      <Image
                                        src={item.image}
                                        alt={item.name}
                                        fluid
                                        style={{
                                          width: '50px',
                                          maxHeight: '150px',
                                        }}
                                      />
                                    </LazyLoad>
                                  </OverlayTrigger>

                                  {(userInfo?.isMod ||
                                    userInfo?.id === product?.owner) &&
                                    !item.isApproved && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexWrap: 'wrap',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <Button
                                          variant='outline-dark'
                                          className='btn-sm me-1'
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            fontSize: '0.6rem',
                                          }}
                                          onClick={() => approveArt(item._id)}
                                        >
                                          <i className='fas fa-check'></i>
                                        </Button>

                                        <Button
                                          variant='outline-dark'
                                          className='btn-sm me-1'
                                          style={{
                                            width: '30px',
                                            height: '30px',
                                            fontSize: '0.6rem',
                                          }}
                                          onClick={() => deleteArt(item._id)}
                                        >
                                          <i className='fas fa-trash'></i>
                                        </Button>
                                      </div>
                                    )}
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  )}

                  {userInfo &&
                    (!product?.groupArt?.artistsList ||
                      product?.groupArt?.artistsList?.length <= 0 ||
                      product?.groupArt?.artistsList?.filter(
                        (item) => item === userInfo?.email
                      ).length > 0) &&
                    (!product?.groupArt?.maxLimit ||
                      product?.groupArt?.maxLimit >
                        product?.groupArt?.art.length) && (
                      <Accordion flush>
                        <Accordion.Item eventKey='0'>
                          <Accordion.Header
                          // className='bg-secondary'
                          // style={{ backgroundColor: 'black' }}
                          >
                            {t('ProductScreen.Contribute')}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Form onSubmit={addArt}>
                              <Form.Group controlId='image'>
                                <Image
                                  src={image}
                                  fluid
                                  rounded
                                  // thumbnail
                                  // height={300}
                                  style={{
                                    maxWidth: '100%',
                                    maxHeight: '1200px',
                                    minHeight: '225px',
                                    minWidth: '300px',
                                    // width: '100%',
                                    borderStyle: 'dashed',
                                    borderWidth: '1px',
                                    objectFit: 'cover',
                                  }}
                                />

                                <Form.Control
                                  type='text'
                                  placeholder={t(
                                    'ProductEditScreen.Enter image url'
                                  )}
                                  value={
                                    image && image?.includes('blob:')
                                      ? ''
                                      : image
                                  }
                                  onChange={(e) => {
                                    setImage(e.target.value)
                                  }}
                                ></Form.Control>
                                <div className='d-flex'>
                                  <Form.Control
                                    type='file'
                                    // onChange={uploadFileHandler}
                                    onChange={(e) => {
                                      setArtFile(e.target.files[0])
                                      setImage(
                                        URL.createObjectURL(e.target.files[0])
                                      )
                                    }}
                                  ></Form.Control>
                                  <Button
                                    variant='secondary'
                                    type='button'
                                    onClick={() => setImage('')}
                                  >
                                    {t('ProductEditScreen.Remove')}
                                  </Button>
                                </div>

                                {uploading && <Loader />}

                                <Form.Label>
                                  {t('ProductEditScreen.Title')}
                                </Form.Label>
                                <Form.Control
                                  type='text'
                                  required
                                  placeholder={t(
                                    'ProductEditScreen.Enter title'
                                  )}
                                  value={artName}
                                  onChange={(e) => setArtName(e.target.value)}
                                ></Form.Control>

                                {/* GALLERY */}
                                {product?.type === 'gallery' && (
                                  <>
                                    <Form.Label>
                                      {t('ProductEditScreen.Price')}
                                    </Form.Label>
                                    <Form.Control
                                      type='number'
                                      required
                                      placeholder={t(
                                        'ProductEditScreen.Enter price'
                                      )}
                                      value={artPrice}
                                      onChange={(e) =>
                                        setArtPrice(e.target.value)
                                      }
                                    ></Form.Control>
                                  </>
                                )}

                                <Form.Label>
                                  {t('ProductScreen.Description')}
                                </Form.Label>
                                <Form.Control
                                  type='text'
                                  placeholder={t(
                                    'ProductEditScreen.Enter description'
                                  )}
                                  value={artDesc}
                                  maxLength={150}
                                  onChange={(e) => setArtDesc(e.target.value)}
                                ></Form.Control>

                                <Form.Check
                                  className='my-2'
                                  type='checkbox'
                                  label={t('ProductScreen.I have copyright')}
                                  checked={copyright}
                                  onChange={(e) =>
                                    setCopyright(e.target.checked)
                                  }
                                ></Form.Check>
                                <Row>
                                  <Col>
                                    <Button
                                      type='submit'
                                      variant='primary'
                                      className='my-2'
                                      disabled={!copyright}
                                    >
                                      {t('ProductScreen.Upload')}
                                    </Button>
                                  </Col>

                                  {product?.groupArt?.art.find(
                                    (item) => item.user === userInfo.id
                                  ) && (
                                    <>
                                      <Col>
                                        <Button
                                          onClick={editArt}
                                          variant='primary'
                                          className='my-2'
                                          disabled={!copyright}
                                        >
                                          {t('ProductScreen.Edit')}
                                        </Button>
                                      </Col>
                                      <span
                                        style={{
                                          fontWeight: 'lighter',
                                          fontSize: '14px',
                                        }}
                                      >
                                        <em>
                                          * {t('ProductScreen.Edit art note')}
                                        </em>
                                      </span>
                                    </>
                                  )}
                                </Row>
                              </Form.Group>
                            </Form>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    )}
                </div>
              )}
            </Col>

            {/* RIGHT PANEL */}
            <Col md={6}>
              {/* Product Title */}
              <ListGroup variant='flush'>
                <div className='px-3'>
                  <h1>
                    {product?.name}
                    {'    '}
                  </h1>
                  <Row className='mb-2'>
                    <Col md={3} sm={6} xs={6}>
                      <span className='me-2'>
                        <WishList
                          id={product?._id}
                          userInfo={userInfo}
                          product={product}
                        />
                      </span>
                      {/* <Like
                        value={product?.likes}
                        showNo={4}
                        id={product?._id}
                        // text={t('ProductScreen.likes')}
                      /> */}
                    </Col>
                    <Col md={3} sm={6} xs={6}>
                      <View
                        value={product?.views ? product?.views : 0}
                        showNo={50}
                        text={t('ProductScreen.views')}
                      />
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      {product?.numReviews > 0 && (
                        <Rating
                          value={product?.rating}
                          text={` ${product?.numReviews} ${t(
                            'ProductScreen.reviews'
                          )}`}
                        />
                      )}
                    </Col>
                  </Row>

                  {/* <strong>{t('ProductScreen.Brand')}: </strong>
                {product.brand} */}

                  {product?.brand && product?.brand.trim().length > 0 && (
                    <Row className='my-2'>
                      <Col md={3} sm={6} xs={6}>
                        {t('ProductScreen.Brand')}
                      </Col>
                      <Col>{product?.brand}</Col>
                    </Row>
                  )}

                  {userInfo &&
                    userInfo?.ref?.isEnabled &&
                    product?.commissionCreator > 0 && (
                      <Row className='my-2' style={{ color: 'yellow' }}>
                        <Col>
                          {t('UserEditScreen.Creator commission') + ' - '}
                          {product?.commissionCreator * 100}%
                        </Col>
                      </Row>
                    )}

                  {/* {userInfo &&
                    userInfo?.ref?.isEnabled &&
                    product?.commissionCreator > 0 && (
                      <Row className='my-2' style={{ color: 'yellow' }}>
                        <Col>
                          {t('ProductScreen.You earn') + ' - '}
                          {userInfo?.ref?.commissionRate *
                            (1 - product?.commissionCreator) *
                            100}
                          %
                        </Col>
                      </Row>
                    )} */}

                  {product?.album && product?.album.trim().length > 0 && (
                    <Row className='my-2'>
                      <Col md={3} sm={6} xs={6}>
                        {t('ProductEditScreen.Collection')}
                      </Col>
                      <Col>{product?.album}</Col>
                    </Row>
                  )}

                  {/* PRODUCT PRICE */}
                  {!product?.isAuction ? (
                    <Row className='mb-2'>
                      <Col md={3} sm={6} xs={6}>
                        {t('ProductScreen.Price')}{' '}
                      </Col>
                      <Col>
                        {product?.promotion?.promoPrice >= 0 &&
                        product?.promotion?.promoPrice < product.price &&
                        product?.promotion?.isActive &&
                        (!product?.promotion?.endTime ||
                          new Date(product?.promotion?.endTime) >
                            Date.now()) ? (
                          <>
                            <span
                              style={{
                                color: 'yellow',
                                fontSize: '1.2rem',
                              }}
                            >
                              <strong>
                                {t('currency')}{' '}
                                {currentCurrencyToLocale(
                                  product?.promotion?.promoPrice,
                                  i18n.language
                                )}
                              </strong>
                            </span>

                            <span
                              style={{
                                textDecoration: 'line-through',
                                fontSize: '0.9rem',
                              }}
                              className='ms-1'
                            >
                              {currentCurrencyToLocale(
                                product.price,
                                i18n.language
                              )}
                            </span>

                            {product?.promotion?.promoType === 'flashsale' && (
                              <>
                                <br />
                                <Badge bg='danger' className='ms-2'>
                                  FLASHSALE
                                </Badge>
                              </>
                            )}
                          </>
                        ) : coupon && promoInfo?.qty > 0 ? (
                          <>
                            <span
                              style={{
                                color: 'yellow',
                                fontSize: '1.2rem',
                              }}
                            >
                              <strong>
                                {t('currency')}{' '}
                                {currentCurrencyToLocale(
                                  selectedVariant
                                    ? selectedVariantObj.price *
                                        (1 - promoInfo?.discount)
                                    : product.price * (1 - promoInfo?.discount),
                                  i18n.language
                                )}
                              </strong>
                            </span>

                            <span
                              style={{
                                textDecoration: 'line-through',
                                fontSize: '0.9rem',
                              }}
                              className='ms-1'
                            >
                              {currentCurrencyToLocale(
                                selectedVariant
                                  ? selectedVariantObj.price
                                  : product.price,
                                i18n.language
                              )}
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                              style={{
                                color: 'yellow',
                                fontSize: '1.2rem',
                              }}
                            >
                              <strong>
                                {t('currency')}{' '}
                                {currentCurrencyToLocale(
                                  selectedVariant
                                    ? selectedVariantObj.price
                                    : product.price,
                                  i18n.language
                                )}
                              </strong>
                            </span>

                            {product?.noDiscountPrice > 0 &&
                              product?.noDiscountPrice > product.price && (
                                <>
                                  <span
                                    style={{
                                      textDecoration: 'line-through',
                                      fontSize: '0.9rem',
                                    }}
                                    className='ms-1'
                                  >
                                    {currentCurrencyToLocale(
                                      product.noDiscountPrice,
                                      i18n.language
                                    )}
                                  </span>
                                </>
                              )}
                          </>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    product?.auction?.buyoutPrice &&
                    product?.auction?.isActive && (
                      <Row className='mb-2'>
                        <Col md={3} sm={6} xs={6}>
                          {t('ProductScreen.Buy Now Price')}{' '}
                        </Col>
                        <Col>
                          <strong>
                            {t('currency')}{' '}
                            {currentCurrencyToLocale(
                              product?.auction?.buyoutPrice,
                              i18n.language
                            )}
                          </strong>
                        </Col>
                      </Row>
                    )
                  )}

                  {/* DISPLAY STOCK QUANTITY */}
                  <Row className='mb-2'>
                    <Col md={3} sm={6} xs={6}>
                      {/* {t('ProductScreen.Status')} */}

                      {product.isLimited
                        ? t('ProductScreen.Limited Edition')
                        : t('ProductScreen.Status')}
                    </Col>
                    <Col>
                      {/* {product.countInStock > 0 ? (
                        <strong>{t('ProductScreen.In Stock')}</strong>
                      ) : (
                        <strong>{t('ProductScreen.Out Of Stock')}</strong>
                      )} */}

                      {product.isLimited &&
                      product?.promotion?.totalSold > 0 ? (
                        <strong>
                          {(
                            product.countInStock - product?.promotion?.totalSold
                          ).toLocaleString()}
                        </strong>
                      ) : product.isLimited &&
                        selectedVariantObj?.quantity >= 0 ? (
                        <strong>
                          {selectedVariantObj.quantity.toLocaleString()}
                        </strong>
                      ) : product.isLimited ? (
                        <strong>{product.countInStock.toLocaleString()}</strong>
                      ) : product.countInStock > 0 ? (
                        t('ProductScreen.In Stock')
                      ) : (
                        t('ProductScreen.Out Of Stock')
                      )}

                      {/* OPTION DEDUCT QUANTITY DIRECTLY _ GROUP BUY */}
                      {/* {product.isLimited ? (
                        <strong>{product.countInStock.toLocaleString()}</strong>
                      ) : product.countInStock > 0 ? (
                        t('ProductScreen.In Stock')
                      ) : (
                        t('ProductScreen.Out Of Stock')
                      )} */}
                    </Col>
                  </Row>

                  {product?.NFTStandard &&
                    product?.NFTStandard.trim().length > 0 && (
                      <Row className='my-2'>
                        <Col md={3} sm={6} xs={6}>
                          {t('ProductScreen.NFT Standard')}
                        </Col>
                        <Col>
                          {product?.NFTStandard.includes('ERC721') && (
                            <OverlayTrigger
                              placement='bottom'
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip>
                                  {t('ProductScreen.Explain ERC721')}
                                </Tooltip>
                              }
                            >
                              <Badge bg='success' className='me-1'>
                                ERC721
                              </Badge>
                            </OverlayTrigger>
                          )}

                          {product?.NFTStandard.includes('ERC1155') && (
                            <OverlayTrigger
                              placement='bottom'
                              delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip>
                                  {t('ProductScreen.Explain ERC1155')}
                                </Tooltip>
                              }
                            >
                              <Badge bg='info' className='me-1'>
                                ERC1155
                              </Badge>
                            </OverlayTrigger>
                          )}
                        </Col>
                      </Row>
                    )}

                  {product?.blockchain &&
                    product?.blockchain.trim().length > 0 && (
                      <Row className='my-2'>
                        <Col md={3} sm={6} xs={6}>
                          {t('ProductScreen.Blockchain')}
                        </Col>
                        <Col style={{ textTransform: 'capitalize' }}>
                          {product?.blockchain}
                        </Col>
                      </Row>
                    )}

                  {/* Display variants */}
                  {product?.variants && product?.variants.length > 0 && (
                    <Row className='mb-2'>
                      <Col md={3} sm={6} xs={6}>
                        {product?.type === 'gallery'
                          ? t('ProductScreen.Art Piece')
                          : t('ProductScreen.Variants')}
                      </Col>
                      <Col>
                        <Form.Select
                          value={selectedVariant}
                          defaultValue='default'
                          onChange={(e) => {
                            if (e.target.value !== 'default') {
                              setSelectedVariant(e.target.value)
                              const temp = product?.variants.find(
                                (v) => v._id.toString() === e.target.value
                              )
                              setSelectedVariantObj(temp)
                              if (temp?.image) {
                                setProductImage(temp?.image)
                              }
                            } else {
                              setSelectedVariant(null)
                              setSelectedVariantObj(null)
                              setProductImage(product?.images[0])
                            }
                          }}
                          // style={{ color: '#e83283' }}
                          // style={{ color: '#212529' }}
                        >
                          {product?.type !== 'voting' &&
                            product?.type !== 'membership' && (
                              <option disabled value='default'>
                                {t('ProductScreen.Select variant')}
                              </option>
                            )}

                          {/* {product?.variants
                            .filter((item) => item?.isApproved)
                            .map((variant) => (
                              <option key={variant._id} value={variant._id}>
                                {variant.name}
                              </option>
                            ))} */}

                          {/* ONLY DISPLAY WINNER VARIANT */}

                          {product?.type === 'voting' &&
                          new Date(product?.voting?.votingEndTime) <
                            Date.now() ? (
                            <>
                              <option value='default'>
                                {t('ProductScreen.Select variant')}
                              </option>
                              {product?.variants
                                .filter(
                                  (item) =>
                                    getMaxFromArray(
                                      product?.voting?.votingVariants
                                    ).votingVariant === item._id.toString()
                                )
                                .map((variant) => (
                                  <option key={variant._id} value={variant._id}>
                                    {variant.name}
                                  </option>
                                ))}
                            </>
                          ) : (
                            product?.variants
                              .filter((item) => item?.isApproved)
                              .map((variant) => (
                                <option key={variant._id} value={variant._id}>
                                  {variant.name}
                                </option>
                              ))
                          )}
                        </Form.Select>
                      </Col>
                    </Row>
                  )}

                  {!product?.isAuction && product.countInStock > 0 && (
                    <Row className='mb-3'>
                      <Col md={3} sm={6} xs={6}>
                        {t('ProductScreen.Qty')}
                      </Col>
                      <Col md={4} sm={6} xs={6}>
                        <Form.Control
                          type='number'
                          value={qty}
                          min='1'
                          max={
                            !selectedVariant
                              ? product.countInStock.toString()
                              : selectedVariantObj.quantity.toString()
                          }
                          onChange={(e) => {
                            if (!selectedVariant) {
                              if (e.target.value > product.countInStock) {
                                setQty(product.countInStock)
                              } else if (e.target.value < 1) {
                                setQty(1)
                              } else {
                                setQty(Number(e.target.value))
                              }
                            } else {
                              if (
                                e.target.value > selectedVariantObj.quantity
                              ) {
                                setQty(selectedVariantObj.quantity)
                              } else if (e.target.value < 1) {
                                setQty(1)
                              } else {
                                setQty(Number(e.target.value))
                              }
                            }
                          }}
                          // onChange={(e) => setQty(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                  )}
                </div>

                {product?.isAuction ? (
                  product?.auction?.buyoutPrice &&
                  product?.auction?.isActive && (
                    <Button
                      onClick={buyNowHandler}
                      className='btn-block my-1 rounded-0'
                      type='button'
                      disabled={product.countInStock <= 0}
                    >
                      {t('ProductScreen.Buy Now')}
                    </Button>
                  )
                ) : (
                  // (!product?.promotion?.promoType === 'groupbuy' ||
                  //     product?.promotion?.promoQuantity <= 0 ||
                  //     !product?.promotion?.isActive) &&
                  <Button
                    onClick={addToCartHandler}
                    className='btn-block my-1 rounded-0'
                    type='button'
                    size='lg'
                    disabled={
                      product.countInStock <= 0 ||
                      product.countInStock <= product?.promotion?.totalSold ||
                      (selectedVariantObj && selectedVariantObj.quantity <= 0)
                    }
                  >
                    <strong>{t('ProductScreen.Add To Cart')}</strong>
                  </Button>
                )}

                {/* GROUP BUY */}
                {product?.promotion?.promoType === 'groupbuy' &&
                  product?.promotion?.isActive &&
                  userInfo && (
                    <Button
                      onClick={buyGroupHandler}
                      className='btn-block my-1 rounded-0'
                      type='button'
                      size='lg'
                      disabled={
                        product.countInStock <= 0 ||
                        product.countInStock <= product?.promotion?.totalSold ||
                        product?.promotion?.promoQuantity <= 0
                      }
                    >
                      <strong>{t('ProductScreen.Buy In Group')}</strong>
                    </Button>
                  )}

                {/* Description */}
                <ListGroup.Item className='mt-1 mb-2'>
                  {/* <Accordion flush>
                    <Accordion.Item eventKey='1'>
                      <Accordion.Header>
                        <h5>{t('ProductScreen.Description')}:</h5>
                      </Accordion.Header>
                      <Accordion.Body></Accordion.Body>
                    </Accordion.Item>
                  </Accordion> */}

                  {/* <h5>{t('ProductScreen.Description')}:</h5> */}

                  {i18n.language === 'vi' && product.descriptionVN ? (
                    <div
                      style={{ fontSize: '14px' }}
                      dangerouslySetInnerHTML={{
                        __html: product.descriptionVN,
                      }}
                    />
                  ) : (
                    <div
                      style={{ fontSize: '14px' }}
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  )}

                  {/* <span style={{ fontSize: '14px' }} id='description-text'>
                    {i18n.language === 'vi' && product.descriptionVN
                      ? product.descriptionVN
                      : product.description}
                  </span> */}
                </ListGroup.Item>
              </ListGroup>

              <div className='text-center'>
                {i18n.language === 'vi' ? (
                  <Image
                    src={nganluong}
                    alt='payment methods'
                    className='my-2'
                    width={250}
                  />
                ) : (
                  <Image
                    src={payments}
                    alt='payment methods'
                    className='my-2'
                    width={280}
                  />
                )}
              </div>

              {/* <h3 className='mb-0'>{t('ProductScreen.Reviews')}</h3> */}

              {/* No review message */}
              {/* {product.reviews.length === 0 && (
                <Message>{t('ProductScreen.No Review')}</Message>
              )} */}

              <ListGroup style={{ fontSize: '14px' }} className='py-2'>
                {product.reviews.map((review) => (
                  <ListGroup.Item
                    key={review._id}
                    style={{
                      position: 'relative',
                      display:
                        userInfo && userInfo.isAdmin
                          ? ''
                          : review?.isHidden
                          ? 'none'
                          : '',
                    }}
                  >
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>

                    {userInfo && userInfo.isAdmin && (
                      <Button
                        className='btn-sm'
                        variant='light'
                        style={{
                          position: 'absolute',
                          top: '15px',
                          right: '15px',
                        }}
                        onClick={() =>
                          review?.isHidden
                            ? showReviewHandler(review._id)
                            : hideReviewHandler(review._id)
                        }
                      >
                        {review?.isHidden
                          ? t('ProductScreen.Show')
                          : t('ProductScreen.Hide')}
                      </Button>
                    )}
                  </ListGroup.Item>
                ))}

                <ListGroup.Item>
                  <h5>{t('ProductScreen.Write a Customer Review')}</h5>
                  {errorProductReview && (
                    <Message variant='danger'>{errorProductReview}</Message>
                  )}
                  {isProductBuyer && userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId='rating'>
                        <Form.Label>{t('ProductScreen.Rating')}</Form.Label>
                        <Form.Select
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                          // style={{ color: '#e83283' }}
                        >
                          <option value=''>
                            {t('ProductScreen.Select')}...
                          </option>
                          <option value='1'>
                            1 - {t('ProductScreen.Poor')}
                          </option>
                          <option value='2'>
                            2 - {t('ProductScreen.Fair')}
                          </option>
                          <option value='3'>
                            3 - {t('ProductScreen.Good')}
                          </option>
                          <option value='4'>
                            4 - {t('ProductScreen.Very Good')}
                          </option>
                          <option value='5'>
                            5 - {t('ProductScreen.Excellent')}
                          </option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group controlId='comment'>
                        <Form.Label>{t('ProductScreen.Comment')}</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows='3'
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button type='submit' variant='primary' className='mt-2'>
                        {t('ProductScreen.Submit')}
                      </Button>
                    </Form>
                  ) : userInfo ? (
                    <Message>
                      {t('ProductScreen.Buy product to leave a review')}
                    </Message>
                  ) : (
                    <Message>
                      <Trans i18nKey='ProductScreen.To Login'>
                        Please <Link to='/login'>sign in</Link> to write a
                        review
                      </Trans>
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>

          {/* COMBO SECTION */}
          {product?.type === 'combo' && product.showCombo && product.combo && (
            <ProductCombo product={product} />
          )}

          {/* UPSALE SECTION */}
          {product.showUpSale && !product.showCombo && <ProductsUpSale />}

          {/* RELATED PRODUCTS SECTION */}
          {product.showRelatedProducts && (
            <ProductsRelated productId={productId} />
          )}
        </div>
      )}
    </div>
  )
}

export default ProductScreen
