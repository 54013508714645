// // Gradient border:
// border: '3px solid transparent',
// borderRadius: '20px',
// background: 'linear-gradient(90deg,#262936,#262936),linear-gradient(90deg,#418dff,#cc25b3)',
// backgroundClip: 'padding-box,border-box',
// backgroundOrigin: 'padding-box,border-box',

// // Gradient background:
// backgroundImage: 'linear-gradient(270deg,#cc25b3 0,#418dff 101.52%)'

import {
  useAddress,
  useDisconnect,
  useMetamask,
  // useWalletConnect,
  // useCoinbaseWallet,
} from '@thirdweb-dev/react'
import { useTranslation } from 'react-i18next'
import { AiOutlineWallet } from 'react-icons/ai'
import { MdLogout } from 'react-icons/md'

export const ConnectMetamaskButton = () => {
  const connectWithMetamask = useMetamask()
  // const connectWithWalletConnect = useWalletConnect()
  // const connectWithCoinbaseWallet = useCoinbaseWallet()
  const disconnectWallet = useDisconnect()

  const address = useAddress()
  const { t } = useTranslation()

  return (
    <div>
      {address ? (
        <>
          <span
            className='btn btn-light btn-sm ms-1'
            style={{ fontSize: '13px' }}
          >
            <AiOutlineWallet />{' '}
            {/* <strong>{t('ConnectMetamaskButton.Connected as')}:</strong>{' '} */}
            {`${address.substring(0, 5)}...${address.slice(-4)}`}
          </span>
          <button
            className='btn btn-secondary btn-sm'
            onClick={disconnectWallet}
            style={{ fontSize: '13px' }}
          >
            {/* {t('ConnectMetamaskButton.Disconnect Wallet')} */}
            <MdLogout />
          </button>
        </>
      ) : (
        <button
          className='btn btn-light text-dark btn-sm ms-1'
          onClick={connectWithMetamask}
          style={{
            // fontWeight: 'lighter',
            fontSize: '13px',
            border: '3px solid transparent',
            borderRadius: '20px',
            background:
              'linear-gradient(90deg,#ECECEC,#ECECEC),linear-gradient(90deg,#418dff,#cc25b3)',
            backgroundClip: 'padding-box,border-box',
            backgroundOrigin: 'padding-box,border-box',
          }}
        >
          {t('ConnectMetamaskButton.Connect Metamask')}
        </button>
      )}
    </div>
  )
}
