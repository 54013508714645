export const POD_LIST_PRODUCTS_REQUEST = 'POD_LIST_PRODUCTS_REQUEST'
export const POD_LIST_PRODUCTS_SUCCESS = 'POD_LIST_PRODUCTS_SUCCESS'
export const POD_LIST_PRODUCTS_FAIL = 'POD_LIST_PRODUCTS_FAIL'

export const POD_LIST_VARIANTS_REQUEST = 'POD_LIST_VARIANTS_REQUEST'
export const POD_LIST_VARIANTS_SUCCESS = 'POD_LIST_VARIANTS_SUCCESS'
export const POD_LIST_VARIANTS_FAIL = 'POD_LIST_VARIANTS_FAIL'

export const POD_MOCKUP_CREATE_REQUEST = 'POD_MOCKUP_CREATE_REQUEST'
export const POD_MOCKUP_CREATE_SUCCESS = 'POD_MOCKUP_CREATE_SUCCESS'
export const POD_MOCKUP_CREATE_FAIL = 'POD_MOCKUP_CREATE_FAIL'
export const POD_MOCKUP_CREATE_RESET = 'POD_MOCKUP_CREATE_RESET'

export const POD_SHIPPING_REQUEST = 'POD_SHIPPING_REQUEST'
export const POD_SHIPPING_SUCCESS = 'POD_SHIPPING_SUCCESS'
export const POD_SHIPPING_FAIL = 'POD_SHIPPING_FAIL'

export const POD_ORDER_PAID_REQUEST = 'POD_ORDER_PAID_REQUEST'
export const POD_ORDER_PAID_SUCCESS = 'POD_ORDER_PAID_SUCCESS'
export const POD_ORDER_PAID_FAIL = 'POD_ORDER_PAID_FAIL'

export const POD_ORDER_CREATED_REQUEST = 'POD_ORDER_CREATED_REQUEST'
export const POD_ORDER_CREATED_SUCCESS = 'POD_ORDER_CREATED_SUCCESS'
export const POD_ORDER_CREATED_FAIL = 'POD_ORDER_CREATED_FAIL'

export const POD_ORDER_TRACKING_REQUEST = 'POD_ORDER_TRACKING_REQUEST'
export const POD_ORDER_TRACKING_SUCCESS = 'POD_ORDER_TRACKING_SUCCESS'
export const POD_ORDER_TRACKING_FAIL = 'POD_ORDER_TRACKING_FAIL'

export const POD_LOAD_PRINT_FILE = 'POD_LOAD_PRINT_FILE'
