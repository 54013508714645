const arrayToString = (arr) => {
  // If the array is empty, return an empty string
  if (!arr || arr?.length === 0) {
    return ''
  }
  // Return the array as a string, with each element separated by a space
  return arr.join(' ')
}

export default arrayToString
