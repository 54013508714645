import { Image, OverlayTrigger } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import arrayToString from '../utils/arrayToString'

const VotingProductImages = ({ product }) => {
  const dispatch = useDispatch()

  const totalVotes = product.voting.votingVariants.reduce(
    (acc, vote) => acc + vote.votingCount,
    0
  )

  const getMaxFromArray = (arr) => {
    let max = arr[0]
    for (let i = 1; i < arr.length; i++) {
      if (arr[i].votingCount > max.votingCount) {
        max = arr[i]
      }
    }
    return max
  }

  const container = document.getElementsByClassName('voting-product-images')

  return product?.voting.votingVariants.length === 2 ? (
    <div
      className='d-flex voting-product-images'
      style={{ minHeight: '400px', aspectRatio: '1/1' }}
    >
      <div
        className='left'
        style={{
          position: 'relative',
          width: `${
            (totalVotes == 0
              ? 0.5
              : product.voting.votingVariants[0].votingCount / totalVotes) * 100
          }%`,
        }}
      >
        <OverlayTrigger
          placement='right'
          delay={{ show: 50, hide: 50 }}
          overlay={
            <Image
              src={
                product.variants.find(
                  (variant) =>
                    variant._id.toString() ===
                    product.voting.votingVariants[0].votingVariant
                ).image
              }
              width={300}
              thumbnail
              style={{ maxHeight: '600px' }}
            />
          }
        >
          <Image
            src={
              product.variants.find(
                (variant) =>
                  variant._id.toString() ===
                  product.voting.votingVariants[0].votingVariant
              ).image
            }
            alt={
              arrayToString(product?.tags)?.length > 0
                ? arrayToString(product?.tags)
                : product.name
            }
            // fluid
            style={{
              objectFit: 'cover',
              width: '100%',
              height: container ? container[0]?.clientHeight : '100%',
            }}
          />
        </OverlayTrigger>

        <div
          style={{
            position: 'absolute',
            top: '0%',
            right: '-50px',
            zIndex: '1',
          }}
        >
          <Image
            src='https://res.cloudinary.com/nqivi/image/upload/v1663748909/NFT_Shop/images/vs-250px_wjfyvq.png'
            style={{ width: '100px', height: '100px' }}
          />
        </div>
      </div>

      <div
        className='right'
        style={{
          position: 'relative',
          width: `${
            (totalVotes == 0
              ? 0.5
              : product.voting.votingVariants[1].votingCount / totalVotes) * 100
          }%`,
        }}
      >
        <OverlayTrigger
          placement='right'
          delay={{ show: 50, hide: 50 }}
          overlay={
            <Image
              src={
                product.variants.find(
                  (variant) =>
                    variant._id.toString() ===
                    product.voting.votingVariants[1].votingVariant
                ).image
              }
              width={300}
              thumbnail
              style={{ maxHeight: '600px' }}
            />
          }
        >
          <Image
            src={
              product.variants.find(
                (variant) =>
                  variant._id.toString() ===
                  product.voting.votingVariants[1].votingVariant
              ).image
            }
            alt={
              arrayToString(product?.tags)?.length > 0
                ? arrayToString(product?.tags)
                : product.name
            }
            // fluid
            style={{
              objectFit: 'cover',
              width: '100%',
              height: container ? container[0]?.clientHeight : '100%',
            }}
          />
        </OverlayTrigger>
      </div>
    </div>
  ) : totalVotes === 0 ? (
    <Image
      // Add watermark
      // src={cloudinary
      //   .image(getPublicId(productImage))
      //   .addTransformation(`l_${OVERLAYS[0]}/fl_layer_apply`)
      //   .toURL()}

      src={product.images[0]}
      alt={
        arrayToString(product?.tags)?.length > 0
          ? arrayToString(product?.tags)
          : product.name
      }
      style={{
        width: '100%',
        maxHeight: '1500px',
        // aspectRatio: '1/1',
        // objectFit: 'contain',
      }}
      fluid
    />
  ) : (
    <>
      {product?.variants
        .filter(
          (item) =>
            getMaxFromArray(product?.voting?.votingVariants).votingVariant ===
            item._id.toString()
        )
        .map((variant) => (
          <Image
            src={variant.image}
            alt={
              arrayToString(product?.tags)?.length > 0
                ? arrayToString(product?.tags)
                : variant.name
            }
            style={{
              width: '100%',
              maxHeight: '1500px',
            }}
            fluid
          />
        ))}
    </>
  )
}

export default VotingProductImages
