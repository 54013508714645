import React from 'react'
import Alert from './Alert'

const TopBar = () => {
  return (
    <div
      className='top-bar'
      style={{
        position: 'fixed',
        top: '0px',
        left: '5%',
        // right: '10%',
        width: '90%',
        zIndex: '9999',
      }}
    >
      <Alert />
    </div>
  )
}

export default TopBar
