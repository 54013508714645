import {
  TRANSACTION_CREATE_FAIL,
  TRANSACTION_CREATE_REQUEST,
  TRANSACTION_CREATE_SUCCESS,
  TRANSACTION_DELETE_FAIL,
  TRANSACTION_DELETE_REQUEST,
  TRANSACTION_DELETE_SUCCESS,
  TRANSACTION_GET_ALL_FAIL,
  TRANSACTION_GET_ALL_REQUEST,
  TRANSACTION_GET_ALL_SUCCESS,
  TRANSACTION_GET_FAIL,
  TRANSACTION_GET_REQUEST,
  TRANSACTION_GET_SUCCESS,
  TRANSACTION_GET_USER_FAIL,
  TRANSACTION_GET_USER_REQUEST,
  TRANSACTION_GET_USER_SUCCESS,
  TRANSACTION_UPDATE_FAIL,
  TRANSACTION_UPDATE_REQUEST,
  TRANSACTION_UPDATE_SUCCESS,
} from '../constants/transactionConstants'

export const transactionReducer = (state = {}, action) => {
  const { type, payload } = action

  switch (type) {
    case TRANSACTION_CREATE_REQUEST:
      return {
        ...state,
        loadingCreate: true,
      }
    case TRANSACTION_CREATE_SUCCESS:
      return {
        ...state,
        loadingCreate: false,
        successCreate: true,
        transaction: payload,
      }
    case TRANSACTION_CREATE_FAIL:
      return {
        ...state,
        loadingCreate: false,
        errorCreate: payload,
      }

    case TRANSACTION_UPDATE_REQUEST:
      return {
        ...state,
        loadingUpdate: true,
      }
    case TRANSACTION_UPDATE_SUCCESS:
      return {
        ...state,
        loadingUpdate: false,
        success: true,
        transaction: payload,
      }
    case TRANSACTION_UPDATE_FAIL:
      return {
        ...state,
        loadingUpdate: false,
        error: payload,
      }

    case TRANSACTION_GET_ALL_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case TRANSACTION_GET_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        transactions: payload.transactions,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case TRANSACTION_GET_ALL_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case TRANSACTION_GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case TRANSACTION_GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        transactions: payload.transactions,
        pages: payload.pages,
        page: payload.page,
        pageSize: payload.pageSize,
      }
    case TRANSACTION_GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case TRANSACTION_GET_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case TRANSACTION_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        transaction: payload,
      }
    case TRANSACTION_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }

    case TRANSACTION_DELETE_REQUEST:
      return {
        ...state,
        loadingDelete: true,
      }
    case TRANSACTION_DELETE_SUCCESS:
      return {
        ...state,
        loadingDelete: false,
        successDelete: true,
      }
    case TRANSACTION_DELETE_FAIL:
      return {
        ...state,
        loadingDelete: false,
        errorDelete: payload,
      }
    default:
      return state
  }
}
