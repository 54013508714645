import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Product from './Product'
import { getProductsRelated } from '../actions/productActions'
import { PRODUCT_RELATED_RESET } from '../constants/productConstants'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const ProductsRelated = ({ productId }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productsRelated = useSelector((state) => state.productsRelated)
  const { products } = productsRelated

  useEffect(() => {
    dispatch(getProductsRelated(productId))

    return () => {
      dispatch({ type: PRODUCT_RELATED_RESET })
    }
  }, [])

  return (
    products && (
      <div className='mt-3'>
        <h4>{t('ProductScreen.You may also like')}</h4>
        <Row>
          {products.map((product) => (
            <Col key={product._id} sm={6} md={3}>
              <Product
                key={product._id}
                product={product}
                userInfo={userInfo}
                addToCart
                notShowRefRate
              />
            </Col>
          ))}
        </Row>
      </div>
    )
  )
}

export default ProductsRelated
