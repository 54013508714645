const currentCurrency = (amount, lang) => {
  let rate
  let decimal
  switch (lang) {
    case 'en':
      rate = 1
      decimal = 1
      break
    case 'vi':
      rate = process.env.REACT_APP_RATE_USDVND
      decimal = 3
      break
    default:
      rate = 1
  }
  const ratio = Math.pow(10, decimal)
  return Math.ceil(amount * rate * ratio) / ratio
}

export default currentCurrency
