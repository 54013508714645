import axios from 'axios'
import i18n from 'i18next'
import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_REQUEST,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_REQUEST,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_REF_LIST_MY_REQUEST,
  ORDER_REF_LIST_MY_SUCCESS,
  ORDER_REF_LIST_MY_FAIL,
} from '../constants/orderConstants'
import { CART_RESET } from '../constants/cartConstants'
import currentCurrency from '../utils/currentCurrency'

export const createOrder = (order, lang) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    // console.log('creating order: ', order)
    const { data } = await axios.post(`/api/orders?lang=${lang}`, order, config)

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: CART_RESET,
    })
    localStorage.getItem('cartItems') && localStorage.removeItem('cartItems')
    localStorage.getItem('coupon') && localStorage.removeItem('coupon')

    let myOrders = JSON.parse(localStorage.getItem('myOrders'))
    if (myOrders) {
      myOrders.push(data)
    } else {
      myOrders = [data]
    }
    localStorage.setItem('myOrders', JSON.stringify(myOrders))
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const buyRandomNFT =
  ({ email, paymentMethod, price, ref, lang }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_CREATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      // console.log('creating order: ', order)
      const { data } = await axios.post(
        `/api/orders/buyrandom?lang=${lang}`,
        {
          buyer: userInfo ? userInfo.id : null,
          email,
          paymentMethod,
          price,
          ref,
        },
        config
      )

      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: data,
      })

      // localStorage.getItem('cartItems') && localStorage.removeItem('cartItems')

      let myOrders = JSON.parse(localStorage.getItem('myOrders'))
      if (myOrders) {
        myOrders.push(data)
      } else {
        myOrders = [data]
      }
      localStorage.setItem('myOrders', JSON.stringify(myOrders))
    } catch (error) {
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'Accept-Language': i18n.language,
      },
    }

    await axios.delete(`/api/orders/${id}`, config)

    dispatch({
      type: ORDER_DELETE_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.get(`/api/orders/${id}`, config)

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateRef = (orderId) => async (getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }
    const { data } = await axios.put(
      `/api/orders/${orderId}/updateref`,
      {},
      config
    )
  } catch (error) {
    console.log(
      'Something went wrong... Please try again later or contact us for help.'
    )
  }
}

export const payOrder =
  (orderId, paymentResult, admin) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAY_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      let res
      if (admin) {
        res = await axios.put(
          `/api/orders/${orderId}/setpay`,
          paymentResult,
          config
        )
      } else {
        res = await axios.put(
          `/api/orders/${orderId}/pay`,
          paymentResult,
          config
        )
      }

      dispatch({
        type: ORDER_PAY_SUCCESS,
        payload: res.data,
      })

      // // if order has a ref user
      // if (res.data && res.data.ref) {
      //   // console.log('paid order: ', res.data)

      //   const { data } = await axios.put(
      //     `/api/orders/${orderId}/updateref`,
      //     {},
      //     config
      //   )

      //   // if (
      //   //   data.message &&
      //   //   data.message === 'Congratulation! You have reached new level.'
      //   // ) {
      //   //   console.log('updated Ref response: ', data)
      //   // }
      // }

      let myOrders = JSON.parse(localStorage.getItem('myOrders'))
      if (myOrders) {
        const newOrders = myOrders.map((order) => {
          if (order._id === orderId) {
            return res.data
          } else return order
        })
        localStorage.setItem('myOrders', JSON.stringify(newOrders))
      }
    } catch (error) {
      dispatch({
        type: ORDER_PAY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const payOrderByNganluong =
  (
    order,
    lang,
    bankCode,
    redirectSuccess = 'payment/thankyou',
    redirectFail = 'payment/fail'
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAY_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': i18n.language,
          Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
        },
      }

      // Create transaction
      const newTransaction = {
        user: userInfo ? userInfo.id : null,
        type: 'buy',
        order: order._id,
        amount: order.totalPrice,
        paymentMethod: order.paymentMethod,
        status: 'pending',
      }

      await axios.post(`/api/transactions`, newTransaction, config)

      const baseUrl = `https://${window.location.hostname}`

      const paymentInfo = {
        orderId: order._id,
        amount: currentCurrency(order.totalPrice, lang),
        customerId: userInfo ? userInfo.id : 'Guest',
        currency: lang === 'vi' ? 'VND' : 'USD',
        paymentMethod: order.paymentMethod,
        bankCode: bankCode,
        customerName: userInfo ? userInfo.name : 'Guest Buyer',
        customerEmail: userInfo ? userInfo.email : 'guest@example.com',
        customerPhone: '18001080',
        returnUrl: `${baseUrl}/${redirectSuccess}`,
        cancelUrl: `${baseUrl}/${redirectFail}`,
        transactionId: order._id,
        paymentType: '1',
        locale: lang,
      }
      // console.log('payment info: ', paymentInfo)

      const { data } = await axios.post(
        '/api/payment/checkout',
        paymentInfo,
        config
      )
      // console.log('nganluong response: ', data)

      // if (newWindow) {
      //   window.open(data, '_blank')
      // } else {
      //   // window.location = data
      //   window.location.href = data
      // }
      window.location.href = data
    } catch (error) {
      dispatch({
        type: ORDER_PAY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deliverOrder = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIVER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.put(
      `/api/orders/${orderId}/deliver`,
      {},
      config
    )

    dispatch({
      type: ORDER_DELIVER_SUCCESS,
    })

    let myOrders = JSON.parse(localStorage.getItem('myOrders'))
    if (myOrders) {
      const newOrders = myOrders.map((order) => {
        if (order._id === orderId) {
          return data
        } else return order
      })
      localStorage.setItem('myOrders', JSON.stringify(newOrders))
    }
  } catch (error) {
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const setDeliverOrder = (orderId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIVER_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.put(
      `/api/orders/${orderId}/setdeliver`,
      {},
      config
    )

    dispatch({
      type: ORDER_DELIVER_SUCCESS,
    })

    let myOrders = JSON.parse(localStorage.getItem('myOrders'))
    if (myOrders) {
      const newOrders = myOrders.map((order) => {
        if (order._id === orderId) {
          return data
        } else return order
      })
      localStorage.setItem('myOrders', JSON.stringify(newOrders))
    }
  } catch (error) {
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listMyOrders =
  (pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_LIST_MY_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/orders/myorders?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: ORDER_LIST_MY_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORDER_LIST_MY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listRefOrdersByUser =
  (userId, pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_REF_LIST_MY_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/orders/ref/${userId}?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: ORDER_REF_LIST_MY_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORDER_REF_LIST_MY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listOrders =
  (pageNumber = '') =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_LIST_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Accept-Language': i18n.language,
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.get(
        `/api/orders?pageNumber=${pageNumber}`,
        config
      )

      dispatch({
        type: ORDER_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: ORDER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': i18n.language,
        Authorization: userInfo ? `Bearer ${userInfo.token}` : '',
      },
    }

    const { data } = await axios.put(`/api/orders/${order._id}`, order, config)

    dispatch({
      type: ORDER_UPDATE_SUCCESS,
      data,
    })
  } catch (error) {
    dispatch({
      type: ORDER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
