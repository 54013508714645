export const ARTICLE_RESET = 'ARTICLE_RESET'

export const ARTICLE_CREATE_REQUEST = 'ARTICLE_CREATE_REQUEST'
export const ARTICLE_CREATE_SUCCESS = 'ARTICLE_CREATE_SUCCESS'
export const ARTICLE_CREATE_FAIL = 'ARTICLE_CREATE_FAIL'
export const ARTICLE_CREATE_RESET = 'ARTICLE_CREATE_RESET'

export const ARTICLE_GET_ALL_REQUEST = 'ARTICLE_GET_ALL_REQUEST'
export const ARTICLE_GET_ALL_SUCCESS = 'ARTICLE_GET_ALL_SUCCESS'
export const ARTICLE_GET_ALL_FAIL = 'ARTICLE_GET_ALL_FAIL'

export const ARTICLE_GET_USER_REQUEST = 'ARTICLE_GET_USER_REQUEST'
export const ARTICLE_GET_USER_SUCCESS = 'ARTICLE_GET_USER_SUCCESS'
export const ARTICLE_GET_USER_FAIL = 'ARTICLE_GET_USER_FAIL'

export const ARTICLE_GET_REQUEST = 'ARTICLE_GET_REQUEST'
export const ARTICLE_GET_SUCCESS = 'ARTICLE_GET_SUCCESS'
export const ARTICLE_GET_FAIL = 'ARTICLE_GET_FAIL'

export const ARTICLE_DELETE_REQUEST = 'ARTICLE_DELETE_REQUEST'
export const ARTICLE_DELETE_SUCCESS = 'ARTICLE_DELETE_SUCCESS'
export const ARTICLE_DELETE_FAIL = 'ARTICLE_DELETE_FAIL'

export const ARTICLE_UPDATE_REQUEST = 'ARTICLE_UPDATE_REQUEST'
export const ARTICLE_UPDATE_SUCCESS = 'ARTICLE_UPDATE_SUCCESS'
export const ARTICLE_UPDATE_FAIL = 'ARTICLE_UPDATE_FAIL'
export const ARTICLE_UPDATE_RESET = 'ARTICLE_UPDATE_RESET'

export const ARTICLE_UPDATE_LIKE_SUCCESS = 'ARTICLE_UPDATE_LIKE_SUCCESS'
export const ARTICLE_UPDATE_LIKE_FAIL = 'ARTICLE_UPDATE_LIKE_FAIL'
